import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { logout, setChangePass } from './utils/Reducer';
import { getAllowUpgrades } from './license/LicenseRest';
import { gStyles } from './utils/Styles';

import { withStyles } from '@mui/styles';
import { Box, AppBar, Toolbar, Typography, IconButton, Tabs, Tab, Menu, MenuItem, Divider } from '@mui/material';
// import { ArrowDropDown } from '@mui/icons-material';

import ChangePassword from './login/ChangePassword';
import DistributorsDashboard from './distributor/DistributorsDashboard';
import DistributorDetailDashboard from './distributor/DistributorDetailDashboard';
import CustomersDashboard from './customer/CustomersDashboard';
import CustomerDetailDashboard from './customer/CustomerDetailDashboard';
import LicensesDashboard from './license/LicensesDashboard';
import LicenseDetailDashboard from './license/LicenseDetailDashboard';
import PrintersDashboard from './printer/PrintersDashboard';
import PrinterDetailDashboard from './printer/PrinterDetailDashboard';
import SettingsOEMDashboard from './settings/SettingsOEMDashboard';
import SettingsOEMAdminDashboard from './settings/SettingsOEMAdminDashboard';
import SettingsOEMDetailDashboard from './settings/SettingsOEMDetailDashboard';
import SettingsOEMDistributor from './settings/SettingsOEMDistributor';

import NotFoundOrMissingPermissions from './global/NotFoundOrMissingPermissions';

import logo from './resources/logoCPpt.png';
import topbanner from './resources/topbanner.png';
import distributorsIcon from './resources/customersIcon.png';
import customersIcon from './resources/customersIcon.png';
import printersIcon from './resources/printersIcon.png';
import licenseIcon from './resources/licenseIcon.png';
import settingsIcon from './resources/icons/settingsIcon.png';
import userIcon from './resources/icons/userIcon.png';

let strings = new LocalizedStrings({
  en: {
    tabDistributors: 'Distributors',
    tabSettings: 'Settings',
    tabSettingsOEM: 'MyOEMs',
    tabCustomers: 'Customers',
    tabLicenses: 'Licenses',
    tabPrinters: 'Printers',

    menuChangePass: 'Change password',
    menuLogout: 'Logout',

    menuSettings: 'Settings',
    settingsWhatsNew: 'What\'s New',
    settingsLearning: 'Learning',
    settingsOEM: 'OEMs',
    settingsMyOEMs: 'MyOEMs',
  }
})


function Main(props) {

  const { classes } = props;

  const holderRef = useRef(null)
  const [holderHeight, setHolderHeight] = useState('auto');

  const { userEmail, userType, allowFeatures } = props;
  const { needPassword } = props;
  const { changePass, setChangePass } = props;
  const { logout } = props;
  const [userMenuEl, setUserMenuEl] = useState(null);
  const [settingsEl, setSettingsEl] = useState(null);


  let history = useHistory();

  useEffect(
    () => {
      if (history.location.pathname === '/')
        history.replace(userType === 0 ? '/distributors' : '/licenses');
      getAllowUpgrades();
    },
    [userType, history]
  );

  useEffect(
    () => {
      const screenHeight = parseInt(window.innerHeight) - 30;
      const holderHeight = screenHeight - parseInt(holderRef.current === null ? 0 : holderRef.current.offsetTop);
      setHolderHeight(holderHeight)
    },
    []
  );

  const handleUserMenuClick = event => {
    setUserMenuEl(event.currentTarget);
  };

  const handleSettingsClick = event => {
    setSettingsEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuEl(null);
  };

  const handleSettingsClose = () => {
    setSettingsEl(null);
  };


  const handleLogout = () => {
    handleUserMenuClose();
    logout();
    history.push('/');
  }

  const handleChangePass = () => {
    handleUserMenuClose();
    setChangePass(true);
  }

  // const handleSettingsTab = name => event => {
  //   history.replace('/settings/' + [name])
  //   setSettingsEl(null);
  // }

  const handleSettingsTabMyOEMs = name => event => {
    history.replace('/' + [name])
    setSettingsEl(null);
  }

  const currentTab = (loc) => {

    if (loc.pathname === '/') {
      return 'licenses';
    }

    if (loc.pathname.split('/')[2] !== undefined && loc.pathname.split('/')[1] === 'settings' && loc.pathname.split('/')[2] === 'notifications') {
      return false;
    }

    var tab = loc.pathname.split('/')[1];
    if (tab === 'distributors' || tab === 'customers' || tab === 'licenses' || tab === 'printers' || tab === 'settings') {
      return tab;
    }

    return false;
  }

  return (
    <Box>
      {(needPassword || changePass) &&
        <ChangePassword />
      }
      {!(needPassword || changePass) &&
        <Box>
          <Box className={classes.appBarBox}>
            <div id="spacerCtr" className={classes.MenuSpacer}></div>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <img className={classes.appBarLogoImg} src={logo} alt='' />
                <Typography variant='h6'> {strings.title} </Typography>
                <Route
                  render={({ location }) => (
                    <Tabs textColor="inherit" indicatorColor="secondary" className={classes.flexExpand} value={currentTab(location)} >
                      {(userType === 0 || (userType === 1 && (allowFeatures & 4) === 4)) &&
                        <Tab label={strings.tabDistributors} icon={<img src={distributorsIcon} alt='' />} value='distributors' component={Link} to='/distributors' />
                      }
                      {(userType === 0 || userType === 1) &&
                        <Tab label={strings.tabCustomers} icon={<img src={customersIcon} alt='' />} value='customers' component={Link} to='/customers' />
                      }
                      {(userType === 0 || userType === 1) &&
                        <Tab label={strings.tabLicenses} icon={<img src={licenseIcon} alt='' />} value='licenses' component={Link} to='/licenses' />
                      }
                      {(userType === 0 || userType === 1) &&
                        <Tab label={strings.tabPrinters} icon={<img src={printersIcon} alt='' />} value='printers' component={Link} to='/printers' />
                      }
                      {userType === 0 &&
                        <Tab label={strings.tabSettings} icon={<img src={settingsIcon} alt='' />} value='settings' component={Link} to='/settings/OEM' />
                      }
                    </Tabs>
                  )} />

                {/* My product menu ############################################### */}
                {userType === 1 &&
                  <Box className={classes.appUserBox} onClick={handleSettingsClick}>
                    <IconButton color='inherit' >
                      <img src={settingsIcon} alt='' />
                    </IconButton>
                    <Typography className={classes.appUserItem} variant='caption'> My Product </Typography>
                  </Box>
                }
                {userType === 1 &&
                  <Menu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    anchorEl={settingsEl}
                    keepMounted
                    open={Boolean(settingsEl)}
                    onClose={handleSettingsClose} >
                    {/* <Divider /> */}
                    <MenuItem onClick={handleSettingsTabMyOEMs('MyOEMs')}><Typography variant='body1'>{strings.settingsMyOEMs}</Typography></MenuItem>
                  </Menu>
                }

                {/* {userType === 1 &&
                  <IconButton color='inherit' onClick={handleSettingsClick}><ArrowDropDown style={{ color: '#CCCCCC' }} /></IconButton>
                } */}

                {/* My product menu ############################################### */}

                {/* User menu ############################################### */}
                <Box className={classes.appUserBox} onClick={handleUserMenuClick}>
                  <IconButton color='inherit' >
                    <img src={userIcon} alt='' />
                  </IconButton>
                  <Typography className={classes.appUserItem} variant='caption'>{userEmail}</Typography>
                </Box>
                <Menu
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  anchorEl={userMenuEl}
                  keepMounted
                  open={Boolean(userMenuEl)}
                  onClose={handleUserMenuClose} >
                  <MenuItem onClick={handleChangePass}><Typography variant='body1'>{strings.menuChangePass}</Typography></MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}><Typography variant='body1'>{strings.menuLogout}</Typography></MenuItem>
                </Menu>
                {/* <IconButton color='inherit' onClick={handleUserMenuClick}><ArrowDropDown style={{ color: '#CCCCCC' }} /></IconButton> */}
                {/* User menu ############################################### */}

              </Toolbar>
            </AppBar>
            <Box className={classes.appBarBanner}>
              <img src={topbanner} alt='' />
            </Box>
          </Box>

          <Box
            id="dashboardHolder"
            ref={holderRef}
            // style={{ height: holderHeight }}
            className={classes.appDashboard}
          >
            <Switch >
              {(userType === 0 || userType === 1) &&
                <Route path='/distributors/:distributorId'><DistributorDetailDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/distributors'><DistributorsDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/customers/:customerId'><CustomerDetailDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/customers'><CustomersDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/licenses/:licenseId'><LicenseDetailDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/licenses'><LicensesDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/printers/:printerId'><PrinterDetailDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/printers'><PrintersDashboard /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/myOEMs'><SettingsOEMDistributor /></Route>
              }
              {(userType === 0 || userType === 1) &&
                <Route path='/myOEM/:oemId'><SettingsOEMDashboard /></Route>
              }
              {userType === 0 &&
                <Route path='/settings/OEM'><SettingsOEMAdminDashboard /></Route>
              }
              {userType === 0 &&
                <Route path='/settings/:oemId'><SettingsOEMDetailDashboard /></Route>
              }
              <Route path="*">
                <NotFoundOrMissingPermissions />
              </Route>
            </Switch>
          </Box>

        </Box>
      }
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    userEmail: state.userEmail,
    userType: state.userType,
    allowFeatures: state.allowFeatures,
    needPassword: state.needPassword,
    changePass: state.changePass,
  }
};

export default connect(mapStateToProps, { logout, setChangePass })(withStyles(gStyles)(Main));
