import React, { useState, useRef, useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { connect, useDispatch } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import {
  Grid, Box, FormControl, FormLabel, Typography, Button, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Tooltip,
  IconButton, DialogContentText
} from '@mui/material';
import { SketchPicker } from 'react-color';
import { Delete, Edit } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';

import DropzonePic from '../components/DropzonePic';
import primaryColors from './../resources/icons/primaryColors.png';
import tinycolor from 'tinycolor2'

//NOT GOOD, NEED FIX
import ReactCrop from 'react-image-crop';
import { height } from '@material-ui/system';
import zIndex from '@mui/material/styles/zIndex';


let strings = new LocalizedStrings({
  en: {
    title: 'MY OEM CUSTOMIZATION',

    softwareLogo: 'Software Logo',
    softwareIcon: 'Software Icon',
    backgroundImage: 'Background Image',
    splash: 'Splash Image',

    labelSelectItem: 'Select an item from preview to edit',
    labelGenerateTheme: 'Select 2 colors',
    labelColorTheme1: 'Main Color',
    labelColorTheme2: 'Secondary Color',

    labelBackgroundColor: 'Background Colors',
    labelPickColor: 'Select color',
    labelCropImage: 'Crop Image',
    labelTextColor: 'Text Colors',
    labelDefault: 'Default',
    labelPrimary: 'Primary',
    labelSecondary: 'Secondary',
    labelDisabled: 'Disabled',
    labelSuccess: 'Success',
    labelError: 'Error',
    labelWarning: 'Warning',
    labelInfo: 'Info',
    labelButtonStatesNormal: 'Buttons/Widgets (Normal)',
    labelButtonStatesHover: 'Buttons/Widgets (Hover)',
    labelButtonStatesSelected: 'Buttons/Widgets (Selected)',
    labelButtonStatesDisabled: 'Buttons/Widgets (Disabled)',
    labelNormalFill: 'Normal - Fill:',
    labelNormalOutline: 'Normal - Outline:',
    labelNormalIcon: 'Normal - Icon Color:',
    labelNormalText: 'Normal - Text Color:',
    labelHoverFill: 'Mouseover - Fill:',
    labelHoverOutline: 'Mouseover - Outline:',
    labelHoverIcon: 'Mouseover - Icon Color:',
    labelHoverText: 'Mouseover - Text Color:',
    labelSelectedFill: 'Selected - Fill:',
    labelSelectedOutline: 'Selected - Outline:',
    labelSelectedIcon: 'Selected - Icon Color:',
    labelSelectedText: 'Selected - Text Color:',
    labelDisabledFill: 'Disabled - Fill:',
    labelDisabledOutline: 'Disabled - Outline:',
    labelDisabledIcon: 'Disabled - Icon Color:',
    labelDisabledText: 'Disabled - Text Color:',
    labelMenuBar: 'MenuBar Colors',
    labelMenu: 'Menu Colors',
    labelNotifications: 'Notifications Colors',
    labelPopupFill: 'Popup - Fill:',
    labelPopupOutline: 'Popup - Outline:',
    labelPopupTitle: 'Popup - Title:',
    labelPopupText: 'Popup - Text:',

    toolTipEdit: 'Edit Picture',
    toolTipDelete: 'Delete Picture',

    noGeneratheThemeDialogTitle: 'Warning',
    noGeneratheThemeDialogText: 'Please generate a theme before editing components.',

    popupHasGenerateThemeDialogText: 'If you generate a new theme you will lost the previous one, are you sure you want to proceed?',

    confirm: 'Confirm',
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    recomendedSize: 'Recomended size',
  }
})


function SettingsOEMEditorSimple(props) {

  const { classes } = props;
  const { settingsOEMEditorForceRefresh } = props;
  const { settingsOEMThemeValues } = props;
  const { editSoftwareIcon, editSoftwareLogo, editBackgroundImage,
    editTextColors, editWidgetsColorsNormal, editWidgetsColorsHover, editWidgetsColorsSelected,
    editWidgetsColorsDisabled, editMenuColors, editMenuBarColors,
    editNotificationColors } = props;
  const { themeGenerated, clickNoThemeGenerated } = props;

  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const [values, setValues] = useState({ valid: false, oem_id: 0, logo: '', icon: '', splash: '', background: '', data: {} });
  const [colorToEdit, setColorToEdit] = useState({ value: '', color: '' });
  const [colorsToGenerateTheme, setColorsToGenerateTheme] = useState({ colorTheme1: '', colorTheme2: '' });
  const [noThemeDialog, setNoThemeDialog] = useState(false);
  const [popupHasGenerateTheme, setPopupHasGenerateTheme] = useState(false);

  const [generalModalOpen, setGeneralModalOpen] = useState(false);
  const [generalModalTitle, setGeneralModalTitle] = useState("");
  const [generalModalText, setGeneralModalText] = useState("");

  const dispatch = useDispatch();
  const fileUploader = useRef(null);
  const fileUploader1 = useRef(null);
  const fileUploader2 = useRef(null);
  const fileUploaderSplash = useRef(null);

  const [imageCrop, setImageCrop] = useState({ icon: '', logo: '' });
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const mainHolderRef = useRef(null);
  const [configDataHolderHeight, setConfigDataHolderHeight] = useState('auto');

  useEffect(
    () => {
      setConfigDataHolderHeight(mainHolderRef.current.offsetHeight - 80);
    },
    [mainHolderRef.current]
  );

  useEffect(
    () => {
      if (clickNoThemeGenerated) {
        setNoThemeDialog(true);
      }
      if (settingsOEMThemeValues !== undefined) {
        if (settingsOEMThemeValues?.data?.background_color_default !== undefined && settingsOEMThemeValues?.data?.button_selected_fill !== undefined) {
          setColorsToGenerateTheme({
            colorTheme1: settingsOEMThemeValues?.data?.background_color_default,
            colorTheme2: settingsOEMThemeValues?.data?.button_selected_fill
          });
          dispatch({ type: 'SET_THEME_GENERATED', value: true });
        }
      }
      //setValues( { ...values, oem_id: 0, valid: false } );
    },
    [settingsOEMEditorForceRefresh, settingsOEMThemeValues, editSoftwareIcon, editSoftwareLogo, editBackgroundImage, editTextColors, editWidgetsColorsNormal, editWidgetsColorsHover, editWidgetsColorsSelected, editWidgetsColorsDisabled, editMenuColors, editMenuBarColors, editNotificationColors, themeGenerated, clickNoThemeGenerated]
  );

  useEffect(
    () => {
      if (settingsOEMThemeValues !== undefined) {

        // On reset 
        if (settingsOEMThemeValues?.data?.background_color_default === undefined) {
          setColorsToGenerateTheme({
            colorTheme1: "#ffffff",
            colorTheme2: "#000000"
          });
          handleGenerateTheme("#ffffff", "#000000");
        }

        setValues({
          valid: true,
          oem_id: settingsOEMThemeValues.productfamily_id,
          logo: (settingsOEMThemeValues.logo === null) ? '' : settingsOEMThemeValues.logo,
          icon: (settingsOEMThemeValues.icon === null) ? '' : settingsOEMThemeValues.icon,
          background: (settingsOEMThemeValues.background === null) ? '' : settingsOEMThemeValues.background,
          splash: (settingsOEMThemeValues.splash === null) ? '' : settingsOEMThemeValues.splash,
          data: {
            background_color_default: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.background_color_default,
            background_color_primary: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.background_color_primary,
            background_color_secondary: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.background_color_secondary,
            text_color_default: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_default,
            text_color_primary: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_primary,
            text_color_secondary: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_secondary,
            text_color_disabled: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_disabled,
            text_color_success: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_success,
            text_color_error: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_error,
            text_color_warning: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_warning,
            text_color_info: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.text_color_info,
            button_normal_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_normal_fill,
            button_normal_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_normal_outline,
            button_normal_icon: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_normal_icon,
            button_normal_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_normal_text,
            button_selected_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_selected_fill,
            button_selected_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_selected_outline,
            button_selected_icon: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_selected_icon,
            button_selected_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_selected_text,
            button_hover_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_fill,
            button_hover_icon: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_icon,
            button_hover_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_text,
            button_disabled_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_disabled_fill,
            button_disabled_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_normal_outline,
            button_disabled_icon: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_disabled_icon,
            button_disabled_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.button_disabled_text,
            menu_bar_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_bar_fill,
            menu_bar_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_bar_outline,
            menu_bar_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_bar_text,
            menu_normal_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_normal_fill,
            menu_normal_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_normal_outline,
            menu_normal_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_normal_text,
            menu_over_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_over_fill,
            menu_over_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_over_outline,
            menu_over_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_over_text,
            menu_selected_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_selected_fill,
            menu_selected_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_selected_outline,
            menu_selected_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_selected_text,
            menu_disabled_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_disabled_fill,
            menu_disabled_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_disabled_outline,
            menu_disabled_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.menu_disabled_text,
            notification_popup_fill: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.notification_popup_fill,
            notification_popup_outline: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.notification_popup_outline,
            notification_popup_title: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.notification_popup_title,
            notification_popup_text: (settingsOEMThemeValues?.data === null) ? undefined : settingsOEMThemeValues?.data?.notification_popup_text,
          },
        });
      }
    },
    [settingsOEMThemeValues]
  )

  const startNewFile = (file, name) => {
    var reader = new FileReader();
    switch (name) {
      case 'logo': {
        reader.onload = () => {
          setValues({ ...values, logo: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, logo: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'icon': {
        reader.onload = () => {
          //setImageCrop( { ...imageCrop, icon: reader.result } );
          setValues({ ...values, icon: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, icon: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'background': {
        reader.onload = () => { 
          setValues({ ...values, background: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'splash': {
        reader.onload = () => {
          setValues({ ...values, splash: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, splash: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }

      default:
        break;
    }

  }

  const handleChangeColor = name => event => {
    if (event.target !== undefined)
      setValues({ ...values, data: { ...values.data, [name]: event.target.value } });
    else {
      setValues({ ...values, data: { ...values.data, [name]: event.hex } });
      setColorToEdit({ ...colorToEdit, value: event.hex });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, data: { ...settingsOEMThemeValues?.data, [name]: event.hex } } });
    }
  };

  const handleBlur = name => event => {
    setValues({ ...values, data: { ...values.data, [name]: event.target.value } });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, data: { ...settingsOEMThemeValues?.data, [name]: event.target.value } } });

  };

  const handleChangeColorToGenerateTheme = name => event => {
    if (event.target !== undefined)
      setColorsToGenerateTheme({ ...colorsToGenerateTheme, [name]: event.target.value });
    else {
      setColorsToGenerateTheme({ ...colorsToGenerateTheme, [name]: event.hex });
      setColorToEdit({ ...colorToEdit, value: event.hex });      
    }
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  };

  const handleBlurToGenerateTheme = name => event => {
    setColorsToGenerateTheme({ ...colorsToGenerateTheme, [name]: event.target.value });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  };

  const handleGenerateThemeClick = () => {
    if (themeGenerated) {
      setPopupHasGenerateTheme(true);
    }
    else {
      handleGenerateTheme();
    }
  }

  const handleHasGeneratedThemePopupClick = confirm => {
    if (confirm) {
      handleGenerateTheme();
    }
    else {
      dispatch({ type: 'REFRESH_SETTINGS_OEM_EDITOR' });
    }
    setPopupHasGenerateTheme(false);
  }

  const handleGenerateTheme = (parColorTheme1 = null, parColorTheme2 = null) => {

    let colorTheme1 = colorsToGenerateTheme.colorTheme1;
    let colorTheme2 = colorsToGenerateTheme.colorTheme2;

    if (parColorTheme1 !== null && parColorTheme2 !== null) {
      colorTheme1 = parColorTheme1;
      colorTheme2 = parColorTheme2;
    }

    var color1Array = ((colorTheme1).substring(1)).match(/.{1,2}/g);
    var textColor = ((parseInt(color1Array[0], 16) * 0.299 + parseInt(color1Array[1], 16) * 0.587 + parseInt(color1Array[2], 16) * 0.114) > 186) ? '#000000' : '#ffffff';
    var disabledText = (textColor === '#000000') ? tinycolor(textColor).brighten(50).toString() : tinycolor(textColor).darken(50).toString();
    var backgroundPrimary = tinycolor(colorTheme1).darken(10).toString();
    var customColorsNormal = (textColor === '#000000') ? tinycolor(colorTheme1).brighten(15).toString() : tinycolor(colorTheme1).darken(15).toString();
    var customColorsSelected = (textColor === '#000000') ? tinycolor(colorTheme2).brighten(15).toString() : tinycolor(colorTheme2).darken(15).toString();
    var outline = (textColor === '#000000') ? tinycolor(textColor).brighten(40).toString() : tinycolor(textColor).darken(40).toString();
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    dispatch({ type: 'SET_THEME_GENERATED', value: true });

    setValues({
      ...values, data: {
        ...values.data,
        background_color_default: colorTheme1,
        background_color_primary: backgroundPrimary,
        text_color_default: textColor,
        text_color_primary: colorTheme2,
        text_color_disabled: disabledText,
        button_normal_fill: colorTheme1,
        button_normal_outline: outline,
        button_normal_icon: textColor,
        button_normal_text: textColor,
        button_selected_fill: colorTheme2,
        button_selected_outline: outline,
        button_selected_icon: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
        button_selected_text: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
        button_hover_fill: colorTheme2,
        button_hover_icon: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
        button_hover_text: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
        button_disabled_fill: colorTheme1,
        button_disabled_outline: disabledText,
        button_disabled_icon: disabledText,
        button_disabled_text: disabledText,
        menu_bar_fill: colorTheme1,
        menu_bar_outline: colorTheme1,
        menu_bar_text: textColor,
        menu_normal_fill: colorTheme2,
        menu_normal_outline: outline,
        menu_normal_text: textColor,
        menu_over_fill: customColorsSelected,
        menu_over_outline: outline,
        menu_over_text: textColor,
        menu_selected_fill: customColorsSelected,
        menu_selected_outline: outline,
        menu_selected_text: textColor,
        menu_disabled_fill: colorTheme2,
        menu_disabled_outline: outline,
        menu_disabled_text: disabledText,
        notification_popup_fill: customColorsNormal,
        notification_popup_outline: outline,
        notification_popup_title: colorTheme2,
        notification_popup_text: textColor,
      },
    });
    dispatch({
      type: 'SET_SETTINGS_OEM_THEME_VALUES', value: {
        ...settingsOEMThemeValues, data: {
          ...settingsOEMThemeValues?.data,
          background_color_default: colorTheme1,
          background_color_primary: backgroundPrimary,
          text_color_default: textColor,
          text_color_primary: colorTheme2,
          text_color_disabled: disabledText,
          button_normal_fill: colorTheme1,
          button_normal_outline: outline,
          button_normal_icon: textColor,
          button_normal_text: textColor,
          button_selected_fill: colorTheme2,
          button_selected_outline: outline,
          button_selected_icon: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
          button_selected_text: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
          button_hover_fill: colorTheme2,
          button_hover_icon: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
          button_hover_text: (colorTheme2 === '#000000' && textColor === '#000000') ? "#ffffff" : textColor,
          button_disabled_fill: colorTheme1,
          button_disabled_outline: disabledText,
          button_disabled_icon: disabledText,
          button_disabled_text: disabledText,
          menu_bar_fill: colorTheme1,
          menu_bar_outline: colorTheme1,
          menu_bar_text: textColor,
          menu_normal_fill: colorTheme2,
          menu_normal_outline: outline,
          menu_normal_text: textColor,
          menu_over_fill: customColorsSelected,
          menu_over_outline: outline,
          menu_over_text: textColor,
          menu_selected_fill: customColorsSelected,
          menu_selected_outline: outline,
          menu_selected_text: textColor,
          menu_disabled_fill: colorTheme2,
          menu_disabled_outline: outline,
          menu_disabled_text: disabledText,
          notification_popup_fill: customColorsNormal,
          notification_popup_outline: outline,
          notification_popup_title: colorTheme2,
          notification_popup_text: textColor,
        },
      }
    });


  };

  const handleEditIconClick = () => {
    fileUploader.current.click();
  }

  const handleEditLogoClick = () => {
    fileUploader1.current.click();
  }

  const handleEditBackgroundClick = () => {
    fileUploader2.current.click();
  }

  const handleEditsplashClick = () => {
    fileUploaderSplash.current.click();
  }

  const onIconEdit = (event) => {    
    const file = event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024)

      if (fileSizeInMB > 1) {
        setGeneralModalTitle("Error");
        setGeneralModalText("Max file size allowed is 1MB");
        setGeneralModalOpen(true);
        return;
      }

    }

    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, icon: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, icon: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const onLogoEdit = (event) => {
    
    const file = event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024)

      if (fileSizeInMB > 1) {
        setGeneralModalTitle("Error");
        setGeneralModalText("Max file size allowed is 1MB");
        setGeneralModalOpen(true);
        return;
      }

    }

    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, logo: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, logo: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const onBackgroundEdit = (event) => {

    const file = event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024)

      if (fileSizeInMB > 1) {
        setGeneralModalTitle("Error");
        setGeneralModalText("Max file size allowed is 1MB");
        setGeneralModalOpen(true);
        return;
      }

    }

    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, background: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);

  }

  const onsplashEdit = (event) => {

    const file = event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024)

      if (fileSizeInMB > 1) {
        setGeneralModalTitle("Error");
        setGeneralModalText("Max file size allowed is 1MB");
        setGeneralModalOpen(true);
        return;
      }

    }

    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, splash: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, splash: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);

  }

  const onBackgroundDelete = () => {
    setValues({ ...values, background: '' });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: '' } });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  }

  const onsplashDelete = () => {
    setValues({ ...values, splash: '' });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, splash: '' } });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  }

  const handleEditColor = (value, color) => {
    setColorPickerOpen(true);
    setColorToEdit({ value: value, color: color });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  }

  const handleEditColorClose = confirm => {
    setColorToEdit({ value: '' });
  }


  const handleBackToTheme = () => {
    dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
    dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
    dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
    dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
    dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
    dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
    dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
    dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
    dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
    dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
  };

  const handleCloseNoThemeDialog = () => {
    setNoThemeDialog(false);
    setColorPickerOpen(false);
    dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: false });
  }


  return (
    <Box id="mainHolder" ref={mainHolderRef} style={{ height: '100%' }}>
      {settingsOEMThemeValues !== undefined &&
        <Box className={classes.workarea} >
          {(editTextColors || editWidgetsColorsNormal || editWidgetsColorsHover || editWidgetsColorsSelected || editWidgetsColorsDisabled || editMenuBarColors || editMenuColors || editNotificationColors || editSoftwareIcon || editSoftwareLogo || editBackgroundImage) &&
            <Grid style={{ marginLeft: '-15px', marginBottom: '25px' }} container >
              <Button onClick={handleBackToTheme} variant="contained" color='secondary' startIcon={<img src={primaryColors} alt='' style={{ marginLeft: '6px' }} />}>PRIMARY COLORS</Button>
            </Grid>
          }
          <Grid container >
            <form className={classes.form} autoComplete="off">
              {/* <Grid style={{ maxWidth: '360px', overflow: 'auto', height: configDataHolderHeight, paddingTop: 15 }} className={classes.workareaEditor} container spacing={2} > */}
              <Grid style={{ maxWidth: '360px', overflow: 'auto', paddingTop: 15 }} className={classes.workareaEditor} container spacing={2} >

                {values.icon === '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormControl variant='outlined' style={{ height: 120 }} >
                      <FormLabel>
                        <Typography className={classes.imageLabel} color='secondary'>{strings.softwareIcon}</Typography>
                        <small className={classes.recomendedSize}>{strings.recomendedSize}: 256x256</small>
                      </FormLabel>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file, 'icon') }} />
                    </FormControl>
                  </Grid>
                }
                {values.icon !== '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormLabel>
                      <Typography className={classes.imageLabel} color='secondary'>{strings.softwareIcon}</Typography>
                      <small className={classes.recomendedSize}>{strings.recomendedSize}: 256x256</small>
                    </FormLabel>
                    <FormControl fullWidth variant='outlined' style={{ borderColor: '#CCCCCC' }}>

                      <Box style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, height: '100%' }}>
                        <img src={values.icon} style={{ width: 'auto', maxWidth: '100%', height: 120, maxHeight: 120, objectFit: 'contain' }} alt="" />
                        <input type='file' accept='.png,.jpg,.jpeg' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onIconEdit(file) }} />
                        <Box className={classes.imageBoxOEMThemeOverlay}>
                          <Tooltip title={strings.toolTipEdit}>
                            <IconButton className={classes.imageBoxOEMThemeIconHolder} size='medium' onClick={handleEditIconClick} >
                              <Edit style={{ color: '#FFF', fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>

                    </FormControl>

                  </Grid>
                }
                {values.logo === '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormControl variant='outlined' style={{ height: 120 }}>
                      <FormLabel>
                        <Typography className={classes.imageLabel} color='secondary'>{strings.softwareLogo}</Typography>
                        <small className={classes.recomendedSize}>{strings.recomendedSize}: 446x104</small>
                      </FormLabel>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file, 'logo') }} />
                    </FormControl>
                  </Grid>
                }
                {values.logo !== '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormLabel>
                      <Typography className={classes.imageLabel} color='secondary'>{strings.softwareLogo}</Typography>
                      <small className={classes.recomendedSize}>{strings.recomendedSize}: 446x104</small>
                    </FormLabel>
                    <FormControl fullWidth variant='outlined'>

                      <Box style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, height: '100%' }}>
                        <img style={{ width: 'auto', maxWidth: '100%', height: 120, maxHeight: 120, objectFit: 'contain' }} src={values.logo} alt="" />
                        <input type='file' accept='.png,.jpg,.jpeg' ref={fileUploader1} style={{ display: "none" }} onChange={(file) => { onLogoEdit(file) }} />
                      </Box>

                      <Box className={classes.imageBoxOEMThemeOverlay}>
                        <Tooltip title={strings.toolTipEdit}>
                          <IconButton className={classes.imageBoxOEMThemeIconHolder} onClick={handleEditLogoClick}>
                            <Edit style={{ color: '#FFF', fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </Grid>
                }

                {values.background === '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormControl variant='outlined' style={{ height: 120 }}>
                      <FormLabel>
                        <Typography className={classes.imageLabel} color='secondary'>{strings.backgroundImage}</Typography>
                        <small className={classes.recomendedSize}>{strings.recomendedSize}: 2048x1152</small>
                      </FormLabel>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file, 'background') }} />
                    </FormControl>
                  </Grid>
                }
                {values.background !== '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormLabel>
                      <Typography className={classes.imageLabel} color='secondary'>{strings.backgroundImage}</Typography>
                      <small className={classes.recomendedSize}>{strings.recomendedSize}: 2048x1152</small>
                    </FormLabel>
                    <FormControl fullWidth variant='outlined'>

                      <Box style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, height: '100%' }}>
                        <img style={{ width: 'auto', maxWidth: '100%', height: 120, maxHeight: 120, objectFit: 'contain' }} src={values.background} alt="" />
                        <input type='file' accept='.png,.jpg,.jpeg' ref={fileUploader2} style={{ display: "none" }} onChange={(file) => { onBackgroundEdit(file) }} />
                      </Box>

                      <Box className={classes.imageBoxOEMThemeOverlay} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <Tooltip title={strings.toolTipEdit}>
                          <IconButton onClick={() => handleEditBackgroundClick()} >
                            <Edit style={{ color: '#FFF', fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={strings.toolTipDelete} >
                          <IconButton onClick={() => onBackgroundDelete()} >
                            <Delete style={{ color: '#FFF', fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </Grid>
                }

                {/* SPLASH  ------------------------ */}
                {values.splash === '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormControl variant='outlined' style={{ height: 120 }}>
                      <FormLabel>
                        <Typography className={classes.imageLabel} color='secondary'>{strings.splash}</Typography>
                        <small className={classes.recomendedSize}>{strings.recomendedSize}: 360x360</small>
                      </FormLabel>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file, 'splash') }} />
                    </FormControl>
                  </Grid>
                }
                {values.splash !== '' &&
                  <Grid item xl={6} lg={12} md={12} sm={12} xs={12} style={{ padding: 5, position: 'relative' }}>
                    <FormLabel>
                      <Typography className={classes.imageLabel} color='secondary'>{strings.splash}</Typography>
                      <small className={classes.recomendedSize}>{strings.recomendedSize}: 360x360</small>
                    </FormLabel>
                    <FormControl fullWidth variant='outlined'>

                      <Box style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, height: '100%' }}>
                        <img style={{ width: 'auto', maxWidth: '100%', height: 120, maxHeight: 120, objectFit: 'contain' }} src={values.splash} alt="" />
                        <input type='file' accept='.png,.jpg,.jpeg' ref={fileUploaderSplash} style={{ display: "none" }} onChange={(file) => { onsplashEdit(file) }} />
                      </Box>

                      <Box className={classes.imageBoxOEMThemeOverlay} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <Tooltip title={strings.toolTipEdit}>
                          <IconButton onClick={() => handleEditsplashClick()} >
                            <Edit style={{ color: '#FFF', fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={strings.toolTipDelete} >
                          <IconButton onClick={() => onsplashDelete()} >
                            <Delete style={{ color: '#FFF', fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </Grid>
                }

                <Grid item xs={12} style={{ paddingTop: 20 }} ></Grid>

                <Grid item xs={12} container spacing={2} >
                  {!editTextColors && !editWidgetsColorsNormal && !editWidgetsColorsHover && !editWidgetsColorsSelected && !editWidgetsColorsDisabled && !editMenuBarColors && !editMenuColors && !editNotificationColors && !editSoftwareIcon && !editSoftwareLogo && !editBackgroundImage &&
                    <Box style={{ minWidth: '280px' }}>
                      <Grid container spacing={2}  >

                        <Grid marginTop={'10px'} item xs={12}>
                          <Typography color='secondary'>{strings.labelGenerateTheme}</Typography>
                        </Grid>
                        <Grid item xs={3} container spacing={2}  >
                          <Grid item xs={12}>
                            <Typography color='primary'>{strings.labelColorTheme1}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color='primary'>{strings.labelColorTheme2}</Typography>
                          </Grid>
                        </Grid>

                        <Grid marginTop={'3px'} item xs={9} container spacing={2}>

                          <Grid item xs={12}>
                            <TextField className={classes.lineEditorMainColors} value={colorsToGenerateTheme.colorTheme1} onChange={handleChangeColorToGenerateTheme('colorTheme1')} onBlur={handleBlurToGenerateTheme('colorTheme1')} variant="standard" />
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: colorsToGenerateTheme.colorTheme1 }} onClick={() => { handleEditColor(colorsToGenerateTheme.colorTheme1, 'colorTheme1'); }} variant='contained' />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField className={classes.lineEditorMainColors} value={colorsToGenerateTheme.colorTheme2} onChange={handleChangeColorToGenerateTheme('colorTheme2')} onBlur={handleBlurToGenerateTheme('colorTheme2')} variant="standard" />
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: colorsToGenerateTheme.colorTheme2 }} onClick={() => { handleEditColor(colorsToGenerateTheme.colorTheme2, 'colorTheme2'); }} variant='contained' />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Button className={classes.lineButton} onClick={() => handleGenerateThemeClick()} variant='contained' color='secondary'>Generate Theme</Button>
                          <div style={{ marginTop: 10, marginLeft: 10 }}>
                            When you click the <b>'Generate Theme'</b> button, all the necessary colors that make up the theme will be generated based on the main and secondary color.
                          </div>
                        </Grid>

                      </Grid>
                    </Box>
                  }

                  {editTextColors && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >
                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelTextColor}</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDefault}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.text_color_default} onChange={handleChangeColor('text_color_default')} onBlur={handleBlur('text_color_default')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_default }} onClick={() => handleEditColor(values.data.text_color_default, 'text_color_default')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelPrimary}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.text_color_primary} onChange={handleChangeColor('text_color_primary')} onBlur={handleBlur('text_color_primary')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_primary }} onClick={() => handleEditColor(values.data.text_color_primary, 'text_color_primary')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabled}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.text_color_disabled} onChange={handleChangeColor('text_color_disabled')} onBlur={handleBlur('text_color_disabled')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_disabled }} onClick={() => handleEditColor(values.data.text_color_disabled, 'text_color_disabled')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Box>
                  }

                  {editWidgetsColorsNormal && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelButtonStatesNormal}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_normal_fill} onChange={handleChangeColor('button_normal_fill')} onBlur={handleBlur('button_normal_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_fill }} onClick={() => { handleEditColor(values.data.button_normal_fill, 'button_normal_fill'); }} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_normal_icon} onChange={handleChangeColor('button_normal_icon')} onBlur={handleBlur('button_normal_icon')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_icon }} onClick={() => handleEditColor(values.data.button_normal_icon, 'button_normal_icon')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalIcon}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_normal_text} onChange={handleChangeColor('button_normal_text')} onBlur={handleBlur('button_normal_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_text }} onClick={() => handleEditColor(values.data.button_normal_text, 'button_normal_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_normal_fill, color: values.data.button_normal_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_normal_icon }} />}>
                            {'text'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                  {editWidgetsColorsHover && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelButtonStatesHover}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelHoverFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_hover_fill} onChange={handleChangeColor('button_hover_fill')} onBlur={handleBlur('button_hover_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_fill }} onClick={() => handleEditColor(values.data.button_hover_fill, 'button_hover_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelHoverIcon}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_hover_icon} onChange={handleChangeColor('button_hover_icon')} onBlur={handleBlur('button_hover_icon')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_icon }} onClick={() => handleEditColor(values.data.button_hover_icon, 'button_hover_icon')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelHoverText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_hover_text} onChange={handleChangeColor('button_hover_text')} onBlur={handleBlur('button_hover_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_text }} onClick={() => handleEditColor(values.data.button_hover_text, 'button_hover_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_hover_fill, color: values.data.button_hover_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_hover_icon }} />}>
                            {'text'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                  {editWidgetsColorsSelected && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelButtonStatesSelected}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_selected_fill} onChange={handleChangeColor('button_selected_fill')} onBlur={handleBlur('button_selected_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_fill }} onClick={() => handleEditColor(values.data.button_selected_fill, 'button_selected_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedIcon}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_selected_icon} onChange={handleChangeColor('button_selected_icon')} onBlur={handleBlur('button_selected_icon')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_icon }} onClick={() => handleEditColor(values.data.button_selected_icon, 'button_selected_icon')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_selected_text} onChange={handleChangeColor('button_selected_text')} onBlur={handleBlur('button_selected_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_text }} onClick={() => handleEditColor(values.data.button_selected_text, 'button_selected_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_selected_fill, color: values.data.button_selected_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_selected_icon }} />}>
                            {'text'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                  {editWidgetsColorsDisabled && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelButtonStatesDisabled}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_disabled_fill} onChange={handleChangeColor('button_disabled_fill')} onBlur={handleBlur('button_disabled_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_fill }} onClick={() => handleEditColor(values.data.button_disabled_fill, 'button_disabled_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledIcon}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_disabled_icon} onChange={handleChangeColor('button_disabled_icon')} onBlur={handleBlur('button_disabled_icon')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_icon }} onClick={() => handleEditColor(values.data.button_disabled_icon, 'button_disabled_icon')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.button_disabled_text} onChange={handleChangeColor('button_disabled_text')} onBlur={handleBlur('button_disabled_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_text }} onClick={() => handleEditColor(values.data.button_disabled_text, 'button_disabled_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_disabled_fill, color: values.data.button_disabled_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_disabled_icon }} />}>
                            {'text'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                  {editMenuBarColors && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelMenuBar}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_bar_fill} onChange={handleChangeColor('menu_bar_fill')} onBlur={handleBlur('menu_bar_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_bar_fill }} onClick={() => handleEditColor(values.data.menu_bar_fill, 'menu_bar_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_bar_text} onChange={handleChangeColor('menu_bar_text')} onBlur={handleBlur('menu_bar_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_bar_text }} onClick={() => handleEditColor(values.data.menu_bar_text, 'menu_bar_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>

                      </Grid>
                    </Box>
                  }

                  {editNotificationColors && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid container spacing={2}  >

                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelNotifications}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelPopupFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.notification_popup_fill} onChange={handleChangeColor('notification_popup_fill')} onBlur={handleBlur('notification_popup_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_fill }} onClick={() => handleEditColor(values.data.notification_popup_fill, 'notification_popup_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelPopupTitle}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.notification_popup_title} onChange={handleChangeColor('notification_popup_title')} onBlur={handleBlur('notification_popup_title')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_title }} onClick={() => handleEditColor(values.data.notification_popup_title, 'notification_popup_title')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelPopupText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.notification_popup_text} onChange={handleChangeColor('notification_popup_text')} onBlur={handleBlur('notification_popup_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_text }} onClick={() => handleEditColor(values.data.notification_popup_text, 'notification_popup_text')} variant='contained' />
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Box>
                  }

                  {editMenuColors && themeGenerated &&
                    <Box style={{ minWidth: '350px' }}>
                      <Grid item xs={12} container spacing={2} >
                        <Grid item xs={12}>
                          <Typography color='secondary'>{strings.labelMenu}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_normal_fill} onChange={handleChangeColor('menu_normal_fill')} onBlur={handleBlur('menu_normal_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_normal_fill }} onClick={() => handleEditColor(values.data.menu_normal_fill, 'menu_normal_fill')} variant='contained' />
                            </Grid>
                          </Grid>


                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_normal_text} onChange={handleChangeColor('menu_normal_text')} onBlur={handleBlur('menu_normal_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_normal_text }} onClick={() => handleEditColor(values.data.menu_normal_text, 'menu_normal_text')} variant='contained' />
                            </Grid>
                          </Grid>
                        </Grid>


                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_selected_fill} onChange={handleChangeColor('menu_selected_fill')} onBlur={handleBlur('menu_selected_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_selected_fill }} onClick={() => handleEditColor(values.data.menu_selected_fill, 'menu_selected_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_selected_text} onChange={handleChangeColor('menu_selected_text')} onBlur={handleBlur('menu_selected_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_selected_text }} onClick={() => handleEditColor(values.data.menu_selected_text, 'menu_selected_text')} variant='contained' />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={2}  >
                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledFill}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_disabled_fill} onChange={handleChangeColor('menu_disabled_fill')} onBlur={handleBlur('menu_disabled_fill')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_disabled_fill }} onClick={() => handleEditColor(values.data.menu_disabled_fill, 'menu_disabled_fill')} variant='contained' />
                            </Grid>
                          </Grid>

                          <Grid item xs={6} container spacing={1}>
                            <Grid item xs={12}>
                              <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledText}</Typography>
                              <TextField className={classes.lineEditor} value={values.data.menu_disabled_text} onChange={handleChangeColor('menu_disabled_text')} onBlur={handleBlur('menu_disabled_text')} variant="standard" />
                              <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_disabled_text }} onClick={() => handleEditColor(values.data.menu_disabled_text, 'menu_disabled_text')} variant='contained' />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      }
      {settingsOEMThemeValues === undefined &&
        <CircularProgress />
      }

      <Dialog open={colorPickerOpen} onClose={() => { handleEditColorClose(false); setColorPickerOpen(false) }}>
        <DialogTitle className={classes.title}>{strings.labelPickColor}</DialogTitle>
        <DialogContent>
          <SketchPicker
            color={colorToEdit.value}
            onChangeComplete={colorToEdit.color !== undefined ?
              (colorToEdit.color.includes('Theme') ? handleChangeColorToGenerateTheme(colorToEdit.color) : handleChangeColor(colorToEdit.color)) :
              false}
          />
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => { handleEditColorClose(true); setColorPickerOpen(false) }} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={imageCrop.icon !== ''} onClose={() => setImageCrop({ ...imageCrop, icon: '' })}>
        <DialogTitle className={classes.title}>{strings.labelCropImage}</DialogTitle>
        <DialogContent>
          <ReactCrop src={imageCrop.icon} crop={crop} onChange={newCrop => setCrop(newCrop)} />
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleEditColorClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={noThemeDialog} onClose={handleCloseNoThemeDialog}>
        <DialogTitle className={classes.title}>{strings.noGeneratheThemeDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.noGeneratheThemeDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={handleCloseNoThemeDialog} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={popupHasGenerateTheme} onClose={() => handleHasGeneratedThemePopupClick(false)}>
        <DialogTitle className={classes.title}>{strings.noGeneratheThemeDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.popupHasGenerateThemeDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleHasGeneratedThemePopupClick(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleHasGeneratedThemePopupClick(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={generalModalOpen} onClose={() => setGeneralModalOpen(false)}>
        <DialogTitle className={classes.title}>{generalModalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{generalModalText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => setGeneralModalOpen(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
    </Box>


  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditorForceRefresh: state.settingsOEMEditorForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
    editSoftwareIcon: state.editSoftwareIcon,
    editSoftwareLogo: state.editSoftwareLogo,
    editBackgroundImage: state.editBackgroundImage,
    editTextColors: state.editTextColors,
    editWidgetsColorsNormal: state.editWidgetsColorsNormal,
    editWidgetsColorsSelected: state.editWidgetsColorsSelected,
    editWidgetsColorsHover: state.editWidgetsColorsHover,
    editWidgetsColorsDisabled: state.editWidgetsColorsDisabled,
    editMenuColors: state.editMenuColors,
    editMenuBarColors: state.editMenuBarColors,
    editNotificationColors: state.editNotificationColors,
    themeGenerated: state.themeGenerated,
    clickNoThemeGenerated: state.clickNoThemeGenerated,
  }
};

export default connect(mapStateToProps)(withStyles(gStyles)(SettingsOEMEditorSimple));