import React from 'react';
import { useParams } from 'react-router-dom';
import { Switch, Route, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Paper, AppBar, Toolbar, Tabs, Tab } from '@mui/material';

import SettingsOEMDetailUpgrades from './SettingsOEMDetailUpgrades';
import SettingsOEMDetailEditions from './SettingsOEMDetailEditions';
import SettingsOEMDetailPlugins from './SettingsOEMDetailPlugins';


let strings=new LocalizedStrings({
	en:{
    title:'Editions/Upgrades',

    tabUpgrades:'Upgrades',
    tabEditions:'Editions',
    tabPlugins:'Plugins',
	}
})


function SettingsOEMDetailDashboard( props ) {

  const { classes } = props;
  const { oemId } = useParams();

  return (
      <Paper className={ classes.selectedItemPaperOEM }>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs centered textColor="inherit" indicatorColor="secondary" value={ location.pathname.split('/')[3] }>
                  <Tab label={ strings.tabEditions }  component={ Link } value='editions' to={ '/settings/' + oemId + '/editions' }/>
                  <Tab label={ strings.tabUpgrades } component={ Link } value='upgrades' to={ '/settings/' + oemId + '/upgrades' }/>
                  <Tab label={ strings.tabPlugins } component={ Link } value='plugins' to={ '/settings/' + oemId + '/plugins' }/>
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/settings/:oemId/editions'><SettingsOEMDetailEditions/></Route>
          <Route path='/settings/:oemId/upgrades'><SettingsOEMDetailUpgrades/></Route>
          <Route path='/settings/:oemId/plugins'><SettingsOEMDetailPlugins/></Route>
        </Switch>        
      </Paper>

  );
}

const mapStateToProps = state => { 
  return {
    userType: state.userType,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMDetailDashboard ) );