import React, { useEffect } from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getCustomer } from './CustomerRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Paper, AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@mui/material';
import  { Close } from '@mui/icons-material';

import CustomerDetail from './CustomerDetail';
import CustomerContactTable from './CustomerContactTable';
import CustomerLicenseTable from './CustomerLicenseTable';

let strings=new LocalizedStrings({
	en:{
    tabDetails:'Details',
    tabContacts:'Contacts',
    tabLicenses:'Licenses',
	}
})


function CustomerDetailDashboard( props ) {

  const { classes } = props;

  const { customerSelected } = props;
  const { customerId } = useParams();

  useEffect(
    ( ) => {
      getCustomer( customerId );
    },
    [ customerId ]
  );

  return (
      <Paper className={ classes.selectedItemPaper }>
        <Toolbar variant="dense"> 
          <Typography className={ classes.flexExpand } variant='h6'>{ customerSelected !== undefined ? customerSelected.name : '' }</Typography>
          <IconButton component={ Link } to='/customers'>
            <Close />
          </IconButton>
        </Toolbar>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
                render={({ location }) => ( 
                  <Tabs textColor="inherit" indicatorColor="secondary" value={ location.pathname.split('/')[3] } >
                    <Tab label={ strings.tabDetails }
                      component={ Link } value='details' to={ '/customers/' + customerId + '/details' }/>
                    <Tab label={ strings.tabContacts } disabled={ customerId === '0' }
                      component={ Link } value='contacts' to={ '/customers/' + customerId + '/contacts' }/>
                    <Tab label={ strings.tabLicenses } disabled={ customerId === '0' }
                      component={ Link } value='licenses' to={ '/customers/' + customerId + '/licenses' }/>
                  </Tabs>
              ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/customers/:customerId/details'><CustomerDetail/></Route>
          <Route path='/customers/:customerId/contacts'><CustomerContactTable/></Route>
          <Route path='/customers/:customerId/licenses'><CustomerLicenseTable/></Route>
        </Switch>      
      </Paper>

  );
}

const mapStateToProps = state => { 
  return { 
    customerSelected: state.customerSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( CustomerDetailDashboard ) );