import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { refreshPrinterTable } from '../utils/Reducer';
import { uploadPrinters } from './PrinterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import UploadCsvDialog from '../components/UploadCsvDialog';

let strings=new LocalizedStrings({
	en:{
    title: 'Upload Printers',
    printerInUseMsg: '{line} - Printer in use',
    distributorNotAllowedMsg: '{line} - Distributor is not allowed',
	}
})

const checkSpecError = ( msg ) => { 
  if ( msg === 'PRINTER_IN_USE' ) return strings.printerInUseMsg;
  if ( msg === 'NOT_ALLOWED' ) return strings.distributorNotAllowedMsg;
  return undefined;
}

function PrinterWizardUpload( props ) { 

  return (
    <UploadCsvDialog
      open={ props.open }
      title={ strings.title }
      headers = { [
        { name: 'serialNumber', index: undefined, mandatory: true, maxLength: 48 },
      ] }
      refresh={ () => props.refreshPrinterTable() }
      uploadRest={ ( list, callback ) => uploadPrinters( list, callback ) }
      checkSpecError={ ( msg ) => checkSpecError( msg ) }
      onClose={ ( ) => props.onClose( ) }/>
  );
 }

 export default connect( null, { refreshPrinterTable } )( withStyles( gStyles )( PrinterWizardUpload ) );