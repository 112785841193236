import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMDistributor, cancelBuild } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, } from '@mui/material';
import {
  Box, Paper, Typography,
  CircularProgress, Container, Grid,
  Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';
import AppleIcon from '@mui/icons-material/Apple';
import CancelIcon from '@mui/icons-material/Cancel';

import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings = new LocalizedStrings({
  en: {
    title: 'OEM',
    titleDialog: 'New OEM',

    tableHeaderDistributor: 'Distributor',
    tableHeaderName: 'Name',
    tableHeaderDesignation: 'Designation',
    tableHeaderPrefix: 'Prefix',
    tableHeaderLinks: 'Download Links',
    tableHeadeState: 'State',
    tableHeaderNotes: 'Notes',


    labelDistributor: 'Distributor',
    labelName: 'Name',
    labelDesignation: 'Designation',
    labelPrefix: 'Prefix',
    labelNotes: 'Notes',

    buttonAdd: 'New OEM',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit OEM',
    toolTipDelete: 'Delete OEM',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this OEM?',
    yes: 'Yes',
    no: 'No',
    ok: 'ok',

    buildRunningTitle: 'Warning',
    buildRunningWarning: 'You can\'t edit OEM while build is running.',
  }
})

function SettingsOEMDistributor(props) {

  const { classes } = props;

  const { settingsOEMDistributorTableForceRefresh } = props;
  const { settingsOEMDistributorList } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);
  const [buildRunningWarningOpen, setBuildRunningWarningOpen] = useState(false);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [genericModalTitle, setGenericModalTitle] = useState('');
  const [genericModalText, setGenericModalText] = useState('');

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      setCurrentPage(parseInt(page))
      setCurrentRowsPerPage(parseInt(rowsPerPage))
    },
    []
  );

  useEffect(
    () => {
      getSettingsOEMDistributor(currentPage * currentRowsPerPage, currentRowsPerPage);
    },
    [currentPage, currentRowsPerPage, page, rowsPerPage, settingsOEMDistributorTableForceRefresh]
  );

  const buildRunnigWarning = () => {
    setBuildRunningWarningOpen(true);
  };
  const cancelBuildJob = (OemID) => {
    (async () => {
      const cancelBuildResult = await cancelBuild(OemID);
      if (cancelBuildResult === true) {
        getSettingsOEMDistributor(currentPage * currentRowsPerPage, currentRowsPerPage);
        setGenericModalTitle("Success")
        setGenericModalText("Builds canceled")
        setGenericModalOpen(true);
      } else {
        setGenericModalTitle("Error")
        setGenericModalText("Error canceling builds")
        setGenericModalOpen(true);
      }
    })();
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Paper className={classes.workareaTopBoxes}>
              {settingsOEMDistributorList !== undefined &&
                <Box className={classes.tableArea}>

                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant='h6'>{strings.tableHeaderName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6'>{strings.tableHeaderDesignation}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6'>{strings.tableHeaderPrefix}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6'>{strings.tableHeaderLinks}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6'>{strings.tableHeadeState}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {settingsOEMDistributorList.items.map((oem) => (
                        <TableRow key={oem.productfamily_id}>

                          <TableCell style={{}}>
                            {oem.build_completed === 1 &&
                              <Link className={classes.link} to={'/myOEM/' + oem.productfamily_id + '/variables'}>
                                <Typography variant='body2'>{oem.name}</Typography>
                              </Link>
                            }
                            {oem.build_completed !== 1 &&

                              <Link className={classes.link} onClick={buildRunnigWarning}>
                                <Typography variant='body2'>{oem.name}</Typography>
                              </Link>
                            }
                          </TableCell>

                          <TableCell style={{}}>{oem.designation}</TableCell>
                          <TableCell style={{}}>{oem.prefix}</TableCell>

                          <TableCell style={{}}>
                            {oem.url_win !== undefined && oem.url_win != '' && oem.url_win !== null &&
                              <Link className={classes.link} style={{ textDecoration: 'none' }} to={{ pathname: oem.url_win }} target="_blank">
                                <Button variant='contained' color='secondary'>
                                  <WindowIcon style={{ marginRight: 10 }} /> Windows
                                </Button>
                              </Link>
                            }
                            {oem.url_win !== undefined && oem.url_win != '' && oem.url_win !== null &&
                              <div style={{ display: 'inline-block', marginRight: 10 }}></div>
                            }
                            {oem.url_mac !== undefined && oem.url_mac !== '' && oem.url_mac !== null &&
                              <Link className={classes.link} style={{ textDecoration: 'none' }} to={{ pathname: oem.url_mac }} target="_blank">
                                <Button variant='contained' color='secondary'>
                                  <AppleIcon style={{ marginRight: 10 }} /> MAC
                                </Button>
                              </Link>
                            }
                          </TableCell> 
                          <TableCell style={{}}> 
                            {oem.build_status !== undefined && oem.build_status !== '' ? oem.build_status : "--"}
                            {oem.build_completed !== 1 && oem.build_status === null ? 'Waiting for start...' : '' } 
                            {oem.build_completed !== 1 && oem.build_status !== null && 
                              <Button style={{ marginLeft: 10 }} variant='contained' color='secondary' onClick={() => { cancelBuildJob(oem.productfamily_id) }} >
                                <CancelIcon style={{ marginRight: 10 }} /> Cancel
                              </Button>
                            }
                          </TableCell>

                        </TableRow>))
                      }
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMDistributorList.count} rowsPerPage={currentRowsPerPage} page={currentPage}
                          SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                          onPageChange={(event, newPage) => { handleChangePage(history, newPage); setCurrentPage(newPage); }}
                          onRowsPerPageChange={(event) => { handleChangeRowsPerPage(history, event, page, rowsPerPage); setCurrentRowsPerPage(parseInt(event.target.value)) }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              }
              {settingsOEMDistributorList === undefined &&
                <CircularProgress className={classes.spinner} />
              }
            </Paper>
          </Box>
        </Grid>
      </Grid >

      <Dialog open={genericModalOpen}>
        <DialogTitle className={classes.title}>{genericModalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{genericModalText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => setGenericModalOpen(false)} variant='contained' color='secondary' autoFocus>{strings.ok}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={buildRunningWarningOpen}>
        <DialogTitle className={classes.title}>{strings.buildRunningTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.buildRunningWarning}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => setBuildRunningWarningOpen(false)} variant='contained' color='secondary' autoFocus>{strings.ok}</Button>
        </DialogActions>
      </Dialog>

    </Container >
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMTableForceRefresh: state.settingsOEMTableForceRefresh,
    settingsOEMDistributorList: state.settingsOEMDistributorList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMDistributor));