import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Grid, Container, Typography, Switch, Stack, Button, Box, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';

import SettingsOEMEditorAdvanced from './SettingsOEMEditor';
import SettingsOEMEditorSimple from './SettingsOEMEditorSimple';
import SettingsOEMWelcomeView from './SettingsOEMWelcomeView';
import SettingsOEMDesignView from './SettingsOEMDesignView';

import resetIcon from './../resources/icons/cleanIcon.png';

let strings = new LocalizedStrings({
  en: {
    resetDialogTitle: 'Reset confirmation',
    resetDialogText: 'Are you sure you want to reset all OEM theme values?',

    confirm: 'confirm',
    cancel: 'cancel',

  }
})

function SettingsOEMTheme(props) {

  const { classes } = props;
  const { settingsOEMThemeValues } = props;
  const { switchButtonEditor } = props;
  // const { switchButtonPreview } = props;

  const [isFixed, setIsFixed] = useState(false);
  const targetRef = useRef(null);

  const [reset, setReset] = useState(false);
  const [switchButtonPreview, setSwitchButtonPreview] = useState(false);

  const dispatch = useDispatch();

  const handleChangeSwitch = (event) => {
    dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: event.target.checked });
  };

  const handleChangeSwitch1 = (event) => {
    // dispatch({ type: 'SET_SWITCH_BUTTON_PREVIEW', value: event.target.checked });
    setSwitchButtonPreview(event.target.checked);
  };

  const handleReset = () => {
    setReset(true);
  }
  const handleResetClose = (confirm) => {
    if (confirm) {
      dispatch({ type: 'SET_OEM_HAS_CHANGES', value: true });
      dispatch({
        type: 'SET_SETTINGS_OEM_THEME_VALUES', value: {
          ...settingsOEMThemeValues,
          logo: '',
          icon: '',
          background: '',
          splash: '',
          data: {
            ...settingsOEMThemeValues.data, background_color_default: undefined,
            background_color_primary: undefined,
            text_color_default: undefined,
            text_color_primary: undefined,
            text_color_disabled: undefined,
            button_normal_fill: undefined,
            button_normal_outline: undefined,
            button_normal_icon: undefined,
            button_normal_text: undefined,
            button_hover_fill: undefined,
            button_hover_icon: undefined,
            button_hover_text: undefined,
            button_selected_fill: undefined,
            button_selected_outline: undefined,
            button_selected_icon: undefined,
            button_selected_text: undefined,
            button_disabled_fill: undefined,
            button_disabled_outline: undefined,
            button_disabled_icon: undefined,
            button_disabled_text: undefined,
            menu_bar_fill: undefined,
            menu_bar_outline: undefined,
            menu_bar_text: undefined,
            menu_normal_fill: undefined,
            menu_normal_outline: undefined,
            menu_normal_text: undefined,
            menu_over_fill: undefined,
            menu_over_outline: undefined,
            menu_over_text: undefined,
            menu_selected_fill: undefined,
            menu_selected_outline: undefined,
            menu_selected_text: undefined,
            menu_disabled_fill: undefined,
            menu_disabled_outline: undefined,
            menu_disabled_text: undefined,
            notification_popup_fill: undefined,
            notification_popup_outline: undefined,
            notification_popup_title: undefined,
            notification_popup_text: undefined,
          }
        }
      });
    }
    setReset(false);
    dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFixed(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Box marginTop={'20px'} minHeight={'100%'} height={'100%'}>
      <Container minheight={'100%'} maxWidth={false}
        style={{
          width: '100%',
          height: '85%',
          overflow: 'auto'
        }}
      >
        <Grid container spacing={2} style={{ height: '100%' }} >
          <Grid style={{ borderRight: '2px solid #CCCCCC', height: '100%', overflow: 'auto' }} item xs={3}>
            <Stack direction="row">
              <Typography>Simple</Typography>
              <Switch size="small" checked={switchButtonEditor} onChange={handleChangeSwitch} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography>Advanced</Typography>
            </Stack>
            <Grid container>
              <Button style={{ marginTop: '8px' }} onClick={handleReset} variant="contained" color='secondary' startIcon={<img src={resetIcon} alt='' style={{ marginLeft: '6px' }} />}>RESET</Button>
            </Grid>
            {!switchButtonEditor &&
              <SettingsOEMEditorSimple />
            }
            {switchButtonEditor &&
              <SettingsOEMEditorAdvanced />
            }
          </Grid>

          <Grid item xs={9}
            style={{
              height: '100%',
              position:  "relative",
            }}
          > 
            <div ref={targetRef}></div>
            <div
              style={{
                // height: '100%',
                position: isFixed ? 'fixed' : 'static',
                // left: '0',
                // right: '0',
                left: '50%',                
                transform: isFixed ? 'translateX(-25%)' : 'unset',
                top: 120,
                // bottom: '0',
                zIndex: '999',
              }}
            >
              <Stack marginLeft='10px' direction="row">
                <Typography>Welcome</Typography>
                <Switch size="small" checked={switchButtonPreview} onChange={handleChangeSwitch1} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>Design</Typography>
              </Stack>
              <Grid style={{ overflow: 'auto' }} direction="column" alignItems="center" justifyContent="center" container>
                <Grid item xs={12} >
                  {!switchButtonPreview &&
                    <SettingsOEMWelcomeView />
                  }
                  {switchButtonPreview &&
                    <SettingsOEMDesignView />
                  }
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={reset} style={{ zIndex: 0 }} onClose={() => handleResetClose(false)}>
        <DialogTitle className={classes.title}>{strings.resetDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.resetDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleResetClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
          <Button className={classes.lineButton} onClick={() => handleResetClose(false)} variant='contained' color='secondary' autoFocus>{strings.cancel}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
    switchButtonEditor: state.switchButtonEditor,
    switchButtonPreview: state.switchButtonPreview,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMTheme));