import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getPrinterModels } from './PrinterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Paper, Typography, CircularProgress } from '@mui/material';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

let strings = new LocalizedStrings({
  en: {
    title: 'Printers by Model',
  }
})

function PrinterByModel(props) {

  const { classes } = props;

  const { printerModelList } = props;
  const { printerTableForceRefresh } = props;

  useEffect(
    () => {
      getPrinterModels();
    },
    [printerTableForceRefresh]
  );

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <Paper className={classes.workareaTopBoxes}>
      <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
      {printerModelList !== undefined &&
        <div className={classes.piechart}>
          <Pie data={printerModelList}
            legend={{ display: false, position: 'right' }}
            options={
              {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                }
              }
            } />
        </div>
      }
      {printerModelList === undefined &&
        <CircularProgress className={classes.spinner} />
      }
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    printerTableForceRefresh: state.printerTableForceRefresh,
    printerModelList: state.printerModelList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(PrinterByModel));