import { store } from '../index';
import { setCustomerList, setCustomerSelected, setCustomerContactList, refreshCustomerContactTable, setCustomerLicenseList, refreshCustomerLicenseTable, refreshCustomerTable } from '../utils/Reducer';
import { handleExportCustomersToCSV } from '../utils/Utils';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getCustomers
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getCustomers( from, limit, search ) {

  const onError = error => {
    gError( 'getCustomers', error ); 
    store.dispatch( setCustomerList( { count:0, items:[] } ) );
  }

  store.dispatch( setCustomerList( undefined ) );
  store.dispatch( setCustomerSelected( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ), {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setCustomerList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportCustomers
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportCustomers( ) {

  const onError = error => {
    gError( 'exportCustomers', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers?from=0&limit=100000', {
    method: 'GET', 
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        handleExportCustomersToCSV( json );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getCustomer
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getCustomer( customerId ) {

  const onError = error => {
    gError( 'getCustomer', error ); 
    store.dispatch( setCustomerSelected( undefined ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setCustomerSelected( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createCustomer
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createCustomer( customer ) {

  const onError = error => {
    gError( 'createCustomer', error ); 
    store.dispatch( setCustomerSelected( undefined ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/create', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken 
    },
    body : JSON.stringify( customer )
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( refreshCustomerTable( ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// uploadCustomers
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function uploadCustomers( customers, callback ) {

  const onError = error => {
    gError( 'uploadCustomers', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/upload', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken 
    },
    body: JSON.stringify( customers ),
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        callback( json );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setCustomer
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setCustomer( customer ) {

  const onError = error => {
    gError( 'setCustomer', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customer.customer_id, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( customer )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( setCustomerSelected( customer ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteCustomer
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteCustomer( customer ) {

  const onError = error => {
    gError( 'deleteCustomer', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customer.customer_id,{
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getCustomerContacts
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getCustomerContacts( customerId, from, limit ) {

  const onError = error => {
    gError( 'getCustomerContacts', error );
    store.dispatch( setCustomerContactList( { count:0, items:[] } ) );
  }

  store.dispatch( setCustomerContactList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/contacts?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken 
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setCustomerContactList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setCustomerContact
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setCustomerContact( customerId, contact ) {

  const onError = error => {
    gError( 'setCustomerContact', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/contacts/' + contact.contact_id, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( contact )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerContactTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteCustomerContact
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteCustomerContact( customerId, contact ) {

  const onError = error => {
    gError( 'deleteCustomerContact', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/contacts/' + contact.contact_id, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerContactTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setCustomerDefaultContact
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setCustomerDefaultContact( customerId, contact ) {

  const onError = error => {
    gError( 'setCustomerDefaultContact', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/contacts/' + contact.contact_id + '/setdefault', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerContactTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getCustomerLicenses
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getCustomerLicenses( customerId, from, limit ) {

  const onError = error => {
    gError( 'getCustomerLicenses', error );
    store.dispatch( setCustomerLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setCustomerLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/licenses?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setCustomerLicenseList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// addCustomerLicenses
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function addCustomerLicenses( customerId, assingList ) {

  const onError = error => {
    gError( 'addCustomerLicenses', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/licenses', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( assingList )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteCustomerLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteCustomerLicense( customerId, license ) {

  const onError = error => {
    gError( 'deleteCustomerLicense', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/customers/' + customerId + '/licenses/' + license.license, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json', 
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshCustomerLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
