import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect, useDispatch } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Paper, AppBar, Toolbar, Tabs, Tab, Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@mui/material';
import { getSettingsOEMTheme, setSettingsOEMTheme, buildOEM, sendBuildServerDownEmail } from './SettingsRest';
import { checkIfBuidlReady } from '../utils/Utils';
import { setSettingsOEMThemeValues } from '../utils/Reducer';
import { store } from '../index';

import SettingsOEMGrid from './SettingsOEMGrid';
import SettingsOEMTheme from './SettingsOEMTheme';
import SettingsOEMEditions from './SettingsOEMEditions';
import SettingsOEMUpgrades from './SettingsOEMUpgrades';
import SettingsOEMPlugins from './SettingsOEMPlugins';
import SettingsOEMWhatsNew from './SettingsOEMWhatsNew';
import SettingsOEMLearning from './SettingsOEMLearning';
import SettingsOEMVariables from './SettingsOEMVariables';
import SettingsOEMNotifications from './SettingsOEMNotifications';
import { HelpOutline } from '@mui/icons-material';
import YouTube from 'react-youtube';

let strings = new LocalizedStrings({
  en: {
    title: 'MY OEM CUSTOMIZATION',

    tabGrid: 'APP DEFAULT LAYOUT',
    tabTheme: 'APP CUSTOM THEME',
    tabEditions: 'EDITIONS',
    tabUpgrades: 'UPGRADES',
    tabPlugins: 'PLUGINS',
    tabWhatsNew: 'WHAT\'S NEW',
    tabLearning: 'LEARNING',
    tabVariables: 'INFORMATION AND CONTACTS',
    tabNotifications: 'NOTIFICATIONS',

    incompleteBuildDialogTitle: 'Build Fail',
    incompleteBuildDialogText: 'Please fill all the required fields',
    buidlServerDownDialogTitle: 'Build Fail',
    buidlServerDownDialogText: 'Build server is in mantainence, please try again later.',
    saveThemeChangesDialogTitle: 'Save theme',
    saveThemeChangesDialogText: 'There are chages on theme',
    buildStartedDialogTitle: 'Build Started',
    buildStartedDialogText: 'Your build started successfully, you will receive an email when finished',
    helpVideoTitle: 'Help',

    buildStartConfirmDialogTitle: "Build confirmation",
    buildStartConfirmDialogText: "Are you sure you want to start your build? Click 'Yes' if you want to continue otherwise choose 'Cancel'.",

    ok: 'ok',
    yes: 'yes',
    save: 'save',
    cancel: 'cancel',
    build: 'Build',
    help: 'Help',
    close: 'Close',
    continueWithoutSave: 'Continue without save',
    closeWithoutSave: 'Close without save'
  }
})


function SettingsOEMDashboard(props) {

  const { classes } = props;
  const { oemId } = useParams();
  const { settingsOEMThemeValues } = props;
  const { buidlServerDown } = props;
  const { buildStarted } = props;
  const { themeHasChanges } = props;
  const { OEMHasChanges } = props;

  const [helpVideoOpen, setHelpVideoOpen] = useState(false);
  const [buidlIncomplete, setBuidlIncomplete] = useState(false);
  const [saveThemeChanges, setSaveThemeChanges] = useState(false);
  const [confirmUnsavedExitAlert, setConfirmUnsavedExitAlert] = useState(false);

  const [buildStartConfirm, setBuildStartConfirm] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    return () => {
      setSaveThemeChanges(false);
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: false });
      dispatch({ type: 'SET_OEM_HAS_CHANGES', value: false });
      store.dispatch(setSettingsOEMThemeValues([]));
    };
  }, []);

  useEffect(
    () => {
      if (!(history.location.pathname.includes('theme')) && themeHasChanges) {
        history.replace('/myOEM/' + oemId + '/variables');
        // setSaveThemeChanges(true);
      } else if (!themeHasChanges && !OEMHasChanges) {
        getSettingsOEMTheme(oemId);
      }
    },
    [oemId, buidlServerDown, buildStarted, history, themeHasChanges, OEMHasChanges]
  );

  const close = () => {
    if (themeHasChanges === undefined || themeHasChanges === false) {
      history.push('/MyOEMs')
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', settingsOEMThemeValues: [] });
    } else {
      setConfirmUnsavedExitAlert(true);
    }
  };
  const closeWithoutSave = () => {
    history.push('/MyOEMs');
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', settingsOEMThemeValues: [] });
  };

  const handleSave = () => {
    setSettingsOEMTheme(settingsOEMThemeValues);
  };

  const handleBuild = () => {

    if (settingsOEMThemeValues.logo !== '' && settingsOEMThemeValues.icon !== '' && checkIfBuidlReady(settingsOEMThemeValues.data)) {
      buildOEM(oemId);
    } else {
      setBuidlIncomplete(true);
    }

  };

  const handleBuidlServerDown = () => {
    sendBuildServerDownEmail(oemId);
    dispatch({ type: 'SET_BUILD_SERVER_DOWN', value: false });
  }

  const handleBuildStarted = () => {
    dispatch({ type: 'SET_BUILD_STARTED', value: false });
  }

  const handleSaveThemeChanges = (confirm) => {
    if (confirm) {
      setSettingsOEMTheme(settingsOEMThemeValues);
    } else {
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: false });
      dispatch({ type: 'SET_OEM_HAS_CHANGES', value: false });
      setSaveThemeChanges(false);
    }
  }

  const currentTab = (loc) => {
    // if (!(loc.pathname.includes('theme')) && themeHasChanges) {
    //   dispatch({ type: 'SET_OEM_HAS_CHANGES', value: true });
    // }
    var tab = loc.pathname.split('/')[3];
    if (
      tab === 'grid' ||
      tab === 'theme' ||
      tab === 'editions' ||
      tab === 'upgrades' ||
      tab === 'plugins' ||
      tab === 'whatsnew' ||
      tab === 'learning' ||
      tab === 'variables' ||
      tab === 'notifications'
    ) {
      return tab;
    }
    return false;
  }

  const videoOpts = {
    width: '800',
    height: '450',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
      rel: 0
    },
  };

  return (
    <Paper className={classes.selectedItemPaperOEM}>
      <Grid container>
        <Grid item xs={6}>
          <Typography marginLeft={'25px'} color='secondary'>{strings.title}</Typography>
        </Grid>

        <Grid container item xs={6} justifyContent="flex-end">

          <Button className={classes.lineButton} onClick={() => { setHelpVideoOpen(true) }} variant='contained' color='secondary'>
            <HelpOutline fontSize='small' style={{ marginRight: 10 }} /> {strings.help}
          </Button>

          <Button onClick={() => close()} className={classes.lineButton} variant='contained' color='secondary' >
            {strings.close}
          </Button>


          <Button disabled={(OEMHasChanges ? false : !themeHasChanges)} className={classes.lineButton} onClick={() => handleSave()} variant='contained' color='secondary'> {strings.save} </Button>
          <Button className={classes.lineButton} onClick={() => setBuildStartConfirm(true)} variant='contained' color='secondary'> {strings.build} </Button>
        </Grid>
      </Grid>


      <AppBar className={classes.selectedItemAppBar} position='static' style={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar variant="dense">
          <Route
            render={({ location }) => (
              <Tabs centered textColor="primary" indicatorColor="secondary" value={currentTab(location)}>
                <Tab className={classes.tabsOEMDashboard} label={strings.tabVariables} component={Link} value='variables' to={'/myOEM/' + oemId + '/variables'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabTheme} component={Link} value='theme' to={'/myOEM/' + oemId + '/theme'} />
                {/* <Tab className={classes.tabsOEMDashboard} label={strings.tabGrid} component={Link} value='grid' to={'/myOEM/' + oemId + '/grid'} /> */}
                <Tab className={classes.tabsOEMDashboard} label={strings.tabEditions} component={Link} value='editions' to={'/myOEM/' + oemId + '/editions'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabUpgrades} component={Link} value='upgrades' to={'/myOEM/' + oemId + '/upgrades'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabPlugins} component={Link} value='plugins' to={'/myOEM/' + oemId + '/plugins'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabWhatsNew} component={Link} value='whatsnew' to={'/myOEM/' + oemId + '/whatsnew'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabLearning} component={Link} value='learning' to={'/myOEM/' + oemId + '/learning'} />
                <Tab className={classes.tabsOEMDashboard} label={strings.tabNotifications} component={Link} value='notifications' to={'/myOEM/' + oemId + '/notifications'} />
              </Tabs>
            )} />
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path='/myOEM/:oemId/variables'><SettingsOEMVariables /></Route>
        <Route path='/myOEM/:oemId/theme'><SettingsOEMTheme /></Route>
        {/* <Route path='/myOEM/:oemId/grid'><SettingsOEMGrid /></Route> */}
        <Route path='/myOEM/:oemId/editions'><SettingsOEMEditions /></Route>
        <Route path='/myOEM/:oemId/upgrades'><SettingsOEMUpgrades /></Route>
        <Route path='/myOEM/:oemId/plugins'><SettingsOEMPlugins /></Route>
        <Route path='/myOEM/:oemId/whatsnew'><SettingsOEMWhatsNew /></Route>
        <Route path='/myOEM/:oemId/learning'><SettingsOEMLearning /></Route>
        <Route path='/myOEM/:oemId/notifications'><SettingsOEMNotifications /></Route>
      </Switch>

      <Dialog
        open={helpVideoOpen}
        fullWidth={false}
        maxWidth={'md'}
      >
        <DialogTitle className={classes.title}>{strings.helpVideoTitle}</DialogTitle>
        <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
          <YouTube style={{ alignSelf: 'center' }} videoId="2g811Eo7K8U" opts={videoOpts} />
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => { setHelpVideoOpen(false) }} variant='contained' color='secondary' autoFocus>{strings.close}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={buidlIncomplete}>
        <DialogTitle className={classes.title}>{strings.incompleteBuildDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.incompleteBuildDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => setBuidlIncomplete(false)} variant='contained' color='secondary' autoFocus>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={buidlServerDown}>
        <DialogTitle className={classes.title}>{strings.buidlServerDownDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.buidlServerDownDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleBuidlServerDown(false)} variant='contained' color='secondary' autoFocus>{strings.ok}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={buildStartConfirm}>
        <DialogTitle className={classes.title}>{strings.buildStartConfirmDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.buildStartConfirmDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          {/* <Button className={classes.lineButton} onClick={() => { handleBuildStarted(false); close(); }} variant='contained' color='secondary' autoFocus>{strings.ok}</Button> */}
          <Button className={classes.lineButton} onClick={() => {handleBuild(); setBuildStartConfirm(false); }} variant='contained' color='secondary'>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => setBuildStartConfirm(false)} variant='contained' color='secondary'>{strings.cancel}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={buildStarted}>
        <DialogTitle className={classes.title}>{strings.buildStartedDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.buildStartedDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => { handleBuildStarted(false); close(); }} variant='contained' color='secondary' autoFocus>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={saveThemeChanges}>
        <DialogTitle className={classes.title}>{strings.saveThemeChangesDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.saveThemeChangesDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleSaveThemeChanges(true)} variant='contained' color='secondary' autoFocus>{strings.save}</Button>
          <Button className={classes.lineButton} onClick={() => handleSaveThemeChanges(false)} variant='contained' color='secondary'>{strings.continueWithoutSave}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmUnsavedExitAlert}>
        <DialogTitle className={classes.title}>{strings.saveThemeChangesDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.saveThemeChangesDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => closeWithoutSave(true)} variant='contained' color='secondary' autoFocus>{strings.closeWithoutSave}</Button>
          <Button className={classes.lineButton} onClick={() => setConfirmUnsavedExitAlert(false)} variant='contained' color='secondary'>{strings.cancel}</Button>
        </DialogActions>
      </Dialog>
    </Paper>

  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
    buidlServerDown: state.buidlServerDown,
    buildStarted: state.buildStarted,
    themeHasChanges: state.themeHasChanges,
    OEMHasChanges: state.OEMHasChanges,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMDashboard));