import React, { useEffect }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { formatDateEpoch } from '../utils/Utils';
import { getPrinter } from './PrinterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Typography, CircularProgress } from '@mui/material';

import evolis_access from './../resources/printers/evolis_access.png';
import evolis_badgy100 from './../resources/printers/evolis_badgy100.png';
import evolis_badgy200 from './../resources/printers/evolis_badgy200.png';
import evolis_edikio_duplex from './../resources/printers/evolis_edikio_duplex.png';
import evolis_edikio_flex from './../resources/printers/evolis_edikio_flex.png';
import evolis_primacy from './../resources/printers/evolis_primacy.png';
import evolis_zenius from './../resources/printers/evolis_zenius.png';
import fargo_c50 from './../resources/printers/fargo_c50.png';
import fargo_dtc1000 from './../resources/printers/fargo_dtc1000.png';
import fargo_dtc1250 from './../resources/printers/fargo_dtc1250.png';
import fargo_dtc1500 from './../resources/printers/fargo_dtc1500.png';
import fargo_dtc4500e from './../resources/printers/fargo_dtc4500e.png';
import fargo_hdp5000 from './../resources/printers/fargo_hdp5000.png';
import fargo_hdp5600 from './../resources/printers/fargo_hdp5600.png';
import fargo_hdp6600 from './../resources/printers/fargo_hdp6600.png';
import fargo_hdp8500 from './../resources/printers/fargo_hdp8500.png';
import idp_solid_510 from './../resources/printers/idp_solid_510.png';
import idp_smart_31 from './../resources/printers/idp_smart_31.png';
import idp_smart_51 from './../resources/printers/idp_smart_51.png';
import idp_smart_70 from './../resources/printers/idp_smart_70.png';
import magicard_300 from './../resources/printers/magicard_300.png';
import magicard_600 from './../resources/printers/magicard_600.png';
import magicard_enduro from './../resources/printers/magicard_enduro.png';
import magicard_enduro3e from './../resources/printers/magicard_enduro3e.png';
import magicard_pronto from './../resources/printers/magicard_pronto.png';
import magicard_rio_pro_duo from './../resources/printers/magicard_rio_pro_duo.png';
import magicard_rio_pro_360 from './../resources/printers/magicard_rio_pro_360.png';
import magicard_ultima from './../resources/printers/magicard_ultima.png';
import zebra_zxp1 from './../resources/printers/zebra_zxp1.png';
import zebra_zxp3 from './../resources/printers/zebra_zxp3.png';
import zebra_zxp_series_7 from './../resources/printers/zebra_zxp_series_7.png';
import zebra_zxp_series_8 from './../resources/printers/zebra_zxp_series_8.png';
import zebra_zxp_series_9 from './../resources/printers/zebra_zxp_series_9.png';
import zebra_zc150 from './../resources/printers/zebra_zc150.png';
import zebra_zc350 from './../resources/printers/zebra_zc350.png';
import default_printer from './../resources/printers/default_printer.png';

let strings=new LocalizedStrings({
	en:{
    labelSerialNumber:'Serial number: ',
    labelLicense:'License: ',
    labelManufacturer:'Manufacturer: ',
    labelModel:'Model: ',
    labelFirmware:'Firmware version: ',
    labelDriver:'Driver version: ',
    labelCardCount:'Number of card printed: ',
    labelCreateDate:'First usage: ',
    labelLastUpdate:'Last update: ',

    titleRibbon:'Current Ribbon',
    labelRibbonType:'Type: ',
    labelRibbonQuantity:'Size: ',
    labelRibbonRemain:'Remaining: ',
	}
})

function PrinterDetail( props ) {

  const { classes } = props;

  const { printerSelected } = props;
  const { printerId } = useParams();
  let history = useHistory();

  useEffect(
    () => {
      getPrinter( printerId );
    },
    [ printerId ]
  );

  const getImage = image => {
    const img = image.toLowerCase();
    if ( img.startsWith( 'evolis_access' ) ) return evolis_access;
    if ( img.startsWith( 'evolis_access' ) ) return evolis_access;
    if ( img.startsWith( 'evolis_badgy100' ) ) return evolis_badgy100;
    if ( img.startsWith( 'evolis_badgy200' ) ) return evolis_badgy200;
    if ( img.startsWith( 'evolis_edikio_duplex' ) ) return evolis_edikio_duplex;
    if ( img.startsWith( 'evolis_edikio_flex' ) ) return evolis_edikio_flex;
    if ( img.startsWith( 'evolis_primacy' ) ) return evolis_primacy;
    if ( img.startsWith( 'evolis_zenius' ) ) return evolis_zenius;
    if ( img.startsWith( 'fargo_c50' ) ) return fargo_c50;
    if ( img.startsWith( 'fargo_dtc1000' ) ) return fargo_dtc1000;
    if ( img.startsWith( 'fargo_dtc1250' ) ) return fargo_dtc1250;
    if ( img.startsWith( 'fargo_dtc1500' ) ) return fargo_dtc1500;
    if ( img.startsWith( 'fargo_dtc4500e' ) ) return fargo_dtc4500e;
    if ( img.startsWith( 'fargo_hdp5000' ) ) return fargo_hdp5000;
    if ( img.startsWith( 'fargo_hdp5600' ) ) return fargo_hdp5600;
    if ( img.startsWith( 'fargo_hdp6600' ) ) return fargo_hdp6600;
    if ( img.startsWith( 'fargo_hdp8500' ) ) return fargo_hdp8500;
    if ( img.startsWith( 'idp_solid_510' ) ) return idp_solid_510;
    if ( img.startsWith( 'idp_smart_31' ) ) return idp_smart_31;
    if ( img.startsWith( 'idp_smart_51' ) ) return idp_smart_51;
    if ( img.startsWith( 'idp_smart_70' ) ) return idp_smart_70;
    if ( img.startsWith( 'magicard_300' ) ) return magicard_300;
    if ( img.startsWith( 'magicard_600' ) ) return magicard_600;
    if ( img.startsWith( 'magicard_enduro' ) ) return magicard_enduro;
    if ( img.startsWith( 'magicard_enduro3e' ) ) return magicard_enduro3e;
    if ( img.startsWith( 'magicard_pronto' ) ) return magicard_pronto;      
    if ( img.startsWith( 'magicard_rio_pro_duo' ) ) return magicard_rio_pro_duo;
    if ( img.startsWith( 'magicard_rio_pro_360' ) ) return magicard_rio_pro_360;
    if ( img.startsWith( 'magicard_ultima' ) ) return magicard_ultima;
    if ( img.startsWith( 'zebra_zxp1' ) ) return zebra_zxp1;
    if ( img.startsWith( 'zebra_zxp3' ) ) return zebra_zxp3;
    if ( img.startsWith( 'zebra_zxp_series_7' ) ) return zebra_zxp_series_7;
    if ( img.startsWith( 'zebra_zxp_series_8' ) ) return zebra_zxp_series_8;
    if ( img.startsWith( 'zebra_zxp_series_9' ) ) return zebra_zxp_series_9;
    if ( img.startsWith( 'zebra_zc150' ) ) return zebra_zc150;
    if ( img.startsWith( 'zebra_zc350' ) ) return zebra_zc350;
    return default_printer;
  }

  return (
    <div className={ classes.workarea }>
      { printerSelected !== undefined &&
        <div>
          <Typography variant='body1'>{ strings.labelSerialNumber }<b>{ printerSelected.serial_number }</b></Typography>
          { printerSelected.last_license !== null &&
            <Typography variant='body1'>{ strings.labelLicense }
              <b className={ classes.link } onClick={ () => history.push('/licenses/' + printerSelected.last_license + '/details') }>
                { printerSelected.last_license }
              </b>
            </Typography>
          }
          <Typography variant='body1'>{ strings.labelManufacturer }<b>{ printerSelected.manufacturer }</b></Typography>
          <Typography variant='body1'>{ strings.labelModel }<b>{ printerSelected.model }</b></Typography>
          <Typography variant='body1'>{ strings.labelFirmware }<b>{ JSON.parse(printerSelected.info).firmwareVersion }</b></Typography>
          <Typography variant='body1'>{ strings.labelDriver }<b>{ JSON.parse(printerSelected.info).driverVersion }</b></Typography>
          <Typography variant='body1'>{ strings.labelCardCount }<b>{ printerSelected.last_card_count }</b></Typography>
          <Typography variant='body1'>{ strings.labelCreateDate }<b>{ formatDateEpoch( printerSelected.create_date ) }</b></Typography>
          <Typography variant='body1'>{ strings.labelLastUpdate }<b>{ formatDateEpoch( printerSelected.update_date ) }</b></Typography>
          <div className={ classes.dividerSpacer }/>
          <Typography variant='h6'><b>{ strings.titleRibbon }</b></Typography>
          <Typography variant='body1'>{ strings.labelRibbonType }<b>{ JSON.parse(printerSelected.last_status).ribbonType }</b></Typography>
          <Typography variant='body1'>{ strings.labelRibbonRemain }<b>{ JSON.parse(printerSelected.last_status).ribbonRemain } ({ printerSelected.last_ribbon_remaining/10 }%)</b></Typography>
          <Typography variant='body1'>{ strings.labelRibbonQuantity }<b>{ JSON.parse(printerSelected.last_status).ribbonQty }</b></Typography>
          <img src={ getImage( printerSelected.manufacturer + '_' + printerSelected.model.replace(/ /g, '_').replace(/-/g, '_') ) } className={ classes.floatingImage } alt=''/>
        </div>
      }
      { printerSelected === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </div>
  );
}

const mapStateToProps = state => {
  return {
    printerSelected: state.printerSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( PrinterDetail ) );