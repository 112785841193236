import React, { useEffect }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getPrinterStatus } from './PrinterRest';
import { formatDateEpoch } from '../utils/Utils';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings=new LocalizedStrings({
	en:{
    tableHeaderType:'Type',
    tableHeaderQuantity:'Size',
    tableHeaderRemain:'Remaining',
    tableHeaderRemainPerc:'Remaining (%)',
    tableHeaderLastUsageDate:'Last Usage Date',
	}
})

function PrinterRibbonTable( props ) {

  const { classes } = props;

  const { printerStatusList } = props; 
  const { printerId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 10 : urlRowsPerPage;

  useEffect(
    ( ) => { 
      getPrinterStatus( printerId );
   },
   [ printerId, page, rowsPerPage ]
  );

  return (
    <div className={ classes.workarea }>
      <div className={ classes.tableArea } >
        { printerStatusList !== undefined &&
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderType }</Typography>
                </TableCell>  
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderQuantity }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderRemain }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderRemainPerc }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderLastUsageDate }</Typography>
                </TableCell>                                                                              
              </TableRow>
            </TableHead>
            <TableBody>
              { printerStatusList.ribbons.map( ( ribbon, index ) => { 
                if ( index >= page * rowsPerPage && index <  (page + 1) * rowsPerPage ) {
                  return (
                    <TableRow key={ index }>
                      <TableCell>
                        <Typography variant='body2'>{ ribbon.type }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>{ ribbon.qty }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>{ ribbon.remain }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>{ ( ribbon.remain*1000/ribbon.qty ).toFixed() / 10 }</Typography>
                      </TableCell>                      
                      <TableCell>
                        <Typography variant='body2'>{ formatDateEpoch( ribbon.lastUsageDate ) }</Typography>
                      </TableCell>
                    </TableRow> );
                }
                return ( <tr key={ index }/> );
              } ) }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 5 } count={ printerStatusList.ribbons.length } rowsPerPage={ rowsPerPage } page={ page }
                  SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                  onPageChange={ ( event, newPage ) => handleChangePage( history, newPage )  }
                  onRowsPerPageChange={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                  ActionsComponent={ TablePaginationActions }
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        { printerStatusList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </div>
    </div>
  );
}

const mapStateToProps = state => { 
  return {
    printerStatusList: state.printerStatusList,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( PrinterRibbonTable ) );