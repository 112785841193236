import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Tru',
        friday: 'Fri',
        saturday: 'Sat',

        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    }
})

function paddingZero(value) {
    return (value < 10) ? "0" + value : value;
}

// export function formatDateEpoch( epoch ) {
//     if ( epoch === null ) return '';
//     var date = new Date( epoch*1000 );
//     return date.getFullYear() + '-'
//     + paddingZero( date.getMonth()+1 ) + '-'
//     + paddingZero( date.getDate() ) + ' '
//     + paddingZero( date.getHours() ) + ':'
//     + paddingZero( date.getMinutes() ) + ':'
//     + paddingZero( date.getSeconds() );
// }

export function formatDateEpoch(epoch) {
    if (epoch === null) {
        return ''
    };
    var date = new Date(epoch);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatName(_first_name, _last_name) {
    return (_first_name !== null ? _first_name : "") + (_first_name !== null && _last_name !== null ? " " : "") + (_last_name !== null ? _last_name : "");
}

export function weekDay(day) {
    switch (day) {
        case 0: return strings.sunday;
        case 1: return strings.monday;
        case 2: return strings.tuesday;
        case 3: return strings.wednesday;
        case 4: return strings.thursday;
        case 5: return strings.friday;
        case 6: return strings.saturday;
        default: return strings.sunday;
    }
}

export function month(day) {
    switch (day) {
        case 0: return strings.january;
        case 1: return strings.february;
        case 2: return strings.march;
        case 3: return strings.april;
        case 4: return strings.may;
        case 5: return strings.june;
        case 6: return strings.july;
        case 7: return strings.august;
        case 8: return strings.september;
        case 9: return strings.october;
        case 10: return strings.november;
        case 11: return strings.december;
        default: return strings.january;
    }
}

export function getSoftware(softwareString) {
    var result = "";
    if (softwareString !== null) {
        var start = softwareString.indexOf("[");
        var end = softwareString.indexOf("]");
        if (start !== -1 && end !== -1)
            result = softwareString.slice(start + 1, end);
    }
    return result;
}

export function getSoftwareVersion(softwareString) {
    var result = softwareString;
    if (softwareString !== null) {
        var start = softwareString.indexOf("[");
        if (start !== -1)
            result = softwareString.split("[", 1);
    }
    return result;
}

export function getSoftwareOS(softwareString) {
    var result = "";
    if (softwareString !== null) {
        var end = softwareString.indexOf("]");
        if (end !== -1)
            result = softwareString.slice(end + 1);
    }
    return result;
}

export function updateLicenseWithUpgrades(license, allowUpgrades) {
    var canUpgrade = false;
    var upgradePath = [];
    if (allowUpgrades !== undefined) {
        for (var i = 0; i < allowUpgrades.length; i++) {
            if (license.pcode === allowUpgrades[i].from_pcode) {
                canUpgrade = true;
                upgradePath.push({ pcode: allowUpgrades[i].to_pcode, description: allowUpgrades[i].product_description });
            }
        }
    }
    return { ...license, canUpgrade: canUpgrade, upgradePath: upgradePath };
}

export function saveToLocalStorage(newState) {
    try {
        const serializedState = JSON.stringify({
            authToken: newState.authToken,
            userEmail: newState.userEmail,
            userType: newState.userType,
            needPassword: newState.needPassword,
            allowAssignPrinter: newState.allowAssignPrinter,
            allowUpgrades: newState.allowUpgrades,
            allowFeatures: newState.allowFeatures,
        });
        localStorage.setItem('state', serializedState);
    }
    catch (e) {
        console.log(e);
    }
}

export function clearLocalStorage() {
    try {
        localStorage.removeItem('state');
    }
    catch (e) {
        console.log(e);
    }
}

export function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

export function handleExportCustomersToCSV(customerList) {

    var str = 'customerName,customerVat,customerAddress,contactEmail,contactFirstName,contactLastName,contactPhone,license\r\n';

    for (var i = 0; i < customerList.items.length; i++) {
        var line = '';
        var arrayLine = ['', '', '', '', '', '', '', ''];
        arrayLine[0] = customerList.items[i].name;
        arrayLine[1] = customerList.items[i].vat;
        arrayLine[2] = customerList.items[i].address;
        arrayLine[3] = customerList.items[i].email;
        arrayLine[4] = customerList.items[i].first_name;
        arrayLine[5] = customerList.items[i].last_name;
        arrayLine[6] = customerList.items[i].phone;
        arrayLine[7] = customerList.items[i].license_id;

        for (var l = 0; l < arrayLine.length; l++) {
            if (line !== '') line += ','
            line += arrayLine[l];
        }

        str += line + '\r\n';
    }
    exportDataToCSVFile('customers', str);
}

export function exportDataToCSVFile(fileName, data) {
    if (data !== undefined) {
        var exportedFilename = fileName + '.csv' || 'export.csv';

        var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

export function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image/png",
            1
        );
    });
}


const buildValidation = [
    'background_color_default',
    'background_color_primary',
    'text_color_default',
    'text_color_primary',
    'button_normal_fill',
    'button_normal_outline',
    'button_normal_icon',
    'button_normal_text',
    'button_selected_fill',
    'button_selected_outline',
    'button_selected_icon',
    'button_selected_text',
    'button_disabled_fill',
    'button_hover_fill',
    'button_hover_icon',
    'button_hover_text',
    'button_disabled_outline',
    'button_disabled_icon',
    'button_disabled_text',
    'variable_app_short',
    'variable_app_name',
    'variable_software_website',
    'variable_copyright',
    'variable_company_name',
    'variable_phone',
    'variable_company_email',
    'variable_company_website'
];

export function checkIfBuidlReady(buildData) {

    for (var x = 0; x < buildValidation.length; x++) {
        if (!buildData.hasOwnProperty(buildValidation[x]))
            return false;
    }
    return true;
}
