import React from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Paper, AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import LicenseDetail from './LicenseDetail';
import LicensePrinterTable from './LicensePrinterTable';
import LicenseHistoryTable from './LicenseHistoryTable';
import LicenseUpgradeScreen from './LicenseUpgradeScreen';

let strings = new LocalizedStrings({
  en: {
    tabDetails: 'Details',
    tabPrinters: 'Printers',
    tabHistory: 'History',
    tabLicenseUpgrade: 'License Upgrade',
  }
})

function LicenseDetailDashboard(props) {

  const { classes } = props;

  const { licenseId } = useParams();
  return (
    <Paper className={classes.selectedItemPaper}>
      <Toolbar variant="dense">
        <Typography className={classes.flexExpand} variant='h6'>{licenseId}</Typography>
        <IconButton component={Link} to='/licenses'>
          <Close />
        </IconButton>
      </Toolbar>
      <AppBar className={classes.selectedItemAppBar} position='static'>
        <Toolbar variant="dense">
          <Route
            render={({ location }) => (
              <Tabs textColor="inherit" indicatorColor="secondary" value={location.pathname.split('/')[3]}>
                {location.pathname.split('/')[3] !== 'upgrade' &&
                  <Tab label={strings.tabDetails} component={Link} value='details' to={'/licenses/' + licenseId + '/details'} />
                }
                {location.pathname.split('/')[3] !== 'upgrade' &&
                  <Tab label={strings.tabPrinters} component={Link} value='printers' to={'/licenses/' + licenseId + '/printers'} />
                }
                {location.pathname.split('/')[3] !== 'upgrade' &&
                  <Tab label={strings.tabHistory} component={Link} value='history' to={'/licenses/' + licenseId + '/history'} />
                }
                {location.pathname.split('/')[3] === 'upgrade' &&
                  <Tab label={strings.tabLicenseUpgrade} component={Link} value='upgrade' to={'/licenses/' + licenseId + '/upgrade/' + location.pathname.split('/')[location.pathname.split('/').length - 1]} />
                }
              </Tabs>
            )} />
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path='/licenses/:licenseId/details'><LicenseDetail /></Route>
        <Route path='/licenses/:licenseId/printers'><LicensePrinterTable /></Route>
        <Route path='/licenses/:licenseId/history'><LicenseHistoryTable /></Route>
        <Route path='/licenses/:licenseId/upgrade/:updateToken'><LicenseUpgradeScreen /></Route>
      </Switch>
    </Paper>

  );
}

export default connect(null, {})(withStyles(gStyles)(LicenseDetailDashboard));