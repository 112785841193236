import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setLicenseAssignList } from '../utils/Reducer';
import { getUpgradeDataFromToken, processUpgradeFromToken } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Alert, Collapse } from '@mui/material';
import { Typography, Button } from '@mui/material';

let strings = new LocalizedStrings({
  en: {
    title: 'Licenses',
    buttonExternalUpgrade: 'Upgrade External License',
    tableHeaderDistributorName: 'Distributor',
    tableHeaderLicense: 'License Id',
    tableHeaderCustomer: 'Customer',
    tableHeaderType: 'Type',
    tableHeaderSerial: 'Dongle Serial',
    tableHeaderOrderDate: 'Order On',
    tableHeaderCurrentEdition: 'Edition',
    tableHeaderSoftware: 'Software',
    tableHeaderSoftwareVersion: 'Version',
    tableHeaderSoftwareOS: 'OS',
    tableHeaderEnd: 'Expires On ',
    searchPlaceHolder: 'search',

    typeDongle: 'Dongle',
    typeCode: 'Activation Code',
    typePrinter: 'Printer Activation',
    typeTrial: 'Trial',

    licenseSuspended: 'Suspended',
    licenseNotStarted: 'Not started',

    toolTipActivate: 'Activate license',
    toolTipSuspend: 'Suspend license',
    toolTipStart: 'Start license for 1 year',
    toolTipRenew: 'Renew license for 1 more year',
    toolTipUpgrade: 'Upgrade license',
  }
});

function LicenseUpgradeScreen(props) {

  const { classes } = props;
  const { assignMode } = props;
  const { userType } = props;
  const { allowFeatures } = props;
  const [currentLicense, setCurrentLicense] = useState(undefined);
  const [upgradeDataState, setUpgradeDataState] = useState({});
  const [upgradeDataFromToState, setUpgradeDataFromToState] = useState({});
  const [upgradeDataPluginsState, setUpgradeDataPluginsState] = useState([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const [upgradeProcessed, setUpgradeProcessed] = useState(false);
 
  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);

  // const page = !assignMode ? Number( searchParams.get( 'page' ) ) : localPage;
  // var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  // urlRowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  // const rowsPerPage = !assignMode ? urlRowsPerPage : localRowsPerPage;

  // const searchFromURL = searchParams.get( 'search' ) === null ? '' : searchParams.get( 'search' );
  // const [ searchValue, setSearchValue ] = useState( '' );
  // const [ searchTrigger, setSearchTrigger ] = useState( '' );
  // const filterIdFromURL = searchParams.get( 'filter' ) === null ? '-1' : searchParams.get( 'filter' );
  let urlParts = window.location.href.split('/');
  let urlToken = urlParts[urlParts.length - 1];

  useEffect(
    () => {
      (async () => {
        const upgradeData = await getUpgradeDataFromToken(urlToken);
        if (upgradeData === false) {

        } else {
          let requestData = {};
          requestData['Customer name'] = upgradeData.name;
          requestData['Company'] = upgradeData.company;
          requestData['Country'] = upgradeData.country;
          requestData['Zip'] = upgradeData.zip;
          requestData['City'] = upgradeData.state;
          requestData['E-mail'] = upgradeData.email;
          requestData['Phone'] = upgradeData.phone;
          setUpgradeDataState(requestData);

          let requestDataFromTo = {};
          if (upgradeData.upgradeFrom !== undefined && upgradeData.upgradeFrom != '') {
            requestDataFromTo['From'] = upgradeData.upgradeFrom;
            requestDataFromTo['From name'] = upgradeData.upgradeFromName;
          }
          if (upgradeData.upgradeTo !== undefined && upgradeData.upgradeTo != '') {
            requestDataFromTo['To'] = upgradeData.upgradeTo;
            requestDataFromTo['To name'] = upgradeData.upgradeToName;
          }
          setUpgradeDataFromToState(requestDataFromTo);

          if (upgradeData.plugins != undefined) {
            let requestDataPlugins = [];
            var pluginsList = upgradeData.plugins.split(",");
            var pluginsNamesList = upgradeData.pluginsNames.split(",");

            if (pluginsList.length > 0) {

              for (let index = 0; index < pluginsNamesList.length; index++) {
                requestDataPlugins.push(pluginsNamesList[index]);
              }
            }
            setUpgradeDataPluginsState(requestDataPlugins);
          }
        }

      })();
    },
    []
  );

  const handleConfirm = () => {
    (async () => {
      const upgradeResult = await processUpgradeFromToken(urlToken);
      if (upgradeResult.code !== "200") {
        setAlertMessage(upgradeResult.developerMessage); 
        setAlertOpen(true);
        setAlertSeverity("error");
      } else {
        setAlertMessage("Upgrade processed with success"); 
        setAlertOpen(true);
        setAlertSeverity("success"); 
        setUpgradeProcessed(true); 
      }

    })();
  }

  return (
    <div className={classes.workarea}>

      {!upgradeProcessed && Object.keys(upgradeDataState).length > 0 &&
        Object.keys(upgradeDataState).map((keyName, i) => (
          <div key={i}>
            <Typography variant='body1'><b>{keyName}</b>:{upgradeDataState[keyName]}</Typography>
          </div>
        ))
      }

      {!upgradeProcessed && Object.keys(upgradeDataFromToState).length > 0 &&
        Object.keys(upgradeDataFromToState).map((keyName, i) => (
          <div key={i}>
            <Typography variant='body1'><b>{keyName}</b>:{upgradeDataFromToState[keyName]}</Typography>
          </div>
        ))
      }

      {!upgradeProcessed && upgradeDataPluginsState.length > 0 &&
        <div>
          <br></br>
          <Typography variant='body1'><b>{"Plugins to buy"}</b></Typography>
        </div>
      }

      {!upgradeProcessed && upgradeDataPluginsState.map((item, index) => {
        return (
          <div key={index}>
            <li>{item}</li>
          </div>
        )
      })} 

      <Collapse in={alertOpen}>
        <Alert variant="outlined" severity={alertSeverity} style={{ marginTop: 15 }}>
          {alertMessage}
        </Alert>
      </Collapse> 

      {!upgradeProcessed && Object.keys(upgradeDataState).length > 0 &&
        <Button variant="contained" color="secondary" style={{ marginLeft: 0, marginTop: 15 }} className={classes.flexButton} onClick={handleConfirm}>
          {"Confirm upgrade"}
        </Button>
      }



    </div>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    allowFeatures: state.allowFeatures,
    licenseList: state.licenseList,
    licenseAssignList: state.licenseAssignList,
  }
};

export default connect(mapStateToProps, { setLicenseAssignList })(withStyles(gStyles)(LicenseUpgradeScreen));