import React, { useEffect }  from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributor } from './DistributorRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Paper, AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@mui/material';
import  { Close } from '@mui/icons-material';

import DistributorDetail from './DistributorDetail';
import DistributorUserTable from './DistributorUserTable';
import DistributorProductFamilyTable from './DistributorProductFamilyTable';
import DistributorFeatureTable from './DistributorFeatureTable';
import DistributorLicenseTable from './DistributorLicenseTable';
import DistributorSubDistributorTable from './DistributorSubDistributorTable';

let strings=new LocalizedStrings({
	en:{
    tabDetails:'Details',
    tabUsers:'Users',
    tabProductFamily:'Products',
    tabFeatures:'Features',
    tabLicenses:'Licenses',
    tabSubDistributors:'Sub Distributors',
	}
})


function DistributorDetailDashboard( props ) {

  const { classes } = props;

  const { distributorSelected } = props;
  const { distributorId } = useParams();

  const { userType } = props;

  useEffect(
    () => {
      getDistributor( distributorId );
    },
    [ distributorId ]
  );

  return (
      <Paper className={ classes.selectedItemPaper }>
        <Toolbar variant="dense"> 
          <Typography className={ classes.flexExpand } variant='h6'>{ distributorSelected !== undefined ? distributorSelected.name : '' }</Typography>
          <IconButton component={ Link } to='/distributors'>
            <Close />
          </IconButton>
        </Toolbar>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs textColor="inherit" indicatorColor="secondary" value={ location.pathname.split('/')[3] }>
                  <Tab label={ strings.tabDetails } component={ Link } value='details' to={ '/distributors/' + distributorId + '/details' }/>
                  <Tab label={ strings.tabUsers }  component={ Link } value='users' to={ '/distributors/' + distributorId + '/users' }/>
                  <Tab label={ strings.tabProductFamily }  component={ Link } value='products' to={ '/distributors/' + distributorId + '/products' }/>
                  <Tab label={ strings.tabFeatures }  component={ Link } value='features' to={ '/distributors/' + distributorId + '/features' }/>
                  { userType !== 2 && 
                    <Tab label={ strings.tabLicenses }  component={ Link } value='licenses' to={ '/distributors/' + distributorId + '/licenses' }/>
                  }
                  { userType === 0 && 
                    <Tab label={ strings.tabSubDistributors }  component={ Link } value='subDistributors' to={ '/distributors/' + distributorId + '/subDistributors' }/>
                  }
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/distributors/:distributorId/details'><DistributorDetail/></Route>
          <Route path='/distributors/:distributorId/users'><DistributorUserTable/></Route>
          <Route path='/distributors/:distributorId/products'><DistributorProductFamilyTable/></Route>
          <Route path='/distributors/:distributorId/features'><DistributorFeatureTable/></Route>
          <Route path='/distributors/:distributorId/licenses'><DistributorLicenseTable/></Route>
          <Route path='/distributors/:distributorId/subDistributors'><DistributorSubDistributorTable/></Route>
        </Switch>        
      </Paper>

  );
}

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    distributorSelected: state.distributorSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorDetailDashboard ) );