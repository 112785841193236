import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEM, setSettingsOEM, deleteSettingsOEM } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Select, Tooltip, MenuItem } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AddCircle, Edit, Delete } from '@mui/icons-material';


import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings = new LocalizedStrings({
  en: {
    title: 'OEMs',
    titleDialog: 'OEM',

    tableHeaderDistributor: 'Distributor',
    tableHeaderName: 'Name',
    tableHeaderDesignation: 'Designation',
    tableHeaderPrefix: 'Prefix',
    tableHeaderNotes: 'Notes',


    labelDistributor: 'Distributor',
    labelName: 'Name',
    labelDesignation: 'Designation',
    labelPrefix: 'Prefix',
    labelNotes: 'Notes',

    buttonAdd: 'New OEM',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit OEM',
    toolTipDelete: 'Delete OEM',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this OEM?',
    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEM(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);

  const [values, setValues] = useState({});
  const [entityToDelete, setEntityToDelete] = useState(undefined);

  const { settingsOEMTableForceRefresh } = props;
  const { settingsOEMList } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      setCurrentPage(parseInt(page))
      setCurrentRowsPerPage(parseInt(rowsPerPage))
    },
    []
  );

  useEffect(
    () => {
      if (settingsOEMList !== undefined) {
        if (settingsOEMList?.distributors[0]?.distributor_id !== undefined) {
          setValues({ ...values, distributor_id: settingsOEMList.distributors[0].distributor_id });
        }
      }
    },
    [settingsOEMList]
  );

  useEffect(
    () => {
      getSettingsOEM(currentPage * currentRowsPerPage, currentRowsPerPage);
    },
    [page, rowsPerPage, settingsOEMTableForceRefresh, currentPage, currentRowsPerPage]
  );

  // Deprecated - Used when is possible to choose multiple distributor for OEM
  const toogleDistributor = (distributorName) => {

    var index = values.distributor_name.indexOf(distributorName);
    if (index !== -1) {
      let newDistArray = values.distributor_name;
      newDistArray.splice(index, 1);
      setValues({ ...values, distributor_name: newDistArray });
    } else {
      let newValues = values.distributor_name.concat(distributorName)
      setValues({ ...values, distributor_name: newValues });
    }

  }

  const handleChange = name => event => {

    switch (name) {
      case 'distributor': {
        const { options } = event.target;
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            //toogleDistributor(options[i].value);
            setValues({ ...values, distributor_id: options[i].value });

          }
        }
        break;
      }
      case 'name': {
        setValues({ ...values, name: event.target.value });
        break;
      }
      case 'designation': {
        setValues({ ...values, designation: event.target.value });
        break;
      }
      case 'prefix': {
        setValues({ ...values, prefix: event.target.value });
        break;
      }
      case 'notes': {
        setValues({ ...values, notes: event });
        break;
      }

      default:
        break;
    }
  };


  const handleAdd = () => {
    setModalOpen(true)
    setValues({
      oem_id: 0,
      // distributor_ids: [],
      // distributor_name: [],
      distributor_id: (settingsOEMList?.distributors[0]?.distributor_id !== undefined ? settingsOEMList.distributors[0].distributor_id : undefined),
      distributor_name: (settingsOEMList?.distributors[0]?.distributor_name !== undefined ? settingsOEMList.distributors[0].distributor_name : undefined),
      name: '',
      designation: '',
      prefix: '',
      notes: ''
    });
  }

  const handleEdit = oem => {
    setEditMode(true);
    setModalOpen(true)
    setValues({
      oem_id: oem.productfamily_id,
      // distributor_ids: oem.distributor_ids,
      // distributor_name: oem.distributor_name.split(', '),
      distributor_id: oem.distributor_id,
      distributor_name: oem.distributor_name,
      name: oem.name,
      designation: oem.designation,
      prefix: oem.prefix,
      notes: oem.notes
    });
  };

  const handleClose = () => {
    setEditMode(false);
    setModalOpen(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();    
    setSettingsOEM(values);
    setEditMode(false);
    setModalOpen(false)
  };

  const handleDeleteDialogClose = confirm => {
    if (confirm)
      deleteSettingsOEM(entityToDelete);
    setEntityToDelete(undefined);
  }

  const handleDelete = oem => {
    setEntityToDelete(oem);
  };

  return (
    <Box>
      <Paper className={classes.workarea}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAdd()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAdd}</Typography>
          </Button>
        </Box>
        {settingsOEMList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDistributor}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDesignation}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPrefix}</Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMList.items.map((oem, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '20%' }}>{oem.distributor_name}</TableCell>

                    <TableCell style={{ width: '20%' }}>
                      <Link className={classes.link} to={'/settings/' + oem.productfamily_id + '/editions'}>
                        <Typography variant='body2'>{oem.name}</Typography>
                      </Link>
                    </TableCell>

                    <TableCell style={{ width: '20%' }}>{oem.designation}</TableCell>
                    <TableCell style={{ width: '20%' }}>{oem.prefix}</TableCell>
                    <TableCell />
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(oem)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDelete}>
                        <IconButton size='small' onClick={() => handleDelete(oem)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMList.count} rowsPerPage={currentRowsPerPage} page={currentPage}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => { handleChangePage(history, newPage); setCurrentPage(newPage); }}
                    onRowsPerPageChange={(event) => { handleChangeRowsPerPage(history, event, page, currentRowsPerPage); setCurrentRowsPerPage(parseInt(event.target.value)) }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={modalOpen} style={{ zIndex: 0, height: '900px' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              <Grid item xs={12}>

                <FormControl style={{ width: '100%' }}>
                  <InputLabel required>{strings.labelDistributor}</InputLabel>
                  <Select
                    native
                    // labelId="distributor"
                    value={values.distributor_id}
                    onChange={handleChange('distributor')}
                    // input={<OutlinedInput labelwidth={90} label="Distributor" />}
                    label={strings.labelDistributor}
                    // inputProps={{
                    //   id: 'select-multiple-native',
                    // }}
                    required
                    disabled={editMode ? true : false}
                  >
                    {settingsOEMList !== undefined && settingsOEMList.distributors.map((distributor, index) => (
                      <option key={index} value={distributor.distributor_id}>
                        {distributor.distributor_id} - {distributor.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField required fullWidth id='name' autoFocus
                  label={strings.labelName} value={values.name} onChange={handleChange('name')}
                  inputProps={{ maxLength: 64 }} />
              </Grid>


              < Grid item xs={6}>
                <TextField required fullWidth id='designation' autoFocus
                  label={strings.labelDesignation} value={values.designation} onChange={handleChange('designation')}
                  inputProps={{ maxLength: 64 }}
                  disabled={editMode ? true : false}
                />
              </Grid>



              <Grid item xs={6}>
                <TextField required fullWidth id='prefix' autoFocus
                  label={strings.labelPrefix} value={values.prefix} onChange={handleChange('prefix')}
                  inputProps={{ maxLength: 8 }}
                  disabled={editMode ? true : false}
                />
              </Grid>


            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog >

      <Dialog open={entityToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMTableForceRefresh: state.settingsOEMTableForceRefresh,
    settingsOEMList: state.settingsOEMList,
    settingsDistributorsList: state.settingsDistributorsList,

  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEM));