import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setDistributor } from './DistributorRest';
import { formatDateEpoch } from '../utils/Utils';

import { gStyles, fieldLimits  } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Typography, CircularProgress, Box, Grid , TextField, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';


let strings=new LocalizedStrings({
	en:{
    labelName:'Name: ',
    labelEmail:'Email: ',
    labelPhone:'Phone Number: ',
    labelUrl:'URL: ',
    labelCustomers:'# Customers: ',
    labelLicenses:'# Licenses: ',
    labelPrinters:'# Printers: ',
    labelUpdateDate:'Last Update: ',

    buttonEdit:'Edit',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',
	}
})

function DistributorDetail( props ) {

  const { classes } = props;

  const { userType } = props;
  const { distributorSelected } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { distributor_id: 0, valid: false, name: '', email: '', phone: '', url: '' } );

  if ( distributorSelected !== undefined && !values.valid ) {
    setValues( {
      valid:true,
      distributor_id: distributorSelected.distributor_id,
      name: distributorSelected.name, 
      email: distributorSelected.email,
      phone: distributorSelected.phone,
      url: distributorSelected.url
    } );
  }

  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };
  const handleEdit = () => {
    setEditMode( true );
  };
  const handleCancel = () => {
    setEditMode( false );
    setValues( {...values,
      name: distributorSelected.name,
      email: distributorSelected.email,
      phone: distributorSelected.phone,
      url: distributorSelected.url,
     } );
  };
  const handleConfirm = ( event ) => {
    event.preventDefault();
    setDistributor( values );
    setEditMode( false );
  };

  let history = useHistory();

  return (
    <Box className={ classes.workarea }>
      { distributorSelected !== undefined &&
        <Box>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField fullWidth variant='outlined' required id='name' disabled={ !editMode }
                  label={ strings.labelName } value={ values.name } onChange={ handleChange('name') }
                  inputProps={ { maxLength: fieldLimits.distributor.name.size } }/>
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth variant='outlined' id='phone' disabled={ !editMode }
                  label={ strings.labelPhone } value={ values.phone } onChange={ handleChange('phone') }
                  inputProps={ { maxLength: fieldLimits.distributor.phone.size } }/>
              </Grid> 
              <Grid item xs={6}>
                <TextField fullWidth variant='outlined' required id='email' disabled={ !editMode }
                  label={ strings.labelEmail } value={ values.email } onChange={ handleChange('email') }
                  inputProps={ { maxLength: fieldLimits.distributor.email.size } }/>
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth variant='outlined' id='url' disabled={ !editMode }
                  label={ strings.labelUrl } value={ values.url } onChange={ handleChange('url') }
                  inputProps={ { maxLength: fieldLimits.distributor.url.size } }/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{ strings.labelCustomers }
                  { distributorSelected.customers !== null &&
                    <b className={ classes.link } onClick={ () => { history.push('/customers?search=' + distributorSelected.name ); } }>
                      { distributorSelected.customers }
                    </b>
                  }
                  { distributorSelected.customers === null && <b>0</b> }
                </Typography>
                <Typography variant='body1'>{ strings.labelLicenses }
                  { distributorSelected.licenses !== null &&
                    <b className={ classes.link } onClick={ () => { history.push('/licenses?search=' + distributorSelected.name ); } }>
                      { distributorSelected.licenses }
                    </b>
                  }
                  { distributorSelected.licenses === null && <b>0</b> }
                </Typography>
                <Typography variant='body1'>{ strings.labelPrinters }
                  { distributorSelected.printers !== null &&
                    <b className={ classes.link } onClick={ () => { history.push('/printers?search=' + distributorSelected.name ); } }>
                      { distributorSelected.printers }
                    </b>
                  }
                  { distributorSelected.printers === null && <b>0</b> }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{ strings.labelUpdateDate }<b>{ formatDateEpoch( distributorSelected.update_date ) }</b></Typography>
              </Grid>
              <Grid item xs={12}>
                <Box className={ classes.lineButtonBox }>
                  { !editMode && userType === 1 &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } onClick={ handleEdit }>
                      <Edit className={ classes.leftIcon }/>
                      { strings.buttonEdit }
                    </Button>
                  }
                  { editMode &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } type='submit'>
                      { strings.buttonConfirm }
                    </Button>
                  }
                  { editMode &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } onClick={ handleCancel }>
                      { strings.buttonCancel }
                    </Button>
                  }
                </Box>
              </Grid> 
            </Grid>            
          </form>
        </Box>
      }
      { distributorSelected === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    distributorSelected: state.distributorSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorDetail ) );