import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMWhatsnew, setSettingsOEMWhatsnew, deleteSettingsWhatsnew } from './SettingsRest';
import { formatDateEpoch } from '../utils/Utils';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles, styled } from '@mui/styles';
import { Alert, Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Select, Tooltip, Checkbox, TextField, tooltipClasses } from '@mui/material';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers'

import { AddCircle, Edit, Delete, MoreHoriz } from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

// import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';
import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';

let strings = new LocalizedStrings({
  en: {
    title: 'What\'s New',
    titleDialog: 'What\'s New',

    tableHeaderActive: 'Active',
    tableHeaderTitle: 'Title',
    tableHeaderPicture: 'Picture',
    tableHeaderLink: 'Link',
    tableHeaderCategory: 'Category',
    tableHeaderMsg: 'Message',
    tableHeaderStart: 'Starts',
    tableHeaderEnd: 'Expires',

    templatesLabel: 'Templates',
    softwareLabel: 'Software',
    newsLabel: 'News',
    otherLabel: 'Other',

    neverExpiresLabel: '<never Expires>',

    labelTitle: 'Title',
    labelPicture: 'Picture',
    labelLink: 'Link',
    labelCategory: 'Category',
    labelValue: 'Value',
    labelMsg: 'Message',
    labelPersistent: 'Persistent',
    labelNeverExpires: 'Never expires',
    labelStartDate: 'Start Date',
    labelEndDate: 'End Date',

    buttonAdd: 'Add What\'s New',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit the What\'s New',
    toolTipDelete: 'Delete the What\'s New',
    toolTipEditPicture: 'Click the picture to edit',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this What\'s New?',
    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMWhatsNew(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});
  const [entityToDelete, setEntityToDelete] = useState(undefined);
  const [state, setState] = useState({ hover: false });
  const [errorMessage, setErrorMessage] = useState(null);

  const { oemId } = useParams();

  const { settingsWhatsnewTableForceRefresh } = props;
  const { settingsWhatsnewList } = props;

  const fileUploader = useRef(null);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      getSettingsOEMWhatsnew(oemId, page * rowsPerPage, rowsPerPage);
    },
    [page, rowsPerPage, settingsWhatsnewTableForceRefresh, oemId]
  );

  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch (name) {
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }
      case 'msg': {
        setValues({ ...values, msg: event.target.value });
        break;
      }
      case 'category': {
        setValues({ ...values, category: Number(event.target.value), value: 0 });
        break;
      }
      case 'persistent': {
        setValues({ ...values, persistent: Number(!values.persistent) });
        break;
      }
      case 'start_date': {
        setValues({ ...values, start_date: event.getTime() / 1000 });
        break;
      }
      case 'end_date': {
        setValues({ ...values, end_date: event.getTime() / 1000 });
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleAdd = () => {
    setEditMode(true);
    var now = new Date().getTime() / 1000;
    setValues({
      notification_id: 0,
      oem_id: oemId,
      active: 1,
      title: '',
      picture: '',
      link: '',
      category: 901,
      msg: '',
      persistent: 1,
      start_date: now,
      end_date: now + 2592000
    }); //30 days
  }

  const handleActive = (whatsnew) => {    
    setSettingsOEMWhatsnew({ ...whatsnew, active: !whatsnew.active });
  }

  const handleEdit = whatsnew => {
    setEditMode(true);   
    setValues({
      notification_id: whatsnew.notification_id,
      oem_id: oemId,
      active: whatsnew.active,
      title: whatsnew.title,
      picture: whatsnew.picture,
      link: whatsnew.link,
      category: whatsnew.category,
      msg: whatsnew.msg,
      persistent: whatsnew.persistent,
      start_date: whatsnew.start_date,
      end_date: whatsnew.end_date
    });
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    if (values.picture === '') {
      setErrorMessage("Field image is required")
    } else { 
      setErrorMessage(null)
      setEditMode(false);      
      setSettingsOEMWhatsnew(values);
    }

  };

  const handleDeleteDialogClose = confirm => {
    if (confirm)
      deleteSettingsWhatsnew(entityToDelete);
    setEntityToDelete(undefined);
  }

  const handleDelete = whatsnew => {
    setEntityToDelete(whatsnew);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAdd()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAdd}</Typography>
          </Button>
        </Box>
        {settingsWhatsnewList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderActive}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderTitle}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderCategory}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPicture}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderMsg}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderStart}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderEnd}</Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsWhatsnewList.items.map((whatsnew) => (
                  <TableRow key={whatsnew.notification_id}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={whatsnew.active === 1} onClick={() => handleActive(whatsnew)} />
                    </TableCell>
                    <TableCell>{whatsnew.title}</TableCell>
                    <TableCell>
                      {whatsnew.category === 901 &&
                        <Typography variant='body2'>{strings.templatesLabel}</Typography>
                      }
                      {whatsnew.category === 902 &&
                        <Typography variant='body2'>{strings.softwareLabel}</Typography>
                      }
                      {whatsnew.category === 903 &&
                        <Typography variant='body2'>{strings.newsLabel}</Typography>
                      }
                      {whatsnew.category === 904 &&
                        <Typography variant='body2'>{strings.otherLabel}</Typography>
                      }
                    </TableCell>
                    <TableCell style={{ width: '50px' }}>
                      <Tooltip title={state.hover ? (<img src={(whatsnew.picture !== '' ? whatsnew.picture : backgroundImage)} height={100} width={'100%'} alt="" />) : ''} alt="">
                        <Icon style={{ width: 'auto', height: '48px' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(whatsnew.picture !== '' ? whatsnew.picture : backgroundImage)} height="48" alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: '20%' }} href={whatsnew.link}> {whatsnew.link} </TableCell>
                    <TableCell>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: whatsnew.msg.replaceAll("\n", "<br/>").replaceAll("\r", "<br/>") }} />
                          </React.Fragment>
                        }
                      >
                        <Button><MoreHoriz /></Button>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{whatsnew.persistent !== 1 ? formatDateEpoch(whatsnew.start_date) : ""}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{whatsnew.persistent !== 1 ? formatDateEpoch(whatsnew.end_date) : strings.neverExpiresLabel}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(whatsnew)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDelete}>
                        <IconButton size='small' onClick={() => handleDelete(whatsnew)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={10} count={settingsWhatsnewList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsWhatsnewList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ height: '100%' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.titleDialog}</Typography>

          <form className={classes.form} autoComplete="off"
            onSubmit={(event) => handleConfirm(event)}
          >

            <Grid container spacing={1}>

              {errorMessage !== null &&
                <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              }

              <Grid item xs={7}>
                <FormControl fullWidth >
                  <InputLabel id="category-label">{strings.labelCategory}</InputLabel>
                  <Select
                    native
                    labelId="category-label"
                    id="category-select"
                    value={values.category}
                    onChange={handleChange('category')}
                    label={strings.labelCategory}
                    required
                  >
                    <option value={901}>{strings.templatesLabel}</option>
                    <option value={902}>{strings.softwareLabel}</option>
                    <option value={903}>{strings.newsLabel}</option>
                    <option value={904}>{strings.otherLabel}</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth >
                  <InputLabel variant='outlined'>{strings.labelPersistent}</InputLabel>
                  <OutlinedInput
                    labelwidth={75}
                    readOnly
                    label={strings.labelPersistent}
                    startAdornment={
                      <InputAdornment position="start">
                        <Checkbox
                          checked={values.persistent === 1}
                          onChange={handleChange('persistent')} />
                        <Typography>{strings.labelNeverExpires}</Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
              </Grid>

              <Grid item xs={6}>
                <DateTimePicker

                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label={strings.labelStartDate}
                  showTodayButton
                  ampm={false}
                  required={values.persistent === 1}
                  disabled={values.persistent === 1}
                  fullWidth
                  inputVariant='outlined'
                  value={formatDateEpoch(values.start_date)}
                  onChange={handleChange('start_date')}
                  inputFormat='yyyy/MM/dd HH:mm' />
              </Grid>

              <Grid item xs={6}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label={strings.labelEndDate}
                  showTodayButton
                  ampm={false}
                  required={values.persistent === 1}
                  disabled={values.persistent === 1}
                  fullWidth
                  inputVariant='outlined'
                  value={formatDateEpoch(values.end_date)}
                  onChange={handleChange('end_date')}
                  inputFormat='yyyy/MM/dd HH:mm' />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='title'
                  label={strings.labelTitle} value={values.title} onChange={handleChange('title')} required />
              </Grid>

              {values.picture === '' &&
                <Grid item xs={6}>
                  <FormControl variant='outlined' required>
                    <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                    <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                  </FormControl>
                </Grid>
              }

              {values.picture !== '' &&
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={11}>
                      <FormControl variant='outlined' required onClick={handleEditPicClick}>
                        <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                        <img src={values.picture} width={'100%'} alt="" />
                        <input type='file' accept='.png' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton style={{ marginLeft: '-200px', marginTop: '-5px' }} size='small' onClick={() => handleEditPicClick()} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              }

              <Grid item xs={6}>
                <FormControl variant='outlined' required>
                  <FormLabel><Typography variant='caption'>{strings.labelMsg}</Typography></FormLabel>
                  <Box border={1} backgroundColor={'#fff'} height={'160px'} width={'240px'} overflow={'auto'}>
                    <TextField style={{ height: '100%' }} multiline fullWidth
                      // helperText={"Type the message above the line"} 
                      type='msg' variant='standard' margin='none' value={values.msg} onChange={handleChange('msg')} required />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Dialog open={entityToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsWhatsnewTableForceRefresh: state.settingsWhatsnewTableForceRefresh,
    settingsWhatsnewList: state.settingsWhatsnewList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMWhatsNew));