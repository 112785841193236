import React, { useEffect }  from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getLicensePrinters } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    tableHeaderSerialNumber:'Serial Number',
    tableHeaderManufacturer:'Manufacturer',
    tableHeaderModel:'Model',
	}
});

function LicensePrinterTable( props ) { 

  const { classes } = props;

  const { licensePrinterList } = props;
  const { licenseId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 10 : urlRowsPerPage;

  useEffect(
    ( ) => { 
      getLicensePrinters( licenseId, page * rowsPerPage, rowsPerPage );
   },
   [ licenseId, page, rowsPerPage ]
  );

  return (
    <div className={ classes.workarea }>
      <div className={ classes.tableArea } >
        { licensePrinterList !== undefined &&
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderSerialNumber }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderManufacturer }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderModel }</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { licensePrinterList.items.map( printer => (
                <TableRow key={ printer.serial_number }>
                  <TableCell>
                    <Link className={ classes.link } to={ '/printers/' + printer.serial_number + '/details' }>
                      <Typography variant='body2'>{ printer.serial_number }</Typography>
                    </Link>  
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ printer.manufacturer }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ printer.model }</Typography>
                  </TableCell>
                </TableRow>
              )) }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 3 } count={ licensePrinterList.count } rowsPerPage={ rowsPerPage } page={ page }
                  SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                  onPageChange={ ( event, newPage ) => handleChangePage( history, newPage )  }
                  onRowsPerPageChange={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                  ActionsComponent={ TablePaginationActions }
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        { licensePrinterList === undefined &&
            <CircularProgress className={ classes.spinner }/>
        }
      </div>
    </div>
  );
 }

const mapStateToProps = state => { 
  return { 
    licensePrinterList: state.licensePrinterList,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( LicensePrinterTable ) );