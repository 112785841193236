import { store } from '../index';
import { setDistributorList, setDistributorSelected, setDistributorUserList, refreshDistributorUserTable } from '../utils/Reducer';
import { refreshDistributorTable, setDistributorSubDistributorList, setDistributorLicenseList, refreshDistributorLicenseTable } from '../utils/Reducer';
import { setDistributorProductFamilyList, refreshDistributorProductFamilyTable } from '../utils/Reducer';
import { setDistributorFeatureList } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributors
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributors( from, limit, search ) {

  const onError = error => {
    gError( 'getDistributors', error ); 
    store.dispatch( setDistributorList( { count:0, items:[] } ) );
  }

  store.dispatch( setDistributorList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ), {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// syncDistributors
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function syncDistributors( ) {

  const onError = error => {
    gError( 'syncDistributors', error ); 
    store.dispatch( setDistributorList( { count:0, items:[] } ) );
  }

  store.dispatch( setDistributorList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/sync', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken 
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributor( distributorId ) {

  const onError = error => {
    gError( 'getDistributor', error ); 
    store.dispatch( setDistributorSelected( undefined ) );
  }

  store.dispatch( setDistributorSelected( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorSelected( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createDistributor( distributor ) {

  const onError = error => {
    gError( 'createDistributor', error ); 
    store.dispatch( setDistributorSelected( undefined ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/create', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( distributor )
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( refreshDistributorTable ( ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteDistributor( distributorId ) {

  const onError = error => {
    gError( 'deleteDistributor', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/delete', {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setDistributor( distributor ) {

  const onError = error => {
    gError( 'setDistributor', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributor.distributor_id, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( distributor )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( setDistributorSelected( getDistributor( distributor.distributor_id ) ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorUsers
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorUsers( distributorId, from, limit ) {

  const onError = error => {
    gError( 'getDistributorUsers', error );
    store.dispatch( setDistributorUserList( { count:0, items:[] } ) );
  }

  store.dispatch( setDistributorUserList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/users?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorUserList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createDistributorUser
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createDistributorUser( distributorId, user ) {

  const onError = error => {
    gError( 'createDistributorUser', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/users', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( user )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorUserTable( ) );
      return; 
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setDistributorUser
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setDistributorUser( distributorId, user ) {

  const onError = error => {
    gError( 'setDistributorUser', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/users/' + user.id, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( user )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorUserTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteDistributorUser
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteDistributorUser( distributorId, user ) {

  const onError = error => {
    gError( 'deleteDistributorUser', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/users/' + user.id, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorUserTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorProductFamilies
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorProductFamilies( distributorId, from, limit ) {

  const onError = error => {
    gError( 'getDistributorProductFamilies', error );
    store.dispatch( setDistributorProductFamilyList( { count:0, items:[] } ) );
  }

  store.dispatch( setDistributorProductFamilyList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/productfamilies?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorProductFamilyList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// addDistributorProductFamilies
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function addDistributorProductFamilies( distributorId, assingList ) {

  const onError = error => {
    gError( 'addDistributorProductFamilies', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/productfamilies', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( assingList )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorProductFamilyTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteDistributorProductFamily
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteDistributorProductFamily( distributorId, productFamily ) {

  const onError = error => {
    gError( 'deleteDistributorProductFamily', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/productfamilies/' + productFamily.productfamily_id, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorProductFamilyTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorFeatures
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorFeatures( distributorId ) {

  const onError = error => {
    gError( 'getDistributorFeatures', error ); 
    store.dispatch( setDistributorFeatureList( undefined ) );
  }

  store.dispatch( setDistributorFeatureList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/features', {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorFeatureList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setDistributorFeatures
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setDistributorFeatures( distributorId, features ) {

  const onError = error => {
    gError( 'setDistributorFeatures', error ); 
    store.dispatch( setDistributorFeatureList( undefined ) );
  }

  store.dispatch( setDistributorFeatureList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/features?features=' + features, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken 
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( setDistributorFeatureList( { distributor_id: distributorId, features: features } ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorSubDistributors
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorSubDistributors( distributorId,  from, limit, search ) {

  const onError = error => {
    gError( 'getDistributorSubDistributors', error ); 
    store.dispatch( setDistributorSubDistributorList( { count:0, items:[] } ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/subDistributors?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ), {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorSubDistributorList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorLicenses
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorLicenses( distributorId, from, limit, search ) {

  const onError = error => {
    gError( 'getDistributorLicenses', error );
    store.dispatch( setDistributorLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setDistributorLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/licenses?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ), {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setDistributorLicenseList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// addDistributorLicenses
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function addDistributorLicenses( distributorId, assingList ) {

  const onError = error => {
    gError( 'addDistributorLicenses', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/licenses', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( assingList )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorLicenseTable( ) );
      store.dispatch( setDistributorSelected( getDistributor( distributorId ) ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error =>  onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteDistributorLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteDistributorLicense( distributorId, license ) {

  const onError = error => {
    gError( 'deleteDistributorLicense', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/distributors/' + distributorId + '/licenses/' + license.license, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken 
    },
  } ) 
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshDistributorLicenseTable( ) );
      store.dispatch( setDistributorSelected( getDistributor( distributorId ) ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

