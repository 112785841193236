import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMEditions, setSettingsOEMProducts, deleteSettingsOEMEdition } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles, styled } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Tooltip, tooltipClasses } from '@mui/material';
import { FormControl, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit, Delete, AddCircle, MoreHoriz } from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';
import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';
import DropzonePic from '../components/DropzonePic';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings = new LocalizedStrings({
  en: {
    title: 'OEM Editions',
    titleDialog: 'Edit Edition',
    titleItemDialog: 'New Edition',

    tableHeaderCode: 'Product Code',
    tableHeaderFile: 'Product File',
    tableHeaderPublic: 'Product Public',
    tableHeaderDescription: 'Product Description',
    tableHeaderLink: 'Link',
    tableHeaderPic: 'Picture',


    labelCode: 'Product Code',
    labelFile: 'Product File',
    labelPublic: 'Product Public',
    labelDescription: 'Description',
    labelPrefix: 'Prefix',
    labelNotes: 'Notes',
    labelLink: 'Link',
    labelPic: 'Picture',

    buttonAdd: 'Add Edition',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit Edition',
    toolTipDelete: 'Delete Edition',
    toolTipEditPicture: 'Click the picture to edit',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this edition?',
    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMDetailEditions(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});
  const [entityToDelete, setEntityToDelete] = useState(undefined);
  const [state, setState] = useState({ hover: false });
  const [newItem, setNewItem] = useState(false);

  const { settingsOEMEditionsTableForceRefresh } = props;
  const { settingsOEMEditionsList } = props;
  const { oemId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    () => {
      getSettingsOEMEditions(page * rowsPerPage, rowsPerPage, oemId);
    },
    [page, rowsPerPage, settingsOEMEditionsTableForceRefresh, oemId]
  );

  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch (name) {
      case 'code': {
        setValues({ ...values, code: event.target.value });
        break;
      }
      case 'file': {
        setValues({ ...values, file: event.target.value });
        break;
      }
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'description': {
        setValues({ ...values, description: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleAdd = () => {
    setEditMode(true);
    setNewItem(true);
    setValues({
      oem_id: oemId,
      oldCode: '',
      code: '',
      file: '',
      title: '',
      description: '',
      link: '',
      picture: '',
    });
  }

  const handleEdit = edition => {
    setEditMode(true);
    setNewItem(false);
    setValues({
      oem_id: oemId,
      oldCode: edition.product_code,
      code: edition.product_code,
      file: edition.product_file,
      title: edition.product_public,
      description: edition.product_description,
      picture: (edition.product_picture === null || edition.product_picture.includes('data:image/png;base64') === false ) ? '' : edition.product_picture,
      link: edition.product_link,
    });
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setEditMode(false);
    setSettingsOEMProducts(values);
  };

  const handleDeleteDialogClose = confirm => {
    if (confirm) {
      deleteSettingsOEMEdition(entityToDelete);
      setEntityToDelete(undefined);
    } else {
      setEntityToDelete(undefined);
    }
  }

  const handleDelete = edition => {
    setEntityToDelete(edition);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAdd()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAdd}</Typography>
          </Button>
        </Box>
        {settingsOEMEditionsList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderCode}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderFile}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPublic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMEditionsList.items.map((edition, index) => (
                  // <TableRow key={edition.editionId}>
                  <TableRow key={index}>
                    <TableCell >{edition.product_code}</TableCell>
                    <TableCell >
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: edition.product_file.replaceAll("\n", "<br/>").replaceAll("\r", "<br/>") }} />
                          </React.Fragment>
                        }
                      >
                        <Button><MoreHoriz /></Button>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell >{edition.product_public}</TableCell>
                    <TableCell >{edition.product_description}</TableCell>
                    <TableCell style={{ maxWidth: '48px', width: '48px' }}>
                      <Tooltip
                        title={state.hover ? (<img src={(edition.product_picture !== undefined && edition.product_picture !== null && edition.product_picture.includes('data:image/png;base64') ? edition.product_picture : backgroundImage)} alt="" style={{ maxWidth: '200px', width: '200px' }} />) : ''} alt=""
                      >
                        <Icon style={{ width: '100%', height: 'auto' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(edition.product_picture !== undefined && edition.product_picture !== null && edition.product_picture.includes('data:image/png;base64') ? edition.product_picture : backgroundImage)} width="100%" height="auto" alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell >{edition.product_link}</TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(edition)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDelete}>
                        <IconButton size='small' onClick={() => handleDelete(edition)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMEditionsList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMEditionsList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ height: '100%' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{newItem ? strings.titleItemDialog : strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              {values.picture === '' &&
                <Grid item xs={4}>
                  <FormControl variant='outlined'>
                    <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                  </FormControl>
                </Grid>
              }

              {values.picture !== '' && 
                <Grid item xs={4}>
                  <FormControl variant='outlined' onClick={handleEditPicClick}>
                    <img src={values.picture} style={{ width: '100%', height: 'auto' }} alt="" />
                    <input type='file' accept='.png' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                    <FormControl><Typography variant='body1' style={{ fontStyle: "italic", fontSize: "13px" }}>{strings.toolTipEditPicture}</Typography></FormControl>
                  </FormControl>
                </Grid>
              }

              <Grid item xs={8}>

                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='code'
                      label={strings.labelCode} value={values.code} onChange={handleChange('code')} required />
                  </Grid>


                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='title'
                      label={strings.labelPublic} value={values.title} onChange={handleChange('title')} required />
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="filled-textarea"
                  label={strings.labelFile}
                  placeholder={strings.labelFile}
                  multiline
                  variant="filled"
                  value={values.file}
                  onChange={handleChange('file')}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description' multiline maxRows={4} inputProps={{ maxLength: 255 }}
                  label={strings.labelDescription} value={values.description} onChange={handleChange('description')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Dialog open={entityToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditionsTableForceRefresh: state.settingsOEMEditionsTableForceRefresh,
    settingsOEMEditionsList: state.settingsOEMEditionsList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMDetailEditions));