import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { toogleLicense, startLicense, renewLicense, upgradeLicense } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { FormControl, InputLabel, Select } from '@mui/material';

let strings = new LocalizedStrings({
  en: {
    activateDialogTitle: 'Activate Confirmation',
    activateDialogText: 'Are you sure that you want to activate this license?',
    suspendDialogTitle: 'Suspend Confirmation',
    suspendDialogText: 'Are you sure that you want to suspend this license?',
    startDialogTitle: 'Start Confirmation',
    startDialogText: 'Are you sure that you want to start this license for 1 year? You may also upgrade this license, select an edition to do so.',
    renewDialogTitle: 'Renew Confirmation',
    renewDialogText: 'Are you sure that you want to renew this license for 1 more year? You may also upgrade this license, select an edition to do so.',
    upgradeDialogTitle: 'Upgrade Confirmation',
    upgradeDialogText: 'Please select to which edition should this license be upgraded and confirm!',
    labelUpgrade: 'Edition',

    confirm: 'Confirm',
    cancel: 'Cancel',
  }
});

function LicenseStateDialog(props) {

  const { classes } = props;

  const { open, dialogType, currentLicense, onClose } = props;

  const [upgradeTo, setUpgradeTo] = useState('');
  const [upgradeError, setUpgradeError] = useState(false);

  const titleByType = () => {
    switch (dialogType) {
      case 'activate': return strings.activateDialogTitle;
      case 'suspend': return strings.suspendDialogTitle;
      case 'start': return strings.startDialogTitle;
      case 'renew': return strings.renewDialogTitle;
      case 'upgrade': return strings.upgradeDialogTitle;
      default: return '';
    }
  }

  const textByType = () => {
    switch (dialogType) {
      case 'activate': return strings.activateDialogText;
      case 'suspend': return strings.suspendDialogText;
      case 'start': return strings.startDialogText;
      case 'renew': return strings.renewDialogText;
      case 'upgrade': return strings.upgradeDialogText;
      default: return '';
    }
  }

  const handleDialogClose = confirm => {
    if (confirm) {
      switch (dialogType) {
        case 'activate': toogleLicense(currentLicense); break;
        case 'suspend': toogleLicense(currentLicense); break;
        case 'start': startLicense(currentLicense, upgradeTo); break;
        case 'renew': renewLicense(currentLicense, upgradeTo); break;
        case 'upgrade':
          if (upgradeTo === '') {
            setUpgradeError(true);
            return;
          }
          upgradeLicense(currentLicense, upgradeTo);
          break;
        default: break;
      }
    }
    if (onClose) onClose();
    setUpgradeTo('');
  }

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.title}>{titleByType()}</DialogTitle>
      <DialogContent>
        <DialogContentText>{textByType()}</DialogContentText>
        {(dialogType === 'upgrade' || dialogType === 'renew' || dialogType === 'start') &&
          <FormControl style={{ display: 'flex', margin: '0px auto', width: '340px', marginTop: 15 }} error={upgradeError}>
            <InputLabel id="upgrade-label">{strings.labelUpgrade}</InputLabel>
            <Select
              native
              labelId="upgrade-label"
              id="upgrade-select"
              label={strings.labelUpgrade}
              value={upgradeTo}
              onChange={event => setUpgradeTo(event.target.value)}
            >
              <option value='' />
              {currentLicense !== undefined && currentLicense.upgradePath.map((path, index) => <option key={index} value={path.pcode}>{path.description}</option>)}
            </Select>
          </FormControl>
        }
      </DialogContent>
      <DialogActions className={classes.lineButtonBox}>
        <Button className={classes.lineButton} onClick={() => handleDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        <Button className={classes.lineButton} onClick={() => handleDialogClose(false)} variant='contained' color='secondary'>{strings.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(null, {})(withStyles(gStyles)(LicenseStateDialog));