import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { useTheme } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export function handleChangePage(history, newPage) {
  var searchParams = new URLSearchParams(history.location.search);

  if (newPage !== 0)
    searchParams.set('page', newPage);
  else
    searchParams.delete('page');

  history.push({ search: searchParams.toString() });
}

export function handleChangeRowsPerPage(history, event, oldPage, oldRowsPerPage) {
  var searchParams = new URLSearchParams(history.location.search);

  var newRowsPerPage = Number(event.target.value);
  searchParams.set('rows', newRowsPerPage);

  //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
  var newPage = Math.trunc(oldPage * (oldRowsPerPage / newRowsPerPage));
  if (newPage !== 0) {
    searchParams.set('page', newPage);
  } else {
    searchParams.delete('page');
  }
  history.push({ search: searchParams.toString() });
}

function TablePaginationActions(props) {

  const { classes } = props;

  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.pagination}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='First Page'>
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='Previous Page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='Next Page'>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='Last Page'>
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default connect(null, {})(withStyles(gStyles)(TablePaginationActions));