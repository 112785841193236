import React, { useState } from 'react';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { CloudUpload } from '@mui/icons-material';

function Dropzone( props ) { 

  const { classes } = props;

  const [ hightlight, setHightlight ] = useState( false );
  const { disabled } = props; 

  var fileInputRef = React.createRef();

  const openFileDialog= () => {
    if  ( disabled ) return;
    fileInputRef.current.click();
  }

  const onFileAdded = ( event ) => {
    if  ( disabled ) return;
    if  ( props.onFileAdded && event.target.files.length >= 1 ) {
      props.onFileAdded( event.target.files[0] );
    }
  }

  const onDragOver = ( event ) => {
    event.preventDefault();
    if ( disabled ) return;
    setHightlight( true );
  }

  const onDragLeave = () => {
    setHightlight( false );
  }

  const onDrop = ( event ) => {
    event.preventDefault();
    if ( disabled ) return;
    if ( props.onFileAdded && event.dataTransfer.files.length >= 1 ) {
      props.onFileAdded( event.dataTransfer.files[0] );
    }
    setHightlight( false );
  }

  return (
    <div className={`${ classes.dropZone } ${ hightlight ? classes.dropZoneHighlight : undefined }`}
      onDragOver={ ( event ) => onDragOver( event ) }
      onDragLeave={ ( ) => onDragLeave( ) }
      onDrop={ ( event ) => onDrop( event ) }
      onClick={ ( ) => openFileDialog( )}
      style={ { cursor: disabled ? 'default' : 'pointer' } }
    >
      <input ref={ fileInputRef } style={ { display: 'none' } } type='file' accept='.csv' onChange={ ( event ) => onFileAdded( event ) } />
      <CloudUpload alt='' className={ classes.dropZoneIcon }/>
    </div>
  );
 }


 export default connect( null, { } )( withStyles( gStyles )( Dropzone ) );