import React, { useEffect, useState }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getCustomerContacts, setCustomerDefaultContact, setCustomerContact, deleteCustomerContact } from './CustomerRest';
import { formatName } from '../utils/Utils';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { Typography, CircularProgress, TextField, Button, Tooltip, IconButton, Grid, Box } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit, Delete, RadioButtonChecked, RadioButtonUnchecked, AddCircle } from '@mui/icons-material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    tableHeaderEmail:'Email',
    tableHeaderName:'Name',
    tableHeaderPhone:'Phone Number',

    toolTipEdit:'Edit the contact',
    toolTipDelete:'Delete the contact',
    toolTipSetDefault:'Set as default contact',

    titleContact:'Contact',
    labelEmail:'Email',
    labelFirstName:'First Name',
    labelLastName:'Last Name',
    labelPhone:'Phone Number',

    buttonAdd:'Add new contact',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete contact \'{entity}\'?',
    yes: 'Yes',
    no: 'No',
	}
});

function CustomerContactTable( props ) { 

  const { classes } = props;


  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { userType } = props;
  const { customerContactList } = props;
  const { customerContactTableForceRefresh } = props;
  const { customerId } = useParams();

  const { customerSelected } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 10 : urlRowsPerPage;

  useEffect(
    ( ) => { 
      getCustomerContacts( customerId, page * rowsPerPage, rowsPerPage );
   },
   [ customerId, page, rowsPerPage, customerContactTableForceRefresh ]
  );

  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const onSetDefault = contact => {
    if ( contact.is_default === 0 ) {
      setCustomerDefaultContact( customerId, contact );
    }
  };

  const handleAdd = () => {
    setEditMode( true );
    setValues( { contact_id: 0, email: '', first_name: '', last_name: '', phone: '', is_default: 0 } );
  };

  const handleEdit = contact => {
    setEditMode( true );
    setValues( { contact_id: contact.contact_id, email: contact.email, first_name: contact.first_name, last_name: contact.last_name, phone: contact.phone, is_default: contact.is_default } );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setCustomerContact( customerId, values );
    setEditMode( false );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteCustomerContact( customerId, entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = contact => {
    setEntityToDelete( contact );
  };

  return (
    <div>
      <Box>
        { customerSelected !== undefined &&
          <div className={ classes.workarea }>
            <div className={ classes.flexHeader }>
              { userType !== 0 && customerSelected.is_direct_customer === 1 && 
                <Button variant='contained' color='secondary' onClick={ ( ) => handleAdd( ) }>
                  <AddCircle className={ classes.leftIcon } />
                  { strings.buttonAdd }
                </Button>
              }
            </div>
            <div className={ classes.tableArea } >
              { customerContactList !== undefined &&
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      { userType !== 0 && <TableCell/> }
                      <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderEmail }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderPhone }</Typography>
                      </TableCell>
                      { userType !== 0 && customerSelected.is_direct_customer === 1 && <TableCell/> }
                      { userType !== 0 && customerSelected.is_direct_customer === 1 && <TableCell/> }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { customerContactList.items.map( contact => (
                      <TableRow key={ contact.contact_id }>
                        { userType !== 0 && 
                          <TableCell className={ classes.tableButton } >
                            <Tooltip title={ strings.toolTipSetDefault } >
                              <div>
                                <IconButton size='small' onClick={ ( ) => onSetDefault( contact ) } >
                                  { contact.is_default === 1 ? <RadioButtonChecked/> : <RadioButtonUnchecked/> }
                                </IconButton>
                              </div>
                            </Tooltip>
                          </TableCell>
                        }
                        <TableCell>
                          <Typography variant='body2'>{ contact.email }</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='body2'>{ formatName( contact.first_name, contact.last_name ) }</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='body2'>{ contact.phone }</Typography>
                        </TableCell>
                        { userType !== 0 && customerSelected.is_direct_customer === 1 && 
                          <TableCell className={ classes.tableButton } >
                            <Tooltip title={ strings.toolTipEdit }>
                              <IconButton size='small' onClick={ ( ) => handleEdit( contact ) } >
                                <Edit/>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        }
                        { userType !== 0 && customerSelected.is_direct_customer === 1 && 
                          <TableCell className={ classes.tableButton } >
                            <Tooltip title={ strings.toolTipDelete }>
                              <IconButton size='small' onClick={ ( ) => handleDelete( contact ) } >
                                <Delete/>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        }
                      </TableRow>
                    )) }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 6 } count={ customerContactList.count } rowsPerPage={ rowsPerPage } page={ page }
                        SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                        onPageChange={ ( event, newPage ) => handleChangePage( history, newPage )  }
                        onRowsPerPageChange={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                        ActionsComponent={ TablePaginationActions }
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              }
              { customerContactList === undefined &&
                  <CircularProgress className={ classes.spinner }/>
              }
            </div>
          </div>
        }
        { customerSelected === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Box>
      <Dialog  open={ editMode }>
        <div className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleContact }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField variant='outlined' required fullWidth id='email' autoFocus
                    label={ strings.labelEmail } value={ values.email } onChange={ handleChange('email') }
                    inputProps={ { maxLength: fieldLimits.customerContact.email.size } }/>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth id='firstName'
                  label={ strings.labelFirstName } value={ values.first_name } onChange={ handleChange('first_name') }
                  inputProps={ { maxLength: fieldLimits.customerContact.first_name.size } }/>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth id='lastName'
                  label={ strings.labelLastName } value={ values.last_name } onChange={ handleChange('last_name') }
                  inputProps={ { maxLength: fieldLimits.customerContact.last_name.size } }/>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth id='phone'
                  label={ strings.labelPhone } value={ values.phone } onChange={ handleChange('phone') }
                  inputProps={ { maxLength: fieldLimits.customerContact.phone.size } }/>
              </Grid>

              <Grid item xs={12}>
                <div className={ classes.lineButtonBox }>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
                </div>
              </Grid>

            </Grid>            
          </form>
        </div>       
      </Dialog>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.email : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    customerContactList: state.customerContactList,
    customerContactTableForceRefresh: state.customerContactTableForceRefresh,
    customerSelected: state.customerSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( CustomerContactTable ) );