import { store } from '../index';
import { setSettingsNotificationList, refreshSettingsNotificationTable } from '../utils/Reducer';
import { setSettingsWhatsnewList, refreshSettingsWhatsnewTable } from '../utils/Reducer';
import { setSettingsLearningList, refreshSettingsLearningTable } from '../utils/Reducer';
import { setSettingsOEMList, setSettingsOEMEditionsList, setSettingsOEMUpgradesList, setSettingsOEMPluginsList, setSettingsOEMThemeValues, setSettingsDistributorsList } from '../utils/Reducer';
import { refreshSettingsOEMTable, refreshSettingsOEMEditionsTable, refreshSettingsOEMEditor, refreshSettingsOEMPreview, refreshSettingsOEMVariables, refreshSettingsOEMUpgradesTable } from '../utils/Reducer';
import { setSettingsOEMDistributorList, setBuidlServerDown, setBuildStarted } from '../utils/Reducer';

import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import axios from 'axios';


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOemNotifications
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOemNotifications(oemId, from, limit) {

  const onError = error => {
    gError('getSettingsOemNotifications', error);
    store.dispatch(setSettingsNotificationList({ count: 0, items: [] }));
  }

  store.dispatch(setSettingsNotificationList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/' + oemId + '/notifications?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setSettingsNotificationList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMWhatsnew(oemId, from, limit) {

  const onError = error => {
    gError('getSettingsWhatsnew', error);
    store.dispatch(setSettingsWhatsnewList({ count: 0, items: [] }));
  }

  store.dispatch(setSettingsWhatsnewList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemwhatsnew/' + oemId + '?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setSettingsWhatsnewList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMLearning(oemId, from, limit) {

  const onError = error => {
    gError('getSettingsOEMLearning', error);
    store.dispatch(setSettingsLearningList({ count: 0, items: [] }));

  }

  store.dispatch(setSettingsLearningList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemlearning/' + oemId + '?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setSettingsLearningList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEM(from, limit) {

  const onError = error => {
    gError('getSettingsOEM', error);
    store.dispatch(setSettingsOEMList({ count: 0, items: [] }));
    store.dispatch(setSettingsDistributorsList({ distributors: [] }));

  }

  store.dispatch(setSettingsOEMList(undefined));
  store.dispatch(setSettingsDistributorsList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          // TODO - Check this with Jorge - This code is to remove duplicates from list 
          // for (var x = 1; x < json.items.length; x++) {
          //   if (!json.items[x - 1].distributor_ids)
          //     json.items[x - 1].distributor_ids = [json.items[x - 1].distributor_id];
          //   if (json.items[x - 1].name === json.items[x].name) {
          //     json.items[x - 1].distributor_ids.push(json.items[x].distributor_id);
          //     json.items[x - 1].distributor_name = json.items[x - 1].distributor_name + ', ' + json.items[x].distributor_name;
          //     json.items.splice(x, 1);
          //     x--; 
          //   }
          // }
          store.dispatch(setSettingsOEMList(json));
          store.dispatch(setSettingsDistributorsList(json.distributors));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMDistributorJobs
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMDistributorJobs() {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPrinters', error);
      resolve(false);
    }

    var authToken = store.getState().authToken;
    var url = process.env.REACT_APP_AUTOBUILD_WEB_API_URL + '/v1/settings/jobs';
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cp-api-authorization': authToken
      }
    }).then((response) => {
      resolve(response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
      resolve(false);
    });

  });
}

export function mergeOEMsDataWithBuildsInfo(OEMsData, buildsData) {
  try{

    let OEMsDataCurrent = OEMsData;

    for (let indexOEM = 0; indexOEM < OEMsDataCurrent.items.length; indexOEM++) {
      const OEM = OEMsDataCurrent.items[indexOEM];

      for (let indexBuild = 0; indexBuild < buildsData.builds.length; indexBuild++) {
        const build = buildsData.builds[indexBuild]; 

        if (build.buildData.name == OEM.name){          
          OEMsDataCurrent.items[indexOEM]['buildStatus'] = build.buildStatus;
          OEMsDataCurrent.items[indexOEM]['buildId'] = build.buildId;
          OEMsDataCurrent.items[indexOEM]['buildCanceled'] = build.buildCanceled;          
          break;
        }
      }
    }
    return OEMsDataCurrent;

  } catch (e) {
    console.log(e);
    return;
  }
  
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// cancelBuild
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function cancelBuild(oemId) {
  return new Promise((resolve) => {
    const onError = error => {
      gError('getPrinters', error);
      resolve(false);
    }
    var authToken = store.getState().authToken;
    var url = process.env.REACT_APP_AUTOBUILD_WEB_API_URL + '/v1/settings/buildCancel';
    axios({
      url: url,
      method: 'PUT',
      headers: {   
        'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cp-api-authorization': authToken
      }, 
      data: JSON.stringify({oemId: oemId})
    }).then((response) => {
      resolve(true);
    }).catch(function (error) {
      resolve(false);
    });

  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMDistributor(from, limit) {

  try {

    const onError = error => {
      gError('getSettingsOEMDistributor', error);
      store.dispatch(setSettingsOEMDistributorList({ count: 0, items: [] }));
    }

    var authToken = store.getState().authToken;
    axios({
      method: "GET",
      url: process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemdistributor?from=' + from + '&limit=' + limit,
      headers: {
        'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cp-api-authorization': authToken
      }
    }).then((response) => {
      (async () => {

        let OEMsData = response.data;

        // Get build info to add on OEM's array - TODO - Add page to view job history 
        // const buidlsResponse = await getSettingsOEMDistributorJobs();
        // if (buidlsResponse !== false){
        //   OEMsData = mergeOEMsDataWithBuildsInfo(OEMsData, buidlsResponse)
        // }
        store.dispatch(setSettingsOEMDistributorList(OEMsData));
        return;

      })();
    }).catch(function (thrown) {
      console.log(thrown);
      onError(thrown)
      return;
    });

  } catch (e) {
    return;
    console.log(e);
  }

  // store.dispatch(setSettingsOEMDistributorList(undefined));
  // var authToken = store.getState().authToken;
  // fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemdistributor?from=' + from + '&limit=' + limit, {
  //   method: 'GET',
  //   headers: {
  //     'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
  //     'Accept': 'application/json',
  //     'cp-api-authorization': authToken
  //   }
  // })
  //   .then(response => {
  //     if (response.ok) {
  //       response.json().then(json => {
  //         store.dispatch(setSettingsOEMDistributorList(json));
  //       })
  //       return;
  //     }
  //     response.json().then(json => onError(json.message));
  //   })
  //   .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMEditions(from, limit, oemId) {

  const onError = error => {
    gError('getSettingsOEMEditions', error);
    store.dispatch(setSettingsOEMEditionsList({ count: 0, items: [] }));

  }

  store.dispatch(setSettingsOEMEditionsList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemeditions/' + oemId + '?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          // for(var x=0;x<json.items.length; x++){
          //   var newProductFile = '<p>' + json.items[x].product_file.replace(/(\n)+/g, '<br/>') + '</p>';
          //   json.items[x].product_file = newProductFile;
          // }        
          store.dispatch(setSettingsOEMEditionsList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMUpgrades(from, limit, oemId) {

  const onError = error => {
    gError('getSettingsOEMUpgrades', error);
    store.dispatch(setSettingsOEMUpgradesList({ count: 0, items: [] }));

  }

  store.dispatch(setSettingsOEMUpgradesList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemupgrades/' + oemId + '?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var upgrades = { count: 0, items: [] };
          var plugins = { count: 0, items: [] };
          for (var x = 0; x < json.items.length; x++) {
            // if(json.items[x].product_base.includes(',')){
            //   plugins.count++;          
            //   plugins.items.push(json.items[x]);
            // }
            // else{
            //   upgrades.count++;          
            //   upgrades.items.push(json.items[x]);
            // }
            if (json.items[x].product_base.includes('=>')) {
              upgrades.count++;
              upgrades.items.push(json.items[x]);
            }
            else {
              plugins.count++;
              plugins.items.push(json.items[x]);
            }
          }
          store.dispatch(setSettingsOEMUpgradesList(upgrades));
          store.dispatch(setSettingsOEMPluginsList(plugins));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMTheme
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMTheme(oemId) {

  const onError = error => {
    gError('getSettingsOEMTheme', error);
    store.dispatch(setSettingsOEMThemeValues([]));

  }

  store.dispatch(setSettingsOEMThemeValues(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemtheme/' + oemId, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          if (json.data !== '') {
            var data = JSON.parse(json.data);
            json.data = data;
          }
          store.dispatch(setSettingsOEMThemeValues(json));
          store.dispatch(refreshSettingsOEMPreview());
          store.dispatch(refreshSettingsOEMEditor());
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function getSettingsUpgrades( from, limit ) {

  const onError = error => {
    gError( 'getSettingsUpgrades', error ); 
    store.dispatch( setSettingsUpgradesList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsUpgradesList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsUpgradesList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function getSettingsEditions( from, limit ) {

  const onError = error => {
    gError( 'getSettingsEditions', error ); 
    store.dispatch( setSettingsEditionsList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsEditionsList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/editions?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'cp-api-authorization' : authToken
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsEditionsList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOemNotification
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOemNotification(notification) {

  const onError = error => {
    gError('setSettingsOemNotification', error);
    store.dispatch(refreshSettingsNotificationTable());
  }

  if (notification.msgShow !== undefined) {
    delete notification.msgShow;
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/notifications/' + notification.notification_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(notification)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsNotificationTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMWhatsnew(whatsnew) {

  const onError = error => {
    gError('setSettingsWhatsnew', error);
    store.dispatch(refreshSettingsWhatsnewTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemwhatsnew/' + whatsnew.notification_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(whatsnew)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsWhatsnewTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMLearning(learning) {

  const onError = error => {
    gError('setSettingsOEMLearning', error);
    store.dispatch(refreshSettingsLearningTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemlearning/' + learning.notification_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(learning)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsLearningTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEM(oem) {

  const onError = error => {
    gError('setSettingsOEM', error);
    store.dispatch(refreshSettingsOEMTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem/' + oem.oem_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(oem)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsOEMTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMProducts
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMProducts(product) {

  const onError = error => {
    gError('setSettingsOEMProducts', error);
    store.dispatch(refreshSettingsOEMUpgradesTable());
    store.dispatch(refreshSettingsOEMUpgradesTable());
    //store.dispatch( refreshSettingsOEMPluginsTable( ) );
  }
  if (product.file !== undefined && product.file !== '') {

    let newProductFile = product.file.replaceAll('<br>', "\n");
    newProductFile = product.file.replaceAll('<br/>', "\n");
    newProductFile = newProductFile.replace(/(<([^>]+)>)/gi, '');
    product.file = newProductFile;

  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/productupgrade/' + product.oem_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(product)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsOEMEditionsTable());
        store.dispatch(refreshSettingsOEMUpgradesTable());
        //store.dispatch( refreshSettingsOEMPluginsTable( ) );
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDistributorOEMEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDistributorOEMEditions(from, limit, oemId) {

  const onError = error => {
    gError('getDistributorOEMEditions', error);
    store.dispatch(setSettingsOEMEditionsList({ count: 0, items: [] }));

  }

  store.dispatch(setSettingsOEMEditionsList(undefined));
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/distributors/oemeditions/' + oemId + '?from=' + from + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          // for(var x=0;x<json.items.length; x++){
          //   var newProductFile = '<p>' + json.items[x].product_file.replace(/(\n)+/g, '<br/>') + '</p>';
          //   json.items[x].product_file = newProductFile;
          // }        
          store.dispatch(setSettingsOEMEditionsList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setDistributorOEMProducts
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setDistributorOEMProducts(product) {

  const onError = error => {
    gError('setDistributorOEMProducts', error);
    store.dispatch(refreshSettingsOEMUpgradesTable());
    store.dispatch(refreshSettingsOEMUpgradesTable());
    //store.dispatch( refreshSettingsOEMPluginsTable( ) ); 
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/distributors/oemedition/' + product.oem_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(product)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsOEMEditionsTable());
        store.dispatch(refreshSettingsOEMUpgradesTable());
        //store.dispatch( refreshSettingsOEMPluginsTable( ) );
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsOEMEdition
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsOEMEdition(product) {

  const onError = error => {
    gError('deleteSettingsOEMEdition', error);
    store.dispatch(refreshSettingsOEMUpgradesTable());
    store.dispatch(refreshSettingsOEMEditionsTable());
    // store.dispatch( refreshSettingsOEMUpgradesTable( ) );
    //store.dispatch( refreshSettingsOEMPluginsTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemeditions/' + product.productfamily_id + '/' + product.product_code, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsOEMEditionsTable());
        store.dispatch(refreshSettingsOEMUpgradesTable());
        //store.dispatch( refreshSettingsOEMPluginsTable( ) );
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMTheme
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMTheme(theme) {

  const onError = error => {
    gError('setSettingsOEMTheme', error);
    store.dispatch(refreshSettingsOEMEditor());
    store.dispatch(refreshSettingsOEMPreview());
    store.dispatch(refreshSettingsOEMVariables());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemtheme/' + theme.productfamily_id, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify(theme)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch({ type: 'SET_THEME_HAS_CHANGES', value: false });
        store.dispatch({ type: 'SET_OEM_HAS_CHANGES', value: false });
        store.dispatch(refreshSettingsOEMEditor());
        store.dispatch(refreshSettingsOEMPreview());
        //store.dispatch( refreshSettingsOEMVariables( ) );
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function setSettingsUpgrades( upgrade ) {

  const onError = error => {
    gError( 'setSettingsUpgrades', error );
    store.dispatch( refreshSettingsUpgradesTable( ) );
  }

  if ( upgrade.msgShow !== undefined )
    delete upgrade.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades/' + upgrade.product_code, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( upgrade )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsUpgradesTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function setSettingsEditions( edition ) {

  const onError = error => {
    gError( 'setSettingsEditions', error );
    store.dispatch( refreshSettingsEditionsTable( ) );
  }

  if ( edition.msgShow !== undefined )
    delete edition.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades/' + edition.product_code, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( edition )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsEditionsTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsNotification
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsNotification(notification) {

  const onError = error => {
    gError('deleteSettingsNotification', error);
    store.dispatch(refreshSettingsNotificationTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/notifications/' + notification.notification_id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsNotificationTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsWhatsnew(whatsnew) {

  const onError = error => {
    gError('deleteSettingsWhatsnew', error);
    store.dispatch(refreshSettingsWhatsnewTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/whatsnew/' + whatsnew.notification_id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsWhatsnewTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsLearning(learning) {

  const onError = error => {
    gError('deleteSettingsLearning', error);
    store.dispatch(refreshSettingsLearningTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/learning/' + learning.notification_id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsLearningTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsOEM(oem) {

  const onError = error => {
    gError('deleteSettingsOEM', error);
    store.dispatch(refreshSettingsOEMTable());
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem/' + oem.productfamily_id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshSettingsOEMTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// buildOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------

export function buildOEM(oemId) {

  const onError = error => {
    gError('buildOEM', error);
    //store.dispatch( setSettingsOEMUpgradesList( { count:0, items:[] } ) );

  }

  //store.dispatch( setSettingsOEMUpgradesList( undefined ) );
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/build/oem/' + oemId, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          //store.dispatch( setSettingsOEMUpgradesList( json ) );
          buildOEMService(json)

        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// buildOEMService
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function buildOEMService(OEM) {

  const onError = error => {
    gError('buildOEMService', error);

  }

  var authToken = store.getState().authToken;
  // fetch('https://auto.build.service.cardpresso.com/v1/settings/autobuild', {
  fetch(process.env.REACT_APP_AUTOBUILD_WEB_API_URL + '/v1/settings/autobuild?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      //'cp-api-authorization': authToken
    },
    body: JSON.stringify(OEM)
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(setBuildStarted(true));
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => store.dispatch(setBuidlServerDown(true)));
}

//https://auto.build.service.cardpresso.com/v1/settings/autobuild
/*fetch( 'http://localhost:3030/v1/settings/autobuild', {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'cp-api-authorization' : authToken
    },
    body : JSON.stringify( OEM )
  } )
  .then( response => {
    if ( response.ok ) {
       response.json().then( json => {
        //store.dispatch( setSettingsOEMUpgradesList( json ) );

      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );*/

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// sendBuildServerDownEmail
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------

export function sendBuildServerDownEmail(oemId) {

  const onError = error => {
    gError('sendBuildServerDownEmail', error);
    //store.dispatch( setSettingsOEMUpgradesList( { count:0, items:[] } ) );
  }

  //store.dispatch( setSettingsOEMUpgradesList( undefined ) );
  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/build/buildError/' + oemId, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'cp-api-authorization': authToken
    }
  })
    .then(response => {
      if (response.ok) {
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}