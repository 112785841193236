import React, { useState }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { refreshPrinterTable } from '../utils/Reducer';
import { checkForPrinter, assignPrinter } from './PrinterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Button, Typography, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

let strings=new LocalizedStrings({
	en:{
    title: 'Add Printer',
    
    step1Label: 'Select serial number',
    step1ErrorLabel: 'Select serial number - empty or in use',
    step1Text: 'Please type the serial number of the printer',
    step1TextFieldLabel: 'Serial Number',

    confirmationLabel: 'Confirmation',
    confirmationErrorLabel: 'Confirmation - upgrade not allowed',
    confirmationText: 'You are about to add this printer, {serialNumber}. It may or not already have data.',

    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
	}
});

function PrinterAddWizard( props ) { 

  const { classes } = props;

  const { open, onClose } = props;

  const { refreshPrinterTable } = props;

  const [ activeStep, setActiveStep ] = useState( 0 );
  const [ step1Error, setStep1Error ] = useState( false );
  const [ step1Value, setStep1Value ] = useState( '' );
  const [ confirmationError, setConfirmationError ] = useState( false );

  const handleNext = () => {
    switch ( activeStep ) {
      case 0:
        if ( step1Value === '' ) {
          setStep1Error( true );
        }
        else {
          setStep1Error( false );
          checkForPrinter( step1Value, step1CB );
        }
        break;
      case 1:
        assignPrinter( step1Value, confirmationCB );
        break;
      default: break;
    }
  }

  const handleBack = () => {
    switch ( activeStep ) {
      case 0:
        handleCloseDialog();
        break;
      case 1:
        setConfirmationError( false );
        setActiveStep( activeStep - 1 );
        break;
      default:
        break;
    }
  }

  const step1CB = ( ok ) => {
    if ( ok )
      setActiveStep( activeStep + 1 );
    else
      setStep1Error( true );
  }

  const confirmationCB = ( result ) => {
    if ( result ) {
      handleCloseDialog();
      refreshPrinterTable();
    }
    else {
      setConfirmationError( true );
    }
  }

  const handleCloseDialog = () => {
    setActiveStep( 0 );
    setStep1Error( false );
    setStep1Value( '' );
    setConfirmationError( false );
    onClose();
  }

  return (
    <Dialog open={ open }>
      <DialogTitle className={ classes.title }>{ strings.title }</DialogTitle>
      <DialogContent style={ { width: '500px', height: '490px' } }>
        <Stepper activeStep={ activeStep } orientation='vertical'>
          <Step>
            <StepLabel error={ step1Error } optional={ step1Value !== '' && activeStep >=1 ? <Typography variant='body1'>{ step1Value }</Typography> : '' }>
              <Typography variant='body1'>{ step1Error ? strings.step1ErrorLabel : strings.step1Label }</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant='body1'>{ strings.step1Text }</Typography>
              <TextField variant='outlined' margin='dense' autoFocus
                label={ strings.step1TextFieldLabel } value={ step1Value } onChange={ event => setStep1Value( event.target.value ) }/>
            </StepContent>
          </Step>
          <Step>
            <StepLabel error={ confirmationError }>
              <Typography variant='body1'>{ confirmationError ? strings.confirmationErrorLabel : strings.confirmationLabel }</Typography>
            </StepLabel>
            <StepContent>
              { step1Value !== '' &&
                <Typography variant='body1'>
                  { strings.confirmationText.replace('{serialNumber}', step1Value ) }
                </Typography>
              }
             </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions className={ classes.lineButtonBox }>
        <Button className={ classes.lineButton } onClick={ handleBack } variant='contained' color='secondary'>{ activeStep === 0 ? strings.cancel : strings.back }</Button>
        <Button className={ classes.lineButton } onClick={ handleNext } variant='contained' color='secondary'>{ activeStep === 1 ? strings.confirm : strings.next }</Button>
      </DialogActions>
    </Dialog>
  );
 }

export default connect( null, { refreshPrinterTable } )( withStyles( gStyles )( PrinterAddWizard ) );