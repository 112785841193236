import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import {  Container, Alert, AlertTitle } from '@mui/material';


let strings = new LocalizedStrings({
  en: {
    title: 'Error',
    description: 'Page not found or does not have the right permissions.',
  }
})

function NotFoundOrMissingPermissions(props) {

  const { classes } = props;

  return (
    <Container maxWidth='xs' style={{ height: '100%' }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Alert severity="error">
          <AlertTitle>{strings.title}</AlertTitle>
          {strings.description}
          {/* <div>
            <Button className={classes.lineButton} variant='contained' color='secondary' href="/">Go to Home</Button> 
          </div> */}
        </Alert>
        
      </div>
    </Container>
  );
}

export default connect(null, {})(withStyles(gStyles)(NotFoundOrMissingPermissions));