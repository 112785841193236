import update from 'immutability-helper';
import { saveToLocalStorage, clearLocalStorage } from './Utils';

export const initialState = {
    authToken: undefined,
    userEmail: '',
    userType: 2,
    needPassword: false,
    changePass: false,
    allowAssignPrinter: false,
    allowUpgrades: undefined,
    allowFeatures: 0,

    errorMessageList: [],

    distributorTableForceRefresh: false,
    distributorList: undefined,
    distributorUserTableForceRefresh: false,
    distributorUserList: undefined,
    distributorProductFamilyTableForceRefresh: false,
    distributorProductFamilyList: undefined,
    distributorFeatureList: undefined,
    distributorSelected: undefined,
    distributorSubDistributorList: undefined,
    distributorSubDistributorTableForceRefresh: false,
    distributorLicenseTableForceRefresh: false,
    distributorLicenseList: undefined,

    customerTableForceRefresh: false,
    customerList: undefined,
    customerContactTableForceRefresh: false,
    customerContactList: undefined,
    customerLicenseTableForceRefresh: false,
    customerLicenseList: undefined,
    customerSelected: undefined,
    customerAssignItem: undefined,

    licenseTableForceRefresh: false,
    licenseList: undefined,
    licensePrinterList: undefined,
    licenseHistoryList: undefined,
    licenseSelected: undefined,
    licenseAssignList: [],
    licenseEditionList: undefined,

    printerTableForceRefresh: false,
    printerList: undefined,
    printerSelected: undefined,
    printerModelList: undefined,
    printerStatusList: undefined,
    
    productFamilyTableForceRefresh: false,
    productFamilyList: undefined,
    productFamilyAssignList: [],

    settingsNotificationTableForceRefresh: false,
    settingsNotificationList: undefined,

    filtersForceRefresh: false,
    filterList: undefined,

    buidlServerDown: false,
    buildStarted: false,
    switchButtonEditor: false,
    switchButtonPreview: false
};

export const setLoginInfo = value => ( { type: 'SET_LOGIN_INFO', value } );
export const passChanged = value => ( { type: 'PASSWORD_CHANGED', value } );
export const setChangePass = value => ( { type: 'SET_CHANGE_PASS', value } );
export const logout = value => ( { type: 'LOGOUT', value } );
export const setAllowUpgrades = value => ( { type: 'SET_ALLOW_UPGRADES', value } );

export const addErrorMessage = value => ( { type: 'ADD_ERROR_MESSAGE', value } );
export const clearErrorMessageList = value => ( { type: 'CLEAR_ERROR_MESSAGE_LIST', value } );

export const refreshDistributorTable = value => ( { type: 'REFRESH_DISTRIBUTOR_TABLE', value } );
export const setDistributorList = value => ( { type: 'SET_DISTRIBUTOR_LIST', value } );
export const refreshDistributorUserTable = value => ( { type: 'REFRESH_DISTRIBUTOR_USER_TABLE', value } );
export const setDistributorUserList = value => ( { type: 'SET_DISTRIBUTOR_USER_LIST', value } );
export const refreshDistributorProductFamilyTable = value => ( { type: 'REFRESH_DISTRIBUTOR_PRODUCTFAMILY_TABLE', value } );
export const setDistributorProductFamilyList = value => ( { type: 'SET_DISTRIBUTOR_PRODUCTFAMILY_LIST', value } );
export const setDistributorFeatureList = value => ( { type: 'SET_DISTRIBUTOR_FEATURE_LIST', value } );
export const setDistributorSelected = value => ( { type: 'SET_DISTRIBUTOR_SELECTED', value } );
export const refreshDistributorSubDistributorTable = value => ( { type: 'REFRESH_DISTRIBUTOR_SUBDISTRIBUTOR_TABLE', value } );
export const setDistributorSubDistributorList = value => ( { type: 'SET_DISTRIBUTOR_SUBDISTRIBUTOR_LIST', value } );
export const refreshDistributorLicenseTable = value => ( { type: 'REFRESH_DISTRIBUTOR_LICENSE_TABLE', value } );
export const setDistributorLicenseList = value => ( { type: 'SET_DISTRIBUTOR_LICENSE_LIST', value } );

export const refreshCustomerTable = value => ( { type: 'REFRESH_CUSTOMER_TABLE', value } );
export const setCustomerList = value => ( { type: 'SET_CUSTOMER_LIST', value } );
export const refreshCustomerContactTable = value => ( { type: 'REFRESH_CUSTOMER_CONTACT_TABLE', value } );
export const setCustomerContactList = value => ( { type: 'SET_CUSTOMER_CONTACT_LIST', value } );
export const refreshCustomerLicenseTable = value => ( { type: 'REFRESH_CUSTOMER_LICENSE_TABLE', value } );
export const setCustomerLicenseList = value => ( { type: 'SET_CUSTOMER_LICENSE_LIST', value } );
export const setCustomerSelected = value => ( { type: 'SET_CUSTOMER_SELECTED', value } );
export const setCustomerAssignItem = value => ( { type: 'SET_CUSTOMER_ASSIGN_ITEM', value } );

export const refreshLicenseTable = value => ( { type: 'REFRESH_LICENSE_TABLE', value } );
export const setLicenseList = value => ( { type: 'SET_LICENSE_LIST', value } );
export const setLicensePrinterList = value => ( { type: 'SET_LICENSE_PRINTER_LIST', value } );
export const setLicenseSelected = value => ( { type: 'SET_LICENSE_SELECTED', value } );
export const setLicenseAssignList = value => ( { type: 'SET_LICENSE_ASSIGN_LIST', value } );
export const setLicenseEditionList = value => ( { type: 'SET_LICENSE_EDITION_LIST', value } );
export const setLicenseHistoryList = value => ({ type: 'SET_LICENSE_HISTORY_LIST', value });

export const refreshPrinterTable = value => ( { type: 'REFRESH_PRINTER_TABLE', value } );
export const setPrinterList = value => ( { type: 'SET_PRINTER_LIST', value } );
export const setPrinterSelected = value => ( { type: 'SET_PRINTER_SELECTED', value } );
export const setPrinterModelList = value => ( { type: 'SET_PRINTER_MODEL_LIST', value } );
export const setPrinterStatusList = value => ( { type: 'SET_PRINTER_STATUS_LIST', value } );

export const refreshProductFamilyTable = value => ( { type: 'REFRESH_PRODUCTFAMILY_TABLE', value } );
export const setProductFamilyList = value => ( { type: 'SET_PRODUCTFAMILY_LIST', value } );
export const setProductFamilyAssignList = value => ( { type: 'SET_PRODUCTFAMILY_ASSIGN_LIST', value } );

export const refreshSettingsNotificationTable = value => ( { type: 'REFRESH_SETTINGS_NOTIFICATION_TABLE', value } );
export const setSettingsNotificationList = value => ( { type: 'SET_SETTINGS_NOTIFICATION_LIST', value } );

export const refreshSettingsWhatsnewTable = value => ( { type: 'REFRESH_SETTINGS_WHATSNEW_TABLE', value } );
export const setSettingsWhatsnewList = value => ( { type: 'SET_SETTINGS_WHATSNEW_LIST', value } );

export const refreshSettingsLearningTable = value => ( { type: 'REFRESH_SETTINGS_LEARNING_TABLE', value } );
export const setSettingsLearningList = value => ( { type: 'SET_SETTINGS_LEARNING_LIST', value } );

export const refreshSettingsOEMTable = value => ( { type: 'REFRESH_SETTINGS_OEM_TABLE', value } );
export const refreshSettingsOEMEditionsTable = value => ( { type: 'REFRESH_SETTINGS_OEM_EDITIONS_TABLE', value } );
export const refreshSettingsOEMUpgradesTable = value => ( { type: 'REFRESH_SETTINGS_OEM_UPGRADES_TABLE', value } );

export const refreshSettingsOEMDistributorTable = value => ( { type: 'REFRESH_SETTINGS_OEM_DISTRIBUTOR_TABLE', value } );



export const setSettingsOEMList = value => ( { type: 'SET_SETTINGS_OEM_LIST', value } );
export const setSettingsOEMEditionsList = value => ( { type: 'SET_SETTINGS_OEM_EDITIONS_LIST', value } );
export const setSettingsOEMUpgradesList = value => ( { type: 'SET_SETTINGS_OEM_UPGRADES_LIST', value } );
export const setSettingsOEMPluginsList = value => ( { type: 'SET_SETTINGS_OEM_PLUGINS_LIST', value } );
export const setSettingsDistributorsList = value => ( { type: 'SET_SETTINGS_DISTRIBUTORS_LIST', value } );

export const setSettingsOEMDistributorList = value => ( { type: 'SET_SETTINGS_OEM_DISTRIBUTOR_LIST', value } );

export const setEditSoftwareIcon = value => ( { type: 'SET_EDIT_SOFTWARE_ICON', value } );
export const setEditSoftwareLogo = value => ( { type: 'SET_EDIT_SOFTWARE_LOGO', value } );
export const setEditBackgroundImage = value => ( { type: 'SET_EDIT_BACKGROUND_IMAGE', value } );
export const setEditTextColors = value => ( { type: 'SET_EDIT_TEXT_COLORS', value } );
export const setEditWidgetsColorsNormal = value => ( { type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value } );
export const setEditWidgetsColorsSelected = value => ( { type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value } );
export const setEditWidgetsColorsDisabled = value => ( { type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value } );
export const setEditMenuColors = value => ( { type: 'SET_EDIT_MENU_COLORS', value } );
export const setEditMenuBarColors = value => ( { type: 'SET_EDIT_MENU_BAR_COLORS', value } );
export const setEditNotificationColors = value => ( { type: 'SET_EDIT_NOTIFICATION_COLORS', value } );

export const setThemeGenerated = value => ( { type: 'SET_THEME_GENERATED', value } );
export const setClickNoThemeGenerated = value => ( { type: 'SET_CLICK_NO_THEME_GENERATED', value } );

export const setBuidlServerDown = value => ( { type: 'SET_BUILD_SERVER_DOWN', value } );
export const setOEMHasChanges = value => ( { type: 'SET_OEM_HAS_CHANGES', value } );
export const setThemeHasChanges = value => ( { type: 'SET_THEME_HAS_CHANGES', value } );
export const setBuildStarted = value => ( { type: 'SET_BUILD_STARTED', value } );
export const setSwitchButtonEditor = value => ( { type: 'SET_SWITCH_BUTTON_EDITOR', value } );
export const setSwitchButtonPreview = value => ( { type: 'SET_SWITCH_BUTTON_PREVIEW', value } );

export const setSettingsOEMThemeValues = value => ( { type: 'SET_SETTINGS_OEM_THEME_VALUES', value } );
export const setSettingsOEMValues = value => ( { type: 'SET_SETTINGS_OEM_VALUES', value } );
export const refreshSettingsOEMEditor = value => ( { type: 'REFRESH_SETTINGS_OEM_EDITOR', value } );
export const refreshSettingsOEMPreview = value => ( { type: 'REFRESH_SETTINGS_OEM_PREVIEW', value } );
export const refreshSettingsOEMVariables = value => ( { type: 'REFRESH_SETTINGS_OEM_VARIABLES', value } );

export const refreshFilters = value => ( { type: 'REFRESH_FILTERS', value } );
export const setFilters = value => ( { type: 'SET_FILTERS', value } );
export const setFilterCounter = value => ( { type: 'SET_FILTER_COUNTER', value } );

export default function rootReducer( state = initialState, action ) {
  var newState;

  switch ( action.type ) {

    case 'SET_LOGIN_INFO':
      newState = { ...state,
        authToken: action.value.token,
        userEmail: action.value.email,
        userType: action.value.type,
        needPassword: action.value.needPassword,
        allowAssignPrinter: action.value.allowAssignPrinter,
        allowFeatures: action.value.allowFeatures };
      saveToLocalStorage( newState );
      return newState;
    case 'PASSWORD_CHANGED':
      newState = { ...state, needPassword: false, changePass: false };
      saveToLocalStorage( newState );
      return newState;      
    case 'SET_CHANGE_PASS':
      return { ...state, changePass: action.value };
    case 'LOGOUT':
      clearLocalStorage();
      return initialState;
    case 'SET_ALLOW_UPGRADES':
      newState = { ...state, allowUpgrades: action.value };
      saveToLocalStorage( newState );
      return newState;      

    case 'ADD_ERROR_MESSAGE':
      if ( state.errorMessageList.find( item => { return item === action.value; } ) === undefined )
        return { ...state, errorMessageList: [ ...state.errorMessageList, action.value ] };
      return state;
    case 'CLEAR_ERROR_MESSAGE_LIST':
      return { ...state, errorMessageList: [] };

    case 'REFRESH_DISTRIBUTOR_TABLE':
      return { ...state, distributorTableForceRefresh:!state.distributorTableForceRefresh, distributorPage: 0 };
    case 'SET_DISTRIBUTOR_LIST':
      return { ...state, distributorList: action.value };
    case 'REFRESH_DISTRIBUTOR_USER_TABLE':
      return { ...state, distributorUserTableForceRefresh:!state.distributorUserTableForceRefresh };  
    case 'SET_DISTRIBUTOR_USER_LIST':
      return { ...state, distributorUserList: action.value };
    case 'REFRESH_DISTRIBUTOR_PRODUCTFAMILY_TABLE':
      return { ...state, distributorProductFamilyTableForceRefresh:!state.distributorProductFamilyTableForceRefresh };     
    case 'SET_DISTRIBUTOR_PRODUCTFAMILY_LIST':
      return { ...state, distributorProductFamilyList: action.value };
    case 'SET_DISTRIBUTOR_FEATURE_LIST':
      return { ...state, distributorFeatureList: action.value };
    case 'SET_DISTRIBUTOR_SELECTED':
      return { ...state, distributorSelected: action.value };
    case 'REFRESH_DISTRIBUTOR_SUBDISTRIBUTOR_TABLE':
      return { ...state, distributorSubDistributorTableForceRefresh:!state.distributorSubDistributorTableForceRefresh };  
    case 'SET_DISTRIBUTOR_SUBDISTRIBUTOR_LIST':
      return { ...state, distributorSubDistributorList: action.value };
    case 'REFRESH_DISTRIBUTOR_LICENSE_TABLE':
      return { ...state, distributorLicenseTableForceRefresh:!state.distributorLicenseTableForceRefresh };     
    case 'SET_DISTRIBUTOR_LICENSE_LIST':
      return { ...state, distributorLicenseList: action.value };

    case 'REFRESH_CUSTOMER_TABLE':
      return { ...state, customerTableForceRefresh:!state.customerTableForceRefresh, customerPage: 0 };
    case 'SET_CUSTOMER_LIST':
      return { ...state, customerList: action.value };
    case 'REFRESH_CUSTOMER_CONTACT_TABLE':
      return { ...state, customerContactTableForceRefresh:!state.customerContactTableForceRefresh };     
    case 'SET_CUSTOMER_CONTACT_LIST':
      return { ...state, customerContactList: action.value };
    case 'REFRESH_CUSTOMER_LICENSE_TABLE':
      return { ...state, customerLicenseTableForceRefresh:!state.customerLicenseTableForceRefresh };     
    case 'SET_CUSTOMER_LICENSE_LIST':
      return { ...state, customerLicenseList: action.value };
    case 'SET_CUSTOMER_SELECTED':
      return { ...state, customerSelected: action.value };
    case 'SET_CUSTOMER_ASSIGN_ITEM':
        return { ...state, customerAssignItem: action.value };

    case 'REFRESH_LICENSE_TABLE':
      return { ...state, licenseTableForceRefresh:!state.licenseTableForceRefresh };
    case 'SET_LICENSE_LIST':
      return { ...state, licenseList: action.value, licenseAssignList: [] };
    case 'SET_LICENSE_PRINTER_LIST':
      return { ...state, licensePrinterList: action.value };
    case 'SET_LICENSE_HISTORY_LIST':
      return { ...state, licenseHistoryList: action.value };
    case 'SET_LICENSE_SELECTED':
      return { ...state, licenseSelected: action.value };
    case 'SET_LICENSE_ASSIGN_LIST':
      return { ...state, licenseAssignList: action.value };
    case 'SET_LICENSE_EDITION_LIST':
      return { ...state, licenseEditionList: action.value };

    case 'REFRESH_PRINTER_TABLE':
      return { ...state, printerTableForceRefresh:!state.printerTableForceRefresh }; 
    case 'SET_PRINTER_LIST':
      return { ...state, printerList: action.value };
    case 'SET_PRINTER_SELECTED':
      return { ...state, printerSelected: action.value };
    case 'SET_PRINTER_MODEL_LIST':
      return { ...state, printerModelList: action.value };
    case 'SET_PRINTER_STATUS_LIST':
      return { ...state, printerStatusList: action.value }; 


    case 'REFRESH_PRODUCTFAMILY_TABLE':
      return { ...state, productFamilyTableForceRefresh:!state.productFamilyTableForceRefresh };
    case 'SET_PRODUCTFAMILY_LIST':
      return { ...state, productFamilyList: action.value, productFamilyAssignList: [] };
    case 'SET_PRODUCTFAMILY_ASSIGN_LIST':
      return { ...state, productFamilyAssignList: action.value };

    case 'REFRESH_SETTINGS_NOTIFICATION_TABLE':
      return { ...state, settingsNotificationTableForceRefresh:!state.settingsNotificationTableForceRefresh };    
    case 'SET_SETTINGS_NOTIFICATION_LIST':
      return { ...state, settingsNotificationList: action.value };

    case 'REFRESH_SETTINGS_WHATSNEW_TABLE':
      return { ...state, settingsWhatsnewTableForceRefresh:!state.settingsWhatsnewTableForceRefresh };    
    case 'SET_SETTINGS_WHATSNEW_LIST':
      return { ...state, settingsWhatsnewList: action.value };

    case 'REFRESH_SETTINGS_LEARNING_TABLE':
      return { ...state, settingsLearningTableForceRefresh:!state.settingsLearningTableForceRefresh };    
    case 'SET_SETTINGS_LEARNING_LIST':
      return { ...state, settingsLearningList: action.value };

    case 'REFRESH_SETTINGS_OEM_TABLE':
      return { ...state, settingsOEMTableForceRefresh:!state.settingsOEMTableForceRefresh };
    case 'REFRESH_SETTINGS_OEM_EDITIONS_TABLE':
      return { ...state, settingsOEMEditionsTableForceRefresh:!state.settingsOEMEditionsTableForceRefresh };
    case 'REFRESH_SETTINGS_OEM_UPGRADES_TABLE':
      return { ...state, settingsOEMUpgradesTableForceRefresh:!state.settingsOEMUpgradesTableForceRefresh };

    case 'REFRESH_SETTINGS_OEM_DISTRIBUTOR_TABLE':
      return { ...state, settingsOEMDistributorTableForceRefresh:!state.settingsOEMDistributorTableForceRefresh };

    
    case 'SET_SETTINGS_OEM_LIST':
      return { ...state, settingsOEMList: action.value };
    case 'SET_SETTINGS_OEM_EDITIONS_LIST':
      return { ...state, settingsOEMEditionsList: action.value };
    case 'SET_SETTINGS_OEM_UPGRADES_LIST':
      return { ...state, settingsOEMUpgradesList: action.value };
    case 'SET_SETTINGS_OEM_PLUGINS_LIST':
      return { ...state, settingsOEMPluginsList: action.value };
    case 'SET_SETTINGS_DISTRIBUTORS_LIST':
      return { ...state, settingsDistributorsList: action.value };

    case 'SET_SETTINGS_OEM_DISTRIBUTOR_LIST':
      return { ...state, settingsOEMDistributorList: action.value };

    case 'SET_EDIT_SOFTWARE_ICON':
      return { ...state, editSoftwareIcon: action.value };
    case 'SET_EDIT_SOFTWARE_LOGO':
      return { ...state, editSoftwareLogo: action.value };    
    case 'SET_EDIT_BACKGROUND_IMAGE':
      return { ...state, editBackgroundImage: action.value };      
    case 'SET_EDIT_TEXT_COLORS':
      return { ...state, editTextColors: action.value };
    case 'SET_EDIT_WIDGETS_COLORS_NORMAL':
      return { ...state, editWidgetsColorsNormal: action.value };
    case 'SET_EDIT_WIDGETS_COLORS_HOVER':
      return { ...state, editWidgetsColorsHover: action.value };
    case 'SET_EDIT_WIDGETS_COLORS_SELECTED':
      return { ...state, editWidgetsColorsSelected: action.value };
    case 'SET_EDIT_WIDGETS_COLORS_DISABLED':
      return { ...state, editWidgetsColorsDisabled: action.value };
    case 'SET_EDIT_MENU_COLORS':
      return { ...state, editMenuColors: action.value };
    case 'SET_EDIT_MENU_BAR_COLORS':
      return { ...state, editMenuBarColors: action.value };
    case 'SET_EDIT_NOTIFICATION_COLORS':
      return { ...state, editNotificationColors: action.value };

    case 'SET_THEME_GENERATED':
      return { ...state, themeGenerated: action.value };
    case 'SET_CLICK_NO_THEME_GENERATED':
      return { ...state, clickNoThemeGenerated: action.value };

    case 'SET_BUILD_SERVER_DOWN':
      return { ...state, buidlServerDown: action.value };
    case 'SET_OEM_HAS_CHANGES':
      return { ...state, OEMHasChanges: action.value }
    case 'SET_THEME_HAS_CHANGES':
      return { ...state, themeHasChanges: action.value }
    case 'SET_BUILD_STARTED':
      return { ...state, buildStarted: action.value };
    case 'SET_SWITCH_BUTTON_EDITOR':
      return { ...state, switchButtonEditor: action.value };
    case 'SET_SWITCH_BUTTON_PREVIEW':
      return { ...state, switchButtonPreview: action.value };
      
    case 'SET_SETTINGS_OEM_THEME_VALUES':
      return { ...state, settingsOEMThemeValues: action.value };
    case 'SET_SETTINGS_OEM_VALUES':
      return { ...state, setSettingsOEMValues: action.value };
    case 'REFRESH_SETTINGS_OEM_EDITOR':
     return { ...state, settingsOEMEditorForceRefresh:!state.settingsOEMEditorForceRefresh }; 
    case 'REFRESH_SETTINGS_OEM_PREVIEW':
      return { ...state, settingsOEMPreviewForceRefresh:!state.settingsOEMPreviewForceRefresh };  
    case 'REFRESH_SETTINGS_OEM_VARIABLES':
      return { ...state, settingsOEMVariablesForceRefresh:!state.settingsOEMVariablesForceRefresh };  

    case 'REFRESH_FILTERS':
      return { ...state, filtersForceRefresh:!state.filtersForceRefresh };   
    case 'SET_FILTERS':
      return { ...state, filterList: action.value };
    case 'SET_FILTER_COUNTER':
      if ( state.filterList !== undefined && state.filterList.items.length > action.value.index )
        return update( state, { filterList: { items: { [action.value.index]: { count: { $set: action.value.count } } } } } );
      return state;

    default:
      return state;
  }
}
