import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { signIn, passwordRecovery, passwordRecoveryProcess } from './LoginRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import {
  Avatar, Button, TextField,
  Link, Grid, Typography, Container, InputAdornment,
  IconButton, Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { LockOutlined, Visibility, VisibilityOff, LockReset } from '@mui/icons-material';

let strings = new LocalizedStrings({
  en: {
    title: 'Sign In',
    ok: 'OK',
    error: "Error",
    success: "Success",
    textFieldEmailAddress: 'Email Address',
    textFieldPassword: 'Password',
    buttonSignIn: 'Sign In',
    linkForgotPassword: 'Forgot password?',

    errorInsertValidEmail: 'Please insert valid email address',
    errorMissingRecoveryKey: 'Recovery key is missing ',
    errorMissingPasswordKey: 'Password is missing ',
    errorMissingPasswordConfirmKey: 'Password confirm is missing ',
    errorPasswordsNotMatch: 'Passwords not match',
    passwordRecoveryError: 'Password recovery error',
    successfullyPasswordRecovery: 'An email was sended with recovery key. Please insert the key received on email on next screen',
    passwordRecoveryWithSuccess: 'Password recovered with success. You can login with new password.',
    recoveryTitle: 'Password Recovery',
    textFieldRecoveryKey: 'Recovery key',
    buttonRecoveryConfirm: 'Confirm',
    buttonRecoveryCancel: 'Cancel',
    textFieldPasswordConfirm: 'Password confirm',
  }
})

function SignIn(props) {

  const { classes } = props;

  const [values, setValues] = useState({
    user: '',
    password: '',
    resetPasswordConfirm: '',
    resetPassword: '',
    showPassword: false,
    showResetPassword: false,
    showResetPasswordConfirm: false
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [recoveryShow, setRecoveryShow] = useState(false);

  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [recoveryKey, setRecoveryKey] = useState('');

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSubmitClick = () => {
    signIn(values.user, values.password);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmitClick();
    }
  };

  const onRecoveryCancelClick = async () => {
    setRecoveryShow(false);
    setRecoveryKey('');
    setValues({ ...values, ['resetPasswordConfirm']: '' });
    setValues({ ...values, ['resetPassword']: '' });
    setValues({ ...values, ['showResetPassword']: false });
    setValues({ ...values, ['showResetPasswordConfirm']: false });
  }

  const onRecoveryClick = async () => {

    setAlertOpen(false);
    if (recoveryKey == '') {
      setAlertTitle(strings.error);
      setAlertMessage(strings.errorMissingRecoveryKey);
      setAlertOpen(true);
      return;
    }

    if (values.resetPassword == '') {
      setAlertTitle(strings.error);
      setAlertMessage(strings.errorMissingPasswordKey);
      setAlertOpen(true);
      return;
    }
    if (values.resetPasswordConfirm == '') {
      setAlertTitle(strings.error);
      setAlertMessage(strings.errorMissingPasswordConfirmKey);
      setAlertOpen(true);
      return;
    }

    if (values.resetPassword !== values.resetPasswordConfirm) {
      setAlertTitle(strings.error);
      setAlertMessage(strings.errorPasswordsNotMatch);
      setAlertOpen(true);
      return;
    }

    const recoverySubmitResult = await passwordRecoveryProcess(values.user, recoveryKey, values.resetPassword);
    if (recoverySubmitResult.status === 'OK') {
      setAlertTitle(strings.success);
      setAlertMessage(strings.passwordRecoveryWithSuccess);
      setAlertOpen(true);
      setRecoveryShow(false);
    } else {
      setAlertTitle(strings.error);
      setAlertMessage(recoverySubmitResult.message);
      setAlertOpen(true);
      setRecoveryShow(true);
    }

  };

  function isValidEmail(email) {
    // Regular expression for a basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const passwordRecoverySubmit = async () => {

    setAlertOpen(false);
    if (values.user == '' || !isValidEmail(values.user)) {
      setAlertTitle(strings.error);
      setAlertMessage(strings.errorInsertValidEmail);
      setAlertOpen(true);
      return;
    }

    const recoveryResult = await passwordRecovery(values.user);
    if (recoveryResult) {
      setAlertTitle(strings.success);
      setAlertMessage(strings.successfullyPasswordRecovery);
      setAlertOpen(true);
      setRecoveryShow(true)
    } else {
      setAlertTitle(strings.error);
      setAlertMessage(strings.passwordRecoveryError);
      setAlertOpen(true);
    }
    return;

  };

  const handleClickShowPassword = (item = '') => {
    if (item === '') {
      setValues({ ...values, showPassword: !values.showPassword });
    } else if (item === 'reset-pass') {
      setValues({ ...values, showResetPassword: !values.showResetPassword });
    } else if (item === 'reset-pass-confirm') {
      setValues({ ...values, showResetPasswordConfirm: !values.showResetPasswordConfirm });
    }
  };

  return (
    <Container maxWidth='xs'>

      {!recoveryShow &&
        <Box className={classes.signInArea}>
          <Avatar className={classes.signInIcon}>
            <LockOutlined />
          </Avatar>
          <Typography component='h1' variant='h5'>{strings.title}</Typography>
          <form className={classes.form} noValidate>

            <TextField variant='outlined' margin='normal' required fullWidth id='email' autoFocus autoComplete='email'
              label={strings.textFieldEmailAddress} value={values.user} onChange={handleChange('user')}
              onKeyDown={handleKeyPress}
              inputProps={{ maxLength: fieldLimits.signIn.user.size }} />

            <TextField variant='outlined' margin='normal' required fullWidth id='password' type={!values.showPassword ? 'password' : 'text'} autoComplete='current-password'
              label={strings.textFieldPassword} value={values.password} onChange={handleChange('password')}
              onKeyDown={handleKeyPress}
              InputProps={{
                inputProps: { maxLength: fieldLimits.signIn.password.size },
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword()}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />

            <Button className={classes.formSubmit} fullWidth variant='contained' color='secondary'
              onClick={() => onSubmitClick()}>{strings.buttonSignIn}</Button>
            <Grid container>
              <Grid item xs>
                <Link onClick={() => passwordRecoverySubmit()} style={{ cursor: 'pointer' }} variant='body2'>{strings.linkForgotPassword}</Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      }

      {recoveryShow &&
        <Box className={classes.signInArea}>
          <Avatar className={classes.signInIcon}>
            <LockReset />
          </Avatar>
          <Typography component='h1' variant='h5'>{strings.recoveryTitle}</Typography>
          <form className={classes.form} noValidate style={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}>

            <TextField variant='outlined' margin='normal' required fullWidth id='recoveryKey' autoFocus
              label={strings.textFieldRecoveryKey}
              value={recoveryKey} onChange={(event) => setRecoveryKey(event.target.value)}
              inputProps={{ maxLength: 10 }} />

            <TextField variant='outlined' margin='normal' required fullWidth id='recovery-password' type={!values.showResetPassword ? 'password' : 'text'}
              label={strings.textFieldPassword} value={values.resetPassword} onChange={handleChange('resetPassword')}
              InputProps={{
                inputProps: { maxLength: fieldLimits.signIn.password.size },
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword('reset-pass')}>
                      {values.showResetPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />

            <TextField variant='outlined' margin='normal' required fullWidth id='recovery-password-confirm' type={!values.showResetPasswordConfirm ? 'password' : 'text'}
              label={strings.textFieldPasswordConfirm} value={values.resetPasswordConfirm} onChange={handleChange('resetPasswordConfirm')}
              InputProps={{
                inputProps: { maxLength: fieldLimits.signIn.password.size },
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword('reset-pass-confirm')}>
                      {values.showResetPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignContent:'start', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Button className={classes.formSubmit} variant='contained' color='error'
                onClick={() => onRecoveryCancelClick()}>{strings.buttonRecoveryCancel}</Button>

              <Button className={classes.formSubmit} variant='contained' color='secondary'
                onClick={() => onRecoveryClick()}>{strings.buttonRecoveryConfirm}</Button>
            </div>

          </form>
        </Box>
      }

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(!alertOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          < Button onClick={() => setAlertOpen(!alertOpen)} autoFocus>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </Container >
  );
}

export default connect(null, {})(withStyles(gStyles)(SignIn));