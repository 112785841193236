import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMUpgrades, setSettingsOEMProducts, deleteSettingsOEMEdition, getSettingsOEMEditions } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Tooltip } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit, AddCircle, Delete } from '@mui/icons-material';
import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';
import Icon from '@material-ui/core/Icon';
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings = new LocalizedStrings({
  en: {
    title: 'OEM Upgrade',
    titleDialog: 'Edit Upgrade',
    titleNewUpgradeDialog: 'New Upgrade',

    tableHeaderCode: 'Product Code',
    tableHeaderBase: 'Product Base',
    tableHeaderPublic: 'Product Public',
    tableHeaderDescription: 'Product Description',
    tableHeaderLink: 'Link',
    tableHeaderPic: 'Picture',

    toolTipEditPicture: 'Click the picture to edit',
    labelCode: 'Product Code',
    labelBase: 'Product Base',
    labelPublic: 'Product Public',
    labelDescription: 'Description',
    labelLink: 'Link',

    buttonAdd: 'Add Upgrade',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit Upgrade',
    toolTipDelete: 'Delete Upgrade',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this upgrade?',
    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMDetailUpgrades(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [newUpgrade, setNewUpgrade] = useState(false);

  const [values, setValues] = useState({});
  const [upgradeToDelete, setUpgradeToDelete] = useState(undefined);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [state, setState] = useState({ hover: false });

  const { settingsOEMUpgradesTableForceRefresh } = props;
  const { settingsOEMUpgradesList } = props;
  const { settingsOEMEditionsList } = props;
  const { oemId } = useParams();
  const fileUploader = useRef(null);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      getSettingsOEMUpgrades(page * rowsPerPage, rowsPerPage, oemId);
      if (settingsOEMEditionsList === undefined) {
        getSettingsOEMEditions(0, 9999, oemId);
      }
    },
    [page, rowsPerPage, settingsOEMUpgradesTableForceRefresh, oemId]
  );
  useEffect(
    () => {
      setValues({ ...values, base: from + '=>' + to });
    },
    [from, to]
  );

  const handleChange = name => event => {

    switch (name) {
      case 'code': {
        setValues({ ...values, code: event.target.value });
        break;
      }
      // case 'base': {
      //   setValues({ ...values, base: event.target.value });
      //   break;
      // } 
      case 'from': {
        setValues({ ...values, from: event.target.value });
        setFrom(event.target.value);
        break;
      }
      case 'to': {
        setValues({ ...values, to: event.target.value });
        setTo(event.target.value);
        break;
      }
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'description': {
        setValues({ ...values, description: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }
      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const handleAdd = () => {
    setNewUpgrade(true);
    setEditMode(true);
    setFrom('');
    setTo('');
    setValues({
      oem_id: oemId,
      oldCode: '',
      code: '',
      base: '',
      title: '',
      description: '',
      from: '',
      to: '',
      picture: '',
      link: ''
    });
  }

  const handleEdit = upgrade => {
    setNewUpgrade(false);
    setEditMode(true);
    let baseArray = upgrade.product_base.split('=>');
    setFrom(baseArray[0]);
    setTo(baseArray[1]);
    setValues({
      oem_id: oemId,
      oldCode: upgrade.product_code,
      code: upgrade.product_code,
      base: upgrade.product_base,
      title: upgrade.product_public,
      description: upgrade.product_description,
      from: baseArray[0],
      to: baseArray[1],
      picture: (upgrade.product_picture === null || upgrade.product_picture.includes('data:image/png;base64') === false) ? '' : upgrade.product_picture,
      link: upgrade.product_link === null ? '' : upgrade.product_link
    });
  };

  const handleDeleteDialogClose = confirm => {
    if (confirm) {
      deleteSettingsOEMEdition(upgradeToDelete);
      setUpgradeToDelete(undefined);
    } else {
      setUpgradeToDelete(undefined);
    }
  }

  const handleDelete = edition => {
    setUpgradeToDelete(edition);
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setEditMode(false);
    setSettingsOEMProducts(values);
  };


  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }
  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAdd()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAdd}</Typography>
          </Button>
        </Box>
        {settingsOEMUpgradesList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderCode}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderBase}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPublic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMUpgradesList.items.map((upgrade, index) => (
                  // <TableRow key={ upgrade.upgradeId }>
                  <TableRow key={index}>
                    <TableCell >{upgrade.product_code}</TableCell>
                    <TableCell >{upgrade.product_base}</TableCell>
                    <TableCell >{upgrade.product_public}</TableCell>
                    <TableCell >{upgrade.product_description}</TableCell>
                    <TableCell style={{ maxWidth: '48px', width: '48px' }}>
                      <Tooltip
                        title={state.hover ? (<img src={(upgrade.product_picture !== undefined && upgrade.product_picture !== null && upgrade.product_picture.includes('data:image/png;base64') ? upgrade.product_picture : backgroundImage)} alt="" style={{ maxWidth: '200px', width: '200px' }} />) : ''} alt=""
                      >
                        <Icon style={{ width: '100%', height: 'auto' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(upgrade.product_picture !== undefined && upgrade.product_picture !== null && upgrade.product_picture.includes('data:image/png;base64') ? upgrade.product_picture : backgroundImage)} width="100%" height="auto" alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell >{upgrade.product_link}</TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(upgrade)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDelete}>
                        <IconButton size='small' onClick={() => handleDelete(upgrade)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMUpgradesList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMUpgradesList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ zIndex: 0, height: '900px' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{newUpgrade ? strings.titleNewUpgradeDialog : strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              <Grid item xs={4}>

                {values.picture === '' &&
                  <Grid item xs={12}>
                    <FormControl variant='outlined'>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                    </FormControl>
                  </Grid>
                }
                {values.picture !== '' &&
                  <Grid item xs={12}>
                    <FormControl variant='outlined' style={{ width: '100%', height: 'auto' }} onClick={handleEditPicClick}>
                      <img src={values.picture} style={{ width: '100%', height: 'auto' }} alt="" />
                      <input type='file' accept='.png' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                      <FormControl><Typography variant='body1' style={{ fontStyle: "italic", fontSize: "13px" }}>{strings.toolTipEditPicture}</Typography></FormControl>
                    </FormControl>

                  </Grid>
                }

              </Grid>

              <Grid item xs={8}>

                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='code'
                      label={strings.labelCode} value={values.code} onChange={handleChange('code')} required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='title'
                      label={strings.labelPublic} value={values.title} onChange={handleChange('title')} required />
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={6}>
                {settingsOEMEditionsList !== undefined &&
                  <FormControl fullWidth variant='outlined' margin='dense'>
                    <InputLabel id="from-select-label">{strings.labelBase}</InputLabel>
                    <Select
                      labelId="from-select-label"
                      id="from"
                      value={values.from !== undefined ? values.from : ''}
                      label={strings.labelBase}
                      onChange={handleChange('from')}
                      required
                    >
                      {settingsOEMEditionsList.items.map((edition, index) => (
                        <MenuItem key={index} value={edition.product_code}>{edition.product_public} ({edition.product_code})</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </Grid>

              <Grid item xs={1}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant='h5' style={{ alignSelf: 'center' }} >{'=>'}</Typography>
                </div>
              </Grid>

              <Grid item xs={5}>
                {settingsOEMEditionsList !== undefined &&
                  <FormControl fullWidth variant='outlined' margin='dense'>
                    <InputLabel id="to-select-label">{strings.labelCode}</InputLabel>
                    <Select
                      labelId="to-select-label"
                      id="to"
                      value={to !== undefined ? to : ''}
                      label={strings.labelCode}
                      onChange={handleChange('to')}
                      required
                    >
                      {settingsOEMEditionsList.items.map((edition, index) => (
                        <MenuItem key={index} value={edition.product_code}>{edition.product_public} ({edition.product_code})</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </Grid>

              {from !== '' && to !== '' &&
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="caption" display="block" >{values.base} </Typography>
                </Grid>
              }

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description' multiline maxRows={4} inputProps={{ maxLength: 255 }}
                  label={strings.labelDescription} value={values.description} onChange={handleChange('description')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>
      <Dialog open={upgradeToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMUpgradesTableForceRefresh: state.settingsOEMUpgradesTableForceRefresh,
    settingsOEMUpgradesList: state.settingsOEMUpgradesList,
    settingsOEMEditionsList: state.settingsOEMEditionsList
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMDetailUpgrades));