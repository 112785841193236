import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Paper, Box, Grid, TextField, Typography } from '@mui/material';


let strings = new LocalizedStrings({
  en: {
    title: 'Variables',

    labelExeName: 'App Exe Name',
    labelShort: 'App Short',
    labelName: 'App Name',
    labelSite: 'Software Website',
    labelCopyright: 'Copyright',
    labelCompany: 'Company Name',
    labelPhone: 'Phone',
    labelEmail: 'Company Email',
    labelCompanySite: 'Company Website',

  }
})


function SettingsOEMVariables(props) {

  const { classes } = props;
  const { settingsOEMVariablesForceRefresh } = props;
  const { settingsOEMThemeValues } = props;

  const [values, setValues] = useState({ valid: false, oem_id: 0, data: {} });

  // This state is used do hide/show mui TextFields
  // When TextFields is showed before state data is filled the label is over text and this state prevent that
  const [statesLoaded, setStatesLoaded] = useState(false);

  const dispatch = useDispatch();


  useEffect(
    () => {
      //getSettingsOEMTheme( oemId );
      
      // if (settingsOEMThemeValues !== undefined && !values.valid && settingsOEMThemeValues?.data?.variable_app_short !== undefined) {
      if (settingsOEMThemeValues !== undefined && !values.valid && settingsOEMThemeValues?.data !== undefined) {
        setValues({
          valid: true,
          oem_id: settingsOEMThemeValues.productfamily_id,
          data: {
            variable_app_short: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_app_short,
            variable_exe_name: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_exe_name,
            variable_app_name: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_app_name,
            variable_software_website: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_software_website,
            variable_copyright: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_copyright,
            variable_company_name: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_company_name,
            variable_phone: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_phone,
            variable_company_email: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_company_email,
            variable_company_website: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues?.data?.variable_company_website,
          },
        });
        setStatesLoaded(true);
      }
    },
    [settingsOEMVariablesForceRefresh, settingsOEMThemeValues]
  );

  useEffect(
    () => {

    },
    []
  );



  const handleChange = name => event => {

    let newValue = event.target.value;

    if (name === "variable_exe_name") {
      newValue = newValue.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
    }

    setValues({ ...values, data: { ...values.data, [name]: newValue } });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, data: { ...settingsOEMThemeValues.data, [name]: newValue } } });
    dispatch({ type: 'SET_OEM_HAS_CHANGES', value: true });
  };

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <form className={classes.form} autoComplete="off">
            {statesLoaded &&
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='name'
                    variant="outlined"
                    label={strings.labelName}
                    value={values.data.variable_app_name}
                    onChange={handleChange('variable_app_name')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='short'
                    label={strings.labelShort} value={values.data.variable_app_short} onChange={handleChange('variable_app_short')}
                    inputProps={{ maxLength: 8 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='exe'
                    label={strings.labelExeName} value={values.data.variable_exe_name} onChange={handleChange('variable_exe_name')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='site'
                    label={strings.labelSite} value={values.data.variable_software_website} onChange={handleChange('variable_software_website')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>


                <Grid item xs={6}>
                  <TextField required fullWidth id='copyright'
                    label={strings.labelCopyright} value={values.data.variable_copyright} onChange={handleChange('variable_copyright')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='company'
                    label={strings.labelCompany} value={values.data.variable_company_name} onChange={handleChange('variable_company_name')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='phone'
                    label={strings.labelPhone} value={values.data.variable_phone} onChange={handleChange('variable_phone')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='email'
                    label={strings.labelEmail} value={values.data.variable_company_email} onChange={handleChange('variable_company_email')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

                <Grid item xs={6}>
                  <TextField required fullWidth id='companySite'
                    label={strings.labelCompanySite} value={values.data.variable_company_website} onChange={handleChange('variable_company_website')}
                    inputProps={{ maxLength: 50 }} />
                </Grid>

              </Grid>
            }
          </form>
        </Box>
      </Paper>
    </Box>

  );
}

const mapStateToProps = state => {
  return {
    settingsOEMVariablesForceRefresh: state.settingsOEMVariablesForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMVariables));