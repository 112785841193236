import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getLicenseEditions } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Paper, Typography, CircularProgress } from '@mui/material';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

let strings = new LocalizedStrings({
  en: {
    title: 'Licenses by Editions',
  }
})

function LicenseByEdition(props) {

  const { classes } = props;
  const { licenseEditionList } = props;

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(
    () => {
      getLicenseEditions();
    },
    []
  );

  const options = {
    legend: {
      display: false,
      position: "right"
    },
    plugins: {
      legend: {
        position: 'right',
      },
    },
    responsive: true, 
    maintainAspectRatio: false
  };

  return (
    <Paper className={classes.workareaTopBoxes}>
      <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
      {licenseEditionList !== undefined &&
        <div className={classes.piechart}>
          <Pie data={licenseEditionList}
            options={options}            
          />
        </div>
      }
      {licenseEditionList === undefined &&
        <CircularProgress className={classes.spinner} />
      }
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    licenseEditionList: state.licenseEditionList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(LicenseByEdition));