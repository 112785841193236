import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMUpgrades, setDistributorOEMProducts } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';
import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Tooltip, Dialog, FormControl, FormLabel } from '@mui/material';
import { Edit } from '@mui/icons-material';
import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';
import Icon from '@material-ui/core/Icon';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings = new LocalizedStrings({
  en: {
    title: 'Upgrades',
    titleDialog: 'Upgrades',

    tableHeaderProductCode: 'Code',
    tableHeaderProductPublic: 'My Code',
    tableHeaderProductDescription: 'Description',
    tableHeaderDetailedDescription: 'Detailed Description',
    tableHeaderLink: 'Link',
    tableHeaderPic: 'Picture',
    toolTipEditPicture: 'Click the picture to edit',

    labelProductCode: 'Code',
    labelProductPublic: 'My Code',
    labelProductDescription: 'Description',
    labelDetailedDescription: 'Detailed Description',
    labelLink: 'Link',
    labelPicture: 'Picture',

    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit the Upgrade',
    toolTipDelete: 'Delete the Upgrade',

    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMUpgrades(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});

  const { settingsOEMUpgradesTableForceRefresh } = props;
  const { settingsOEMUpgradesList } = props;
  const [state, setState] = useState({ hover: false });

  const { oemId } = useParams();
  const fileUploader = useRef(null);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      getSettingsOEMUpgrades(page * rowsPerPage, rowsPerPage, oemId);
    },
    [page, rowsPerPage, settingsOEMUpgradesTableForceRefresh, oemId]
  );

  const handleChange = name => event => {

    switch (name) {
      case 'description': {
        setValues({ ...values, description: event.target.value });
        break;
      }
      case 'detailedDescription': {
        setValues({ ...values, descriptionDetail: event.target.value });
        break;
      }
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }

      default:
        break;
    }
  };

  const handleEdit = upgrade => {
    setEditMode(true);
    setValues({
      oem_id: oemId,
      oldCode: upgrade.product_code,
      code: upgrade.product_code,
      title: upgrade.product_public === null ? '' : upgrade.product_public,
      description: upgrade.product_description === null ? '' : upgrade.product_description,
      descriptionDetail: upgrade.product_description_detail === null ? '' : upgrade.product_description_detail,
      picture: upgrade.product_picture === null ? '' : upgrade.product_picture,
      link: upgrade.product_link === null ? '' : upgrade.product_link
    });
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setEditMode(false);
    setDistributorOEMProducts(values);
  };

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }
  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
        </Box>
        {settingsOEMUpgradesList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderProductCode}123123</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderProductPublic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderProductDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDetailedDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMUpgradesList.items.map((upgrade) => (
                  <TableRow key={upgrade.product_code}>
                    <TableCell>{upgrade.product_code}</TableCell>
                    <TableCell>{upgrade.product_public}</TableCell>
                    <TableCell>{upgrade.product_description}</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-line' }}>{upgrade.product_description_detail}</TableCell>
                    <TableCell style={{ maxWidth: '48px', width: '48px' }}>
                      <Tooltip
                        title={state.hover ? (<img src={(upgrade.product_picture !== null ? upgrade.product_picture : backgroundImage)} alt="" style={{ maxWidth: '200px', width: '200px' }} />) : ''} alt=""
                      >
                        <Icon style={{ width: '100%', height: 'auto' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(upgrade.product_picture !== null ? upgrade.product_picture : backgroundImage)} width="100%" height="auto" alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell >{upgrade.product_link}</TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(upgrade)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMUpgradesList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMUpgradesList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ height: '100%' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              {values.picture === '' &&
                <Grid item xs={4}>
                  <FormControl variant='outlined' required>
                    <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                    <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                  </FormControl>
                </Grid>
              }

              {values.picture !== '' &&
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={11}>
                      <FormControl variant='outlined' required onClick={handleEditPicClick}>
                        <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                        <img src={values.picture} width={'100%'} alt="" />
                        <input type='file' accept='.png' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton style={{ marginLeft: '-100px', marginTop: '-5px' }} size='small' onClick={() => handleEditPicClick()} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              }


              <Grid item xs={8}>

                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='name' disabled
                      label={strings.labelProductCode} value={values.code} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='title'
                      label={strings.labelProductPublic} value={values.title} onChange={handleChange('title')} />
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' multiline maxRows={4} margin='dense' id='description' inputProps={{ maxLength: 255 }}
                  label={strings.labelProductDescription} value={values.description} onChange={handleChange('description')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' multiline maxRows={4} margin='dense' id='detailedDescription' inputProps={{ maxLength: 500 }}
                  label={strings.labelDetailedDescription} value={values.descriptionDetail} onChange={handleChange('detailedDescription')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMUpgradesTableForceRefresh: state.settingsOEMUpgradesTableForceRefresh,
    settingsOEMUpgradesList: state.settingsOEMUpgradesList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMUpgrades));