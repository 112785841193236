import React, { useEffect, useState }  from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getCustomerLicenses, deleteCustomerLicense, addCustomerLicenses } from './CustomerRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { CircularProgress, Tooltip, IconButton, Button, Typography, Box } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Delete, AddCircle } from '@mui/icons-material';

import LicenseTable from '../license/LicenseTable';
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    tableHeaderLicense:'License',
    tableHeaderEdition:'Edition',
    tableHeaderSoftware:'Software',

    toolTipDelete:'Removes the license',

    buttonAdd:'Add more licenses',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    deleteDialogTitle: 'Remove Confirmation',
    deleteDialogText: 'Are you sure that you want to remove license \'{entity}\' from this customer?',
    yes: 'Yes',
    no: 'No',
	}
});

function CustomerLicenseTable( props ) { 

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { userType } = props;
  const { customerLicenseList, customerLicenseTableForceRefresh } = props;
  const { licenseAssignList } = props;
  const { customerId } = useParams();

  const { customerSelected } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 10 : urlRowsPerPage

  useEffect(
    ( ) => { 
      getCustomerLicenses( customerId, page * rowsPerPage, rowsPerPage );
   },
   [ customerId, page, rowsPerPage, customerLicenseTableForceRefresh ]
  );

  const handleAdd = () => {
    setEditMode( true );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleConfirm = ( ) => {
    addCustomerLicenses( customerId, licenseAssignList );
    setEditMode( false );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteCustomerLicense( customerId, entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = license => {
    setEntityToDelete( license );
  };

  return (
    <div>
      <Box>
        { customerSelected !== undefined &&
          <div className={ classes.workarea }>
            <div className={ classes.flexHeader }>
              { userType !== 0 && customerSelected.is_direct_customer === 1 && 
                <Button variant="contained" color="secondary" onClick={ ( ) => handleAdd( ) }>
                  <AddCircle className={ classes.leftIcon } />
                  { strings.buttonAdd }
                </Button>
              }
            </div>
            <div className={ classes.tableArea } >
              { customerLicenseList !== undefined &&
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                    <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderLicense }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderEdition }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>{ strings.tableHeaderSoftware }</Typography>
                      </TableCell>                  
                      { userType !== 0 && <TableCell/> }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { customerLicenseList.items.map( license => (
                      <TableRow key={ license.license }>
                        <TableCell>
                          <Link className={ classes.link } to={ '/licenses/' + license.license + '/details' }>
                            <Typography variant='body2'>{ license.license }</Typography>
                          </Link>  
                        </TableCell>
                        <TableCell>
                          <Typography variant='body2'>{ license.pcode }</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='body2'>{ license.softwareVersion }</Typography>
                        </TableCell>
                        { userType !== 0 &&
                          <TableCell className={ classes.tableButton } >
                            <Tooltip title={ strings.toolTipDelete }>
                              <IconButton size='small' onClick={ ( ) => handleDelete( license ) } >
                                <Delete/>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        }
                      </TableRow>
                    )) }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 4 } count={ customerLicenseList.count } rowsPerPage={ rowsPerPage } page={ page }
                        SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                        onPageChange={ ( event, newPage ) => handleChangePage( history, newPage )  }
                        onRowsPerPageChange={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                        ActionsComponent={ TablePaginationActions }/>
                    </TableRow>
                  </TableFooter>
                </Table>
              }
              { customerLicenseList === undefined &&
                  <CircularProgress className={ classes.spinner }/>
              }
            </div>
          </div>
        }
        { customerSelected === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Box>
      <Dialog maxWidth='lg' open={ editMode }>
        <div className={ classes.workarea }>
          <LicenseTable assignMode={ true }/>
          <div className={ classes.lineButtonBox }>
            <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleConfirm() }>{ strings.buttonConfirm }</Button>
            <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.license : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    customerLicenseList: state.customerLicenseList,
    customerLicenseTableForceRefresh: state.customerLicenseTableForceRefresh,
    licenseAssignList: state.licenseAssignList,
    customerSelected: state.customerSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( CustomerLicenseTable ) );