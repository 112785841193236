import React, { useState, useRef, useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { connect, useDispatch } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Grid, Box, FormControl, FormLabel, Typography, Button, CircularProgress, Divider, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Tooltip, IconButton } from '@mui/material';
import { SketchPicker } from 'react-color';
import { Delete, Edit } from '@mui/icons-material';

import HomeIcon from '@mui/icons-material/Home';
import DropzonePic from '../components/DropzonePic';

//NOT GOOD, NEED FIX
import ReactCrop from 'react-image-crop';


let strings = new LocalizedStrings({
  en: {
    title: 'MY OEM CUSTOMIZATION',

    softwareLogo: 'Software Logo',
    softwareIcon: 'Software Icon',
    backgroundImage: 'Background Image',

    labelBackgroundColor: 'Background Colors',
    labelPickColor: 'Select color',
    labelCropImage: 'Crop Image',
    labelTextColor: 'Text Colors',
    labelDefault: 'Default',
    labelPrimary: 'Primary',
    labelSecondary: 'Secondary',
    labelDisabled: 'Disabled',
    labelSuccess: 'Success',
    labelError: 'Error',
    labelWarning: 'Warning',
    labelInfo: 'Info',
    labelButtonStates: 'Buttons/Widgets (States)',
    labelNormalFill: 'Normal - Fill:',
    labelNormalOutline: 'Normal - Outline:',
    labelNormalIcon: 'Normal - Icon Color:',
    labelNormalText: 'Normal - Text Color:',  
    labelSelectedFill: 'Selected - Fill:',
    labelSelectedOutline: 'Selected - Outline:',
    labelSelectedIcon: 'Selected - Icon Color:',
    labelSelectedText: 'Selected - Text Color:',
      
    labelHoverFill: 'Hover - Fill:',
    labelHoverOutline: 'Hover - Outline:',
    labelHoverIcon: 'Hover - Icon Color:',
    labelHoverdText: 'Hover - Text Color:',
    
    labelDisabledFill: 'Disabled - Fill:',
    labelDisabledOutline: 'Disabled - Outline:',
    labelDisabledIcon: 'Disabled - Icon Color:',
    labelDisabledText: 'Disabled - Text Color:',
    labelMenuBar: 'MenuBar Colors',
    labelMenu: 'Menu Colors',
    labelNotifications: 'Notifications Colors',
    labelPopupFill: 'Popup - Fill:',
    labelPopupOutline: 'Popup - Outline:',
    labelPopupTitle: 'Popup - Title:',
    labelPopupText: 'Popup - Text:',

    toolTipEdit: 'Edit Picture',
    toolTipDelete: 'Delete Picture',

    confirm: 'Confirm',

  }
})


function SettingsOEMEditor(props) {

  const { classes } = props;
  const { settingsOEMEditorForceRefresh } = props;
  const { settingsOEMThemeValues } = props;


  const [values, setValues] = useState({ valid: false, oem_id: 0, logo: '', icon: '', background: '', data: {} });
  const [colorToEdit, setColorToEdit] = useState({ value: '', color: '' });

  const dispatch = useDispatch();
  const fileUploader = useRef(null);
  const fileUploader1 = useRef(null);
  const fileUploader2 = useRef(null);

  const [imageCrop, setImageCrop] = useState({ icon: '', logo: '' });
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  useEffect(
    () => {
      setValues({ ...values, oem_id: 0, valid: false });
    },
    [settingsOEMEditorForceRefresh, settingsOEMThemeValues]
  );

  if (settingsOEMThemeValues !== undefined && !values.valid) {
    if (settingsOEMThemeValues.productfamily_id !== values.oem_id)
      setValues({
        valid: true,
        oem_id: settingsOEMThemeValues.productfamily_id,
        logo: (settingsOEMThemeValues.logo === null) ? '' : settingsOEMThemeValues.logo,
        icon: (settingsOEMThemeValues.icon === null) ? '' : settingsOEMThemeValues.icon,
        background: (settingsOEMThemeValues.background === null) ? '' : settingsOEMThemeValues.background,
        data: {
          background_color_default: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.background_color_default,
          background_color_primary: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.background_color_primary,
          background_color_secondary: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.background_color_secondary,
          text_color_default: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_default,
          text_color_primary: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_primary,
          text_color_secondary: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_secondary,
          text_color_disabled: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_disabled,
          text_color_success: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_success,
          text_color_error: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_error,
          text_color_warning: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_warning,
          text_color_info: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.text_color_info,
          button_normal_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_normal_fill,
          button_normal_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_normal_outline,
          button_normal_icon: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_normal_icon,
          button_normal_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_normal_text,
          button_selected_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_selected_outline,
          button_selected_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_selected_fill,
          button_selected_icon: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_selected_icon,
          button_selected_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_selected_text,
          
          button_hover_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_fill,
          button_hover_icon: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_icon,
          button_hover_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_hover_text,

          button_disabled_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_disabled_fill,
          button_disabled_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_normal_outline,
          button_disabled_icon: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_disabled_icon,
          button_disabled_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.button_disabled_text,
          menu_bar_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_bar_fill,
          menu_bar_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_bar_outline,
          menu_bar_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_bar_text,
          menu_normal_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_normal_fill,
          menu_normal_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_normal_outline,
          menu_normal_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_normal_text,
          menu_over_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_over_fill,
          menu_over_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_over_outline,
          menu_over_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_over_text,
          menu_selected_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_selected_fill,
          menu_selected_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_selected_outline,
          menu_selected_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_selected_text,
          menu_disabled_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_disabled_fill,
          menu_disabled_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_disabled_outline,
          menu_disabled_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.menu_disabled_text,
          notification_popup_fill: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.notification_popup_fill,
          notification_popup_outline: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.notification_popup_outline,
          notification_popup_title: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.notification_popup_title,
          notification_popup_text: (settingsOEMThemeValues.data === null) ? undefined : settingsOEMThemeValues.data.notification_popup_text,

        },
      });
  }



  const startNewFile = (file, name) => {
    var reader = new FileReader();
    switch (name) {
      case 'logo': {
        reader.onload = () => {
          setValues({ ...values, logo: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, logo: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'icon': {
        reader.onload = () => {
          setValues({ ...values, icon: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, icon: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'background': {
        reader.onload = () => {
          setValues({ ...values, background: reader.result });
          dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: reader.result } });
          dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
        };
        reader.readAsDataURL(file);
        break;
      }

      default:
        break;
    }

  }

  const handleEditIconClick = () => {
    fileUploader.current.click();
  }

  const handleEditLogoClick = () => {
    fileUploader1.current.click();
  }

  const handleEditBackgroundClick = () => {
    fileUploader2.current.click();
  }

  const onIconEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, icon: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, icon: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const onLogoEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, logo: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, logo: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const onBackgroundEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, background: reader.result });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: reader.result } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const onBackgroundDelete = () => {
    setValues({ ...values, background: '' });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, background: '' } });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  }

  const handleChangeColor = name => event => {
    if (event.target !== undefined){
      setValues({ ...values, data: { ...values.data, [name]: event.target.value } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    }else {
      setValues({ ...values, data: { ...values.data, [name]: event.hex } });
      setColorToEdit({ ...colorToEdit, value: event.hex });
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, data: { ...settingsOEMThemeValues.data, [name]: event.hex } } });
      dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
    }
  };

  const handleBlur = name => event => {
    setValues({ ...values, data: { ...values.data, [name]: event.target.value } });
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues, data: { ...settingsOEMThemeValues.data, [name]: event.target.value } } });
    dispatch({ type: 'SET_THEME_HAS_CHANGES', value: true });
  };

  const handleEditColor = (value, color) => {
    setColorToEdit({ value: value, color: color });
  }

  const handleEditColorClose = confirm => {
    setColorToEdit({ value: '', color: '' });
  }

  return (
    <Box style={{ minHeight: '100%', overflow: 'auto' }}>
      {settingsOEMThemeValues !== undefined &&
        <Box className={classes.workarea}>
          <form className={classes.form} autoComplete="off">
            <Grid style={{ overflow: 'auto' }} className={classes.workareaEditor} container spacing={2} >
              <Grid item xs={12} container spacing={2} >
                <Box style={{ minWidth: '350px' }} id="color-settings-holder">
                  <Grid container spacing={2}  >

                    {/* { values.icon === '' &&
                        <Grid item xs={12}>
                          <FormControl variant='outlined'>
                          <FormLabel><Typography color='secondary'>{ strings.softwareIcon }</Typography></FormLabel>
                            <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'icon' ) } } />
                          </FormControl>
                        </Grid>
                      }


                      { values.icon !== '' &&
                        <Grid item xs={12}>
                          <FormControl variant='outlined' onClick={ handleEditIconClick } style={{ borderColor: '#CCCCCC'}}>
                            <FormLabel><Typography color='secondary'>{ strings.softwareIcon }</Typography></FormLabel>
                              <Box height={ 160 } border={1} borderColor={'#CCCCCC'}>
                                <img src={values.icon} height={ 160 } width={ 160 } alt=""/>
                                <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ ( file ) => { onIconEdit( file ) }}/>
                              </Box>
                          </FormControl>
                          <Tooltip title={ strings.toolTipEdit }>
                            <IconButton size='small' onClick={ () => handleEditIconClick() } >
                              <Edit/>
                            </IconButton>  
                          </Tooltip>
                        </Grid>
                      }

                      

                      { values.logo === '' &&
                      <Grid item xs={6}>
                        <FormControl variant='outlined'>
                        <FormLabel><Typography color='secondary'>{ strings.softwareLogo }</Typography></FormLabel>
                          <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'logo' ) } } />
                        </FormControl>
                      </Grid>
                      }


                      { values.logo !== '' &&
                        <Grid container item xs={6}>
                          <Grid item xs={9}>
                            <FormControl variant='outlined' onClick={ handleEditLogoClick }>
                              <FormLabel><Typography color='secondary'>{ strings.softwareLogo }</Typography></FormLabel>
                                <Box>
                                  <img style={{ border: '1px solid #CCCCCC' }} src={values.logo}  width={ 160 } alt=""/>
                                  <input type='file' accept='.png'  ref= { fileUploader1 } style={{display: "none"}} onChange={ ( file ) => { onLogoEdit( file ) }}/>
                                </Box>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <Tooltip title={ strings.toolTipEdit }>
                              <IconButton style={{ padding: '0px'}} size='small' onClick={ () => handleEditLogoClick() } >
                                <Edit/>
                              </IconButton>  
                            </Tooltip>
                          </Grid>
                        </Grid>
                      }

                      { values.background === '' &&
                        <Grid item  style={{ paddingLeft: '0px' }} xs={6}>
                          <FormControl variant='outlined'>
                          <FormLabel><Typography color='secondary'>{ strings.backgroundImage }</Typography></FormLabel>
                            <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'background' ) } } />
                          </FormControl>
                        </Grid>
                      }


                      { values.background !== '' &&
                        <Grid item container style={{ paddingLeft: '0px' }} xs={6}>
                          <Grid item xs={9}>
                            <FormControl variant='outlined' onClick={ handleEditBackgroundClick }>
                              <FormLabel><Typography color='secondary'>{ strings.backgroundImage }</Typography></FormLabel>
                                <Box>
                                  <img style={{ border: '1px solid #CCCCCC' }} src={values.background} width={ 160 } alt=""/>
                                  <input type='file' accept='.png'  ref= { fileUploader2 } style={{display: "none"}} onChange={ ( file ) => { onBackgroundEdit( file ) }}/>
                                </Box>
                            </FormControl>
                          </Grid>
                          <Grid item container xs={3}>
                            <Grid item xs={6}>
                              <Tooltip title={ strings.toolTipDelete }>
                                <IconButton style={{ paddingLeft: '0px', paddingTop: '0px' }} size='small' onClick={ () => onBackgroundDelete() } >
                                  <Delete/>
                                </IconButton>  
                              </Tooltip>  
                            </Grid>                
                            <Grid item xs={6}>
                              <Tooltip title={ strings.toolTipEdit }>
                                <IconButton style={{ paddingLeft: '0px', paddingTop: '0px' }} size='small' onClick={ () => handleEditBackgroundClick() } >
                                  <Edit/>
                                </IconButton>  
                              </Tooltip>
                            </Grid>                
                          </Grid>                
                        </Grid>
                      } */}

                    <Divider className={classes.dividersEditor} id="labelBackgroundColor" />

                    <Grid item xs={12}>
                      <Typography color='secondary'>{strings.labelBackgroundColor}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDefault}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.background_color_default} onChange={handleChangeColor('background_color_default')} onBlur={handleBlur('background_color_default')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.background_color_default }} onClick={() => handleEditColor(values.data.background_color_default, 'background_color_default')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelPrimary}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.background_color_primary} onChange={handleChangeColor('background_color_primary')} onBlur={handleBlur('background_color_primary')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.background_color_primary }} onClick={() => handleEditColor(values.data.background_color_primary, 'background_color_primary')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Divider className={classes.dividersEditor} id="labelTextColor" />

                    <Grid item xs={12}>
                      <Typography color='secondary'>{strings.labelTextColor}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDefault}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.text_color_default} onChange={handleChangeColor('text_color_default')} onBlur={handleBlur('text_color_default')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_default }} onClick={() => handleEditColor(values.data.text_color_default, 'text_color_default')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelPrimary}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.text_color_primary} onChange={handleChangeColor('text_color_primary')} onBlur={handleBlur('text_color_primary')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_primary }} onClick={() => handleEditColor(values.data.text_color_primary, 'text_color_primary')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabled}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.text_color_disabled} onChange={handleChangeColor('text_color_disabled')} onBlur={handleBlur('text_color_disabled')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_disabled }} onClick={() => handleEditColor(values.data.text_color_disabled, 'text_color_disabled')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider className={classes.dividersEditor} id="labelButtonStates" />

                    <Grid item xs={12}>
                      <Typography color='secondary'>{strings.labelButtonStates}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2} >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_normal_fill} onChange={handleChangeColor('button_normal_fill')} onBlur={handleBlur('button_normal_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_fill }} onClick={() => handleEditColor(values.data.button_normal_fill, 'button_normal_fill')} variant='contained' />
                        </Grid>

                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalIcon}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_normal_icon} onChange={handleChangeColor('button_normal_icon')} onBlur={handleBlur('button_normal_icon')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_icon }} onClick={() => handleEditColor(values.data.button_normal_icon, 'button_normal_icon')} variant='contained' />
                        </Grid>

                      </Grid>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_normal_text} onChange={handleChangeColor('button_normal_text')} onBlur={handleBlur('button_normal_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_text }} onClick={() => handleEditColor(values.data.button_normal_text, 'button_normal_text')} variant='contained' />
                        </Grid>

                      </Grid>
                      <Grid item xs={12}>
                        <Button style={{ backgroundColor: values.data.button_normal_fill, color: values.data.button_normal_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_normal_icon }} />}>
                          {'text'}
                        </Button>
                      </Grid>

                      {/* ******** Hover init ******** */}

                      <Divider className={classes.dividersEditor} id="labelHoverFill" />
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelHoverFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_hover_fill} onChange={handleChangeColor('button_hover_fill')} onBlur={handleBlur('button_hover_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_fill }} onClick={() => handleEditColor(values.data.button_hover_fill, 'button_hover_fill')} variant='contained' />
                        </Grid>
                      </Grid>
                      
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelHoverIcon}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_hover_icon} onChange={handleChangeColor('button_hover_icon')} onBlur={handleBlur('button_hover_icon')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_icon }} onClick={() => handleEditColor(values.data.button_hover_icon, 'button_hover_icon')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelHoverdText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_hover_text} onChange={handleChangeColor('button_hover_text')} onBlur={handleBlur('button_hover_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_hover_text }} onClick={() => handleEditColor(values.data.button_hover_text, 'button_hover_text')} variant='contained' />
                        </Grid>
                      </Grid>

                      {/* ******** Hover END ******** */}


                      <Divider className={classes.dividersEditor} id="labelSelectedFill" />

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_selected_fill} onChange={handleChangeColor('button_selected_fill')} onBlur={handleBlur('button_selected_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_fill }} onClick={() => handleEditColor(values.data.button_selected_fill, 'button_selected_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedIcon}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_selected_icon} onChange={handleChangeColor('button_selected_icon')} onBlur={handleBlur('button_selected_icon')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_icon }} onClick={() => handleEditColor(values.data.button_selected_icon, 'button_selected_icon')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_selected_text} onChange={handleChangeColor('button_selected_text')} onBlur={handleBlur('button_selected_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_text }} onClick={() => handleEditColor(values.data.button_selected_text, 'button_selected_text')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Button style={{ backgroundColor: values.data.button_selected_fill, color: values.data.button_selected_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_selected_icon }} />}>
                          {'text'}
                        </Button>
                      </Grid>

                      <Divider className={classes.dividersEditor} id="labelDisabledFill" />

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_disabled_fill} onChange={handleChangeColor('button_disabled_fill')} onBlur={handleBlur('button_disabled_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_fill }} onClick={() => handleEditColor(values.data.button_disabled_fill, 'button_disabled_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledIcon}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_disabled_icon} onChange={handleChangeColor('button_disabled_icon')} onBlur={handleBlur('button_disabled_icon')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_icon }} onClick={() => handleEditColor(values.data.button_disabled_icon, 'button_disabled_icon')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.button_disabled_text} onChange={handleChangeColor('button_disabled_text')} onBlur={handleBlur('button_disabled_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_text }} onClick={() => handleEditColor(values.data.button_disabled_text, 'button_disabled_text')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Button style={{ backgroundColor: values.data.button_disabled_fill, color: values.data.button_disabled_text }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_disabled_icon }} />}>
                          {'text'}
                        </Button>
                      </Grid>

                    </Grid>

                    <Divider className={classes.dividersEditor} id="labelMenuBar" />

                    <Grid item xs={12}>
                      <Typography fontSize={'14px'} color='secondary'>{strings.labelMenuBar}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_bar_fill} onChange={handleChangeColor('menu_bar_fill')} onBlur={handleBlur('menu_bar_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_bar_fill }} onClick={() => handleEditColor(values.data.menu_bar_fill, 'menu_bar_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_bar_text} onChange={handleChangeColor('menu_bar_text')} onBlur={handleBlur('menu_bar_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_bar_text }} onClick={() => handleEditColor(values.data.menu_bar_text, 'menu_bar_text')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider className={classes.dividersEditor} />

                    <Grid item xs={12}>
                      <Typography color='secondary'>{strings.labelMenu}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_normal_fill} onChange={handleChangeColor('menu_normal_fill')} onBlur={handleBlur('menu_normal_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_normal_fill }} onClick={() => handleEditColor(values.data.menu_normal_fill, 'menu_normal_fill')} variant='contained' />
                        </Grid>
                      </Grid>


                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelNormalText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_normal_text} onChange={handleChangeColor('menu_normal_text')} onBlur={handleBlur('menu_normal_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_normal_text }} onClick={() => handleEditColor(values.data.menu_normal_text, 'menu_normal_text')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_selected_fill} onChange={handleChangeColor('menu_selected_fill')} onBlur={handleBlur('menu_selected_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_selected_fill }} onClick={() => handleEditColor(values.data.menu_selected_fill, 'menu_selected_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelSelectedText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_selected_text} onChange={handleChangeColor('menu_selected_text')} onBlur={handleBlur('menu_selected_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_selected_text }} onClick={() => handleEditColor(values.data.menu_selected_text, 'menu_selected_text')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_disabled_fill} onChange={handleChangeColor('menu_disabled_fill')} onBlur={handleBlur('menu_disabled_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_disabled_fill }} onClick={() => handleEditColor(values.data.menu_disabled_fill, 'menu_disabled_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelDisabledText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.menu_disabled_text} onChange={handleChangeColor('menu_disabled_text')} onBlur={handleBlur('menu_disabled_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.menu_disabled_text }} onClick={() => handleEditColor(values.data.menu_disabled_text, 'menu_disabled_text')} variant='contained' />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider className={classes.dividersEditor} />

                    <Grid item xs={12}>
                      <Typography color='secondary'>{strings.labelNotifications}</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={2}  >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelPopupFill}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.notification_popup_fill} onChange={handleChangeColor('notification_popup_fill')} onBlur={handleBlur('notification_popup_fill')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_fill }} onClick={() => handleEditColor(values.data.notification_popup_fill, 'notification_popup_fill')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelPopupTitle}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.notification_popup_title} onChange={handleChangeColor('notification_popup_title')} onBlur={handleBlur('notification_popup_title')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_title }} onClick={() => handleEditColor(values.data.notification_popup_title, 'notification_popup_title')} variant='contained' />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography fontSize={'14px'} color='primary'>{strings.labelPopupText}</Typography>
                          <TextField className={classes.lineEditor} value={values.data.notification_popup_text} onChange={handleChangeColor('notification_popup_text')} onBlur={handleBlur('notification_popup_text')} variant="standard" />
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.notification_popup_text }} onClick={() => handleEditColor(values.data.notification_popup_text, 'notification_popup_text')} variant='contained' />
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      }
      {settingsOEMThemeValues === undefined &&
        <CircularProgress />
      }

      <Dialog open={colorToEdit.color !== ''} onClose={() => handleEditColorClose(false)}>
        <DialogTitle className={classes.title}>{strings.labelPickColor}</DialogTitle>
        <DialogContent>
          <SketchPicker
            color={colorToEdit.value}
            onChangeComplete={handleChangeColor(colorToEdit.color)}
          />
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleEditColorClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={imageCrop.icon !== ''} onClose={() => setImageCrop({ ...imageCrop, icon: '' })}>
        <DialogTitle className={classes.title}>{strings.labelCropImage}</DialogTitle>
        <DialogContent>
          <ReactCrop src={imageCrop.icon} crop={crop} onChange={newCrop => setCrop(newCrop)} />
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleEditColorClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
        </DialogActions>
      </Dialog>
    </Box>


  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditorForceRefresh: state.settingsOEMEditorForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect(mapStateToProps)(withStyles(gStyles)(SettingsOEMEditor));