import React, { useEffect, useState }  from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setLicenseAssignList } from '../utils/Reducer';
import { formatDateEpoch, getSoftware, getSoftwareVersion, getSoftwareOS } from '../utils/Utils';
import { getLicenses } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { Paper, Typography, CircularProgress, InputBase, IconButton, Checkbox, Button, Tooltip } from '@mui/material';
import { Search, PlayCircleOutline, PauseCircleOutline, AlarmOn, Autorenew, TrendingUp } from '@mui/icons-material';

import LicenseStateDialog from './LicenseStateDialog';
import LicenseUpgradeExternalWizard from './LicenseUpgradeExternalWizard';
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Licenses',
    buttonExternalUpgrade: 'Upgrade External License',
    tableHeaderDistributorName:'Distributor',
    tableHeaderLicense:'License Id',
    tableHeaderCustomer:'Customer',
    tableHeaderType:'Type',
    tableHeaderSerial:'Dongle Serial',
    tableHeaderOrderDate:'Order On',
    tableHeaderCurrentEdition:'Edition',
    tableHeaderSoftware:'Software',
    tableHeaderSoftwareVersion:'Version',
    tableHeaderSoftwareOS:'OS',
    tableHeaderEnd:'Expires On ',
    searchPlaceHolder:'search',

    typeDongle: 'Dongle',
    typeCode: 'Activation Code',
    typePrinter: 'Printer Activation',
    typeTrial: 'Trial',

    licenseSuspended: 'Suspended',
    licenseNotStarted: 'Not started',
    
    toolTipActivate: 'Activate license',
    toolTipSuspend: 'Suspend license',
    toolTipStart: 'Start license for 1 year',
    toolTipRenew: 'Renew license for 1 more year',
    toolTipUpgrade: 'Upgrade license',
	}
});

function LicenseTable( props ) { 

  const { classes } = props;

  const { assignMode } = props;

  const { userType } = props;
  const { allowFeatures } = props;
  const { licenseTableForceRefresh } = props;
  const { licenseList } = props;
  const { licenseAssignList, setLicenseAssignList } = props;
  
  const [ localPage, setLocalPage ] = useState(0);
  const [ localRowsPerPage, setLocalRowsPerPage ] = useState(10);
  const [ upgradeExternalWizardOpen, setUpgradeExternalWizardOpen ] = useState( false );
  const [ dialogType, setDialogType ] = useState( '' );
  const [ currentLicense, setCurrentLicense ] = useState( undefined );

  const { distributorId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );

  const page = !assignMode ? Number( searchParams.get( 'page' ) ) : localPage;
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  urlRowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const rowsPerPage = !assignMode ? urlRowsPerPage : localRowsPerPage;

  const searchFromURL = searchParams.get( 'search' ) === null ? '' : searchParams.get( 'search' );
  const [ searchValue, setSearchValue ] = useState( '' );
  const [ searchTrigger, setSearchTrigger ] = useState( '' );

  const filterIdFromURL = searchParams.get( 'filter' ) === null ? '-1' : searchParams.get( 'filter' );

  useEffect(
    ( ) => {
      if ( !assignMode ) setSearchValue( searchFromURL );
      getLicenses( page * rowsPerPage, rowsPerPage, assignMode ? searchTrigger : searchFromURL, filterIdFromURL );
    },
    [ page, rowsPerPage, assignMode, searchFromURL, searchTrigger, filterIdFromURL, licenseTableForceRefresh ]
  );

  const handleLocalChangePage = ( event, newPage ) => {
    if ( !assignMode )
      handleChangePage( history, newPage );
    else
      setLocalPage( newPage );
  }

  const handleLocalChangeRowsPerPage = event => {
    if (!assignMode)
      handleChangeRowsPerPage( history, event, page, rowsPerPage )
    else {
      //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
      var newRowsPerPage = parseInt(event.target.value, 10);
      setLocalPage( Math.trunc( page * ( rowsPerPage / newRowsPerPage ) ) );
      setLocalRowsPerPage( newRowsPerPage );
    }
  }

  const searchKeyPress = event => {
    if ( event.charCode === 13 ) {//ENTER key pressed
      event.preventDefault();
      handleSearch( );
    }
  }

  const handleSearch = () => {
    if ( assignMode ) {
      setSearchTrigger( searchValue );
      return;
    }

    var searchParams = new URLSearchParams( history.location.search );
    if ( searchValue !== '' )
      searchParams.set( 'search', searchValue );
    else
      searchParams.delete( 'search' );
    searchParams.delete( 'page' ); 
    history.push( { search: searchParams.toString() } );
  }

  const handleCheck = ( license ) => {
    var newSelecteItems = licenseAssignList;
    var index = newSelecteItems.indexOf( license.license );
    if (index !== -1)
      newSelecteItems.splice( index, 1 );
    else
      newSelecteItems.push( license.license );

    setLicenseAssignList( newSelecteItems );
  }

  const handleStateClick = ( type, license ) => {
    setDialogType( type );
    setCurrentLicense( license );
  }
  const handleStateDialogClose = () => {
    setCurrentLicense( undefined );
  }

  const handleUpgradeExternalClick = () => {
    setUpgradeExternalWizardOpen( true );
  }
  const handleUpgradeExternalWizardClose = () => {
    setUpgradeExternalWizardOpen( false );
  }

  function formatType(_type) { 
    switch (_type) {
      case 1:
        return strings.typeDongle;
      case 2:
        return strings.typeCode;
      case 3:
        return strings.typePrinter;
      case 4:
        return strings.typeTrial;
      default:
        return '';
    }
  }

  return (
    <Paper className={ classes.workarea }>
      <div className={ classes.flexHeader }>
        <Typography  variant='h4' className={ classes.title }>{ strings.title }</Typography>
        { !assignMode && userType !== 0 &&
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleUpgradeExternalClick( ) }>
            <TrendingUp className={ classes.leftIcon } />
            { strings.buttonExternalUpgrade }
          </Button>
        }
        <div className={ classes.flexExpand }/>
        <Paper className={ classes.searchPaper }>
          <InputBase
            className={ classes.searchInput }
            placeholder={ strings.searchPlaceHolder }
            inputProps={ { 'aria-label': 'Search Text' } }
            value={ searchValue }
            onChange={ ( event ) => setSearchValue( event.target.value ) }
            onKeyPress={ searchKeyPress } />
          <IconButton aria-label='Search' size='small' onClick={ handleSearch }>
            <Search/>
          </IconButton>
        </Paper>
      </div>
      
      <div className={ classes.tableArea } >
        { licenseList !== undefined &&
          <Table size='small'>
            <TableHead>
              <TableRow>
                { assignMode && <TableCell/> }
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderLicense }</Typography>
                </TableCell>
                { (userType === 0 || (userType === 1 && (allowFeatures & 4) === 4)) &&
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDistributorName }</Typography>
                  </TableCell>
                }
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderCustomer }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderType }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderSerial }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderOrderDate }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderCurrentEdition }</Typography>
                </TableCell>
                { !assignMode && <TableCell/> }
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderSoftware }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderSoftwareVersion }</Typography>
                </TableCell>
                { !assignMode &&
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSoftwareOS }</Typography>
                  </TableCell>
                }
                { !assignMode &&
                  <TableCell colSpan='3'>
                    <Typography variant='h6'>{ strings.tableHeaderEnd }</Typography>
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              { licenseList.items.map( license => (
                <TableRow key={ license.license }>
                  { assignMode &&
                    <TableCell padding="checkbox">
                      { (parseInt(distributorId) === license.distributor_id || parseInt(distributorId) === license.subdistributor_id) && 
                        <Checkbox disabled= { true } checked= { true }/>
                      }
                      { (parseInt(distributorId) !== license.distributor_id && parseInt(distributorId) !== license.subdistributor_id) && 
                        <Checkbox onClick={ ( ) => handleCheck( license ) }/>
                      }
                    </TableCell>
                  }
                  { !assignMode &&
                    <TableCell className={ classes.link }>
                      <Link className={ classes.link } to={ '/licenses/' + license.license + '/details' }>
                        <Typography variant='body2'>{ license.license }</Typography>
                      </Link>
                    </TableCell>
                  }
                  { assignMode &&
                    <TableCell>
                      <Typography variant='body2'>{ license.license }</Typography>
                    </TableCell>
                  }
                  { userType === 0 &&
                    <TableCell className={ classes.link }>
                      <Link className={ classes.link } to={ '/distributors/' + license.distributor_id + '/details' }>
                        <Typography variant='body2'>{ license.distributor_name }</Typography>
                      </Link>                      
                    </TableCell>
                  }
                  { userType === 1 && (allowFeatures & 4) === 4 && 
                    <TableCell className={ classes.link }>
                      <Link className={ classes.link } to={ '/distributors/' + license.subdistributor_id + '/details' }>
                        <Typography variant='body2'>{ license.subdistributor_name }</Typography>
                      </Link>                      
                    </TableCell>
                  }
                  { !assignMode && license.customer_id !== null && 
                    <TableCell className={ classes.link }>
                      <Link className={ classes.link } to={ '/customers/' + license.customer_id + '/details' }>
                        <Typography variant='body2'>{ license.customer }</Typography>
                      </Link>
                    </TableCell>
                  }
                  { ( assignMode || license.customer_id === null ) &&
                    <TableCell>
                      <Typography variant='body2'>{ license.customer }</Typography>
                    </TableCell>
                  }
                  <TableCell>
                    <Typography variant='body2'>{ formatType( license.license_type ) }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ license.serial }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ formatDateEpoch( license.order_date ) }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ license.edition }</Typography>
                  </TableCell>
                  { !assignMode &&
                    <TableCell className={ classes.tableButton }>
                      { license.renewable === 0 && license.canUpgrade &&
                        <Tooltip title={ strings.toolTipUpgrade } onClick={ ( ) => handleStateClick( 'upgrade', license ) } >
                          <IconButton size='small' >
                            <TrendingUp/>
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                  }
                  <TableCell>
                    <Typography variant='body2'>{ getSoftware( license.softwareVersion ) }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ getSoftwareVersion( license.softwareVersion ) }</Typography>
                  </TableCell>
                  { !assignMode && 
                    <TableCell>
                      <Typography variant='body2'>{ getSoftwareOS( license.softwareVersion ) }</Typography>
                    </TableCell>
                  }
                  { !assignMode && 
                    <TableCell>
                      { license.renewable === 1 &&
                        <Typography variant='body2'>
                          { license.end_utc === null ? strings.licenseNotStarted : ( license.active === 1 ? formatDateEpoch( license.end_utc ) : strings.licenseSuspended ) }
                        </Typography>
                      }
                    </TableCell>
                  }
                  { !assignMode && 
                    <TableCell className={ classes.tableButton }>
                      { license.renewable === 1 &&
                        <Tooltip
                          title={ license.end_utc === null ? strings.toolTipStart : strings.toolTipRenew }
                          onClick={ ( ) => handleStateClick( license.end_utc === null ? 'start' : 'renew', license ) }>
                          <IconButton size='small' >
                            { license.end_utc === null ? <AlarmOn/> : <Autorenew/> }
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                  }
                  { !assignMode &&
                    <TableCell className={ classes.tableButton }>
                      { license.renewable === 1 && license.end_utc !== null &&
                        <Tooltip
                          title={ license.active === 1 ? strings.toolTipSuspend : strings.toolTipActivate }
                          onClick={ ( ) => handleStateClick( license.active === 1 ? 'suspend' : 'activate', license ) }>
                          <IconButton size='small' >
                            { license.active === 1 ? <PauseCircleOutline/> : <PlayCircleOutline/> }
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                  }
                </TableRow>
              )) }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ !assignMode ? 14 : 10 } count={ licenseList.count } rowsPerPage={ rowsPerPage } page={ page }
                  SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                  onPageChange={ handleLocalChangePage }
                  onRowsPerPageChange={ handleLocalChangeRowsPerPage }
                  ActionsComponent={ TablePaginationActions }
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        { licenseList === undefined &&
            <CircularProgress className={ classes.spinner }/>
        }
        <LicenseStateDialog open={ currentLicense !== undefined } dialogType={ dialogType } currentLicense={ currentLicense } onClose={ () => handleStateDialogClose() }/>
        <LicenseUpgradeExternalWizard open={ upgradeExternalWizardOpen } onClose={ () => handleUpgradeExternalWizardClose() }/>        
      </div>
    </Paper>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    allowFeatures: state.allowFeatures,
    licenseTableForceRefresh: state.licenseTableForceRefresh,
    licenseList: state.licenseList,
    licenseAssignList: state.licenseAssignList,
  }
};

export default connect( mapStateToProps, { setLicenseAssignList } )( withStyles( gStyles )( LicenseTable ) );