import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getPrinters, deletePrinter } from './PrinterRest';
import { formatDateEpoch } from '../utils/Utils';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { Paper, Typography, CircularProgress, InputBase, IconButton, Button, Tooltip } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Search, Delete, AddCircle, CloudUpload } from '@mui/icons-material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';
import PrinterWizardAdd from './PrinterWizardAdd';
import PrinterWizardUpload from './PrinterWizardUpload';

let strings = new LocalizedStrings({
  en: {
    title: 'Printers',
    buttonAdd: 'Add printer',
    buttonUpload: 'Upload printers',
    toolTipDelete: 'Delete printer',
    tableHeaderDistributorName: 'Distributor',
    tableHeaderSerialNumber: 'Serial Number',
    tableHeaderLicense: 'License',
    tableHeaderManufacturer: 'Manufacturer',
    tableHeaderModel: 'Model',
    tableHeaderFirmware: 'Firmware',
    tableHeaderSoftVersion: 'Software',
    tableHeaderOsVersion: 'OS',
    tableHeaderDriver: 'Driver',
    tableHeaderCardCount: 'Card Count',
    tableHeaderRibbonType: 'Ribbon Type',
    tableHeaderRibbonRemain: 'Ribbon (%)',
    tableHeaderLastUpdate: 'Last Update',
    searchPlaceHolder: 'search',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete printer \'{entity}\'?',
    yes: 'Yes',
    no: 'No',
  }
});

function PrinterTable(props) {

  const location = useLocation();
  const { classes } = props;

  const [wizardUploadOpen, setWizardUploadOpen] = useState(false);

  const { userType, allowAssignPrinter } = props;
  const { printerTableForceRefresh } = props;
  const { printerList } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);
  const [currentUrlSearch, setCurrentUrlSearch] = useState('');
  const [currentFilterId, setCurrentFilterId] = useState('-1');

  const [entityToDelete, setEntityToDelete] = useState(undefined);
  const [wizardAddOpen, setAddWizardOpen] = useState(false);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);

  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  const searchFromURL = searchParams.get('search') === null ? '' : searchParams.get('search');
  const [searchValue, setSearchValue] = useState('');
  const filterIdFromURL = searchParams.get('filter') === null ? '-1' : searchParams.get('filter');

  useEffect(
    () => {
      setCurrentPage(parseInt(page))
      setCurrentRowsPerPage(parseInt(rowsPerPage))
    },
    []
  );

  useEffect(() => {
    const filterIdFromURL = searchParams.get('filter') === null ? '-1' : searchParams.get('filter');
    if (currentFilterId !== filterIdFromURL) {
      setCurrentFilterId(filterIdFromURL);
    }
  }, [location]);

  useEffect(
    () => {
      getPrinters(currentPage * currentRowsPerPage, currentRowsPerPage, currentUrlSearch, currentFilterId);
    },
    [currentPage, currentRowsPerPage, currentUrlSearch, currentFilterId, page, rowsPerPage, searchFromURL, filterIdFromURL, printerTableForceRefresh]
  );

  const searchKeyPress = event => {
    if (event.charCode === 13) {//ENTER key pressed
      event.preventDefault();
      handleSearch();
    }
  }

  const handleSearch = () => {

    var searchParams = new URLSearchParams(history.location.search);
    if (searchValue !== '') {
      searchParams.set('search', searchValue);
    } else {
      searchParams.delete('search');
      searchParams.delete('page');
    }
    history.push({ search: searchParams.toString() });
    setCurrentPage(0);
    setCurrentUrlSearch(searchValue)
  }

  const handleDeleteDialogClose = confirm => {
    if (confirm)
      deletePrinter(entityToDelete);
    setEntityToDelete(undefined);
  }

  const handleDelete = customer => {
    setEntityToDelete(customer);
  };

  return (
    <div>
      <Paper className={classes.workarea}>
        <div className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          {allowAssignPrinter &&
            <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => setAddWizardOpen(true)}>
              <AddCircle className={classes.leftIcon} />
              <Typography variant='button'>{strings.buttonAdd}</Typography>
            </Button>
          }
          {allowAssignPrinter &&
            <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => setWizardUploadOpen(true)}>
              <CloudUpload className={classes.leftIcon} />
              <Typography variant='button'>{strings.buttonUpload}</Typography>
            </Button>
          }
          <div className={classes.flexExpand} />
          <Paper className={classes.searchPaper}>
            <InputBase
              className={classes.searchInput}
              placeholder={strings.searchPlaceHolder}
              inputProps={{ 'aria-label': 'Search Text' }}
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              onKeyPress={searchKeyPress} />
            <IconButton aria-label='Search' size='small' onClick={handleSearch}>
              <Search />
            </IconButton>
          </Paper>
        </div>

        <div className={classes.tableArea}>
          {printerList !== undefined &&
            <Table size='medium'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderSerialNumber}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderLicense}</Typography>
                  </TableCell>
                  {userType !== 2 &&
                    <TableCell>
                      <Typography variant='subtitle1'>{strings.tableHeaderDistributorName}</Typography>
                    </TableCell>
                  }
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderManufacturer}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderModel}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderFirmware}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderSoftVersion}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderOsVersion}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderDriver}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderCardCount}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderRibbonType}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderRibbonRemain}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle1'>{strings.tableHeaderLastUpdate}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {printerList.items.map(printer => (
                  <TableRow key={printer.serial_number}>
                    {printer.update_date !== null &&
                      <TableCell>
                        <Link className={classes.link} to={'/printers/' + printer.serial_number + '/details'}>
                          <Typography variant='body2'>{printer.serial_number}</Typography>
                        </Link>
                      </TableCell>
                    }
                    {printer.update_date === null &&
                      <TableCell>
                        <Typography variant='body2'>{printer.serial_number}</Typography>
                      </TableCell>
                    }

                    {printer.last_license !== null &&
                      <TableCell>
                        <Link className={classes.link} to={'/licenses/' + printer.last_license + '/details'}>
                          <Typography variant='body2'>{printer.last_license}</Typography>
                        </Link>
                      </TableCell>
                    }
                    {printer.last_license === null &&
                      <TableCell />
                    }

                    {userType === 0 && printer?.distributor_id !== undefined && printer.distributor_id !== null &&
                      <TableCell>
                        <Link className={classes.link} to={'/distributors/' + printer.distributor_id + '/details'}>
                          <Typography variant='body2'>{printer.distributor_name}</Typography>
                        </Link>
                      </TableCell>
                    }
                    {userType !== 0 &&
                      <TableCell>
                        <Typography variant='body2'>{printer.distributor_name}</Typography>
                      </TableCell>
                    }

                    <TableCell>
                      <Typography variant='body2'>{printer.manufacturer}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.model}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.info !== null ? JSON.parse(printer.info).firmwareVersion : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.info !== null ? JSON.parse(printer.info).softVersion : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.info !== null ? JSON.parse(printer.info).systemVersion : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.info !== null ? JSON.parse(printer.info).driverVersion : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.last_card_count !== null ? printer.last_card_count : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.last_status !== null ? JSON.parse(printer.last_status).ribbonType : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{printer.last_ribbon_remaining !== null ? printer.last_ribbon_remaining / 10 : ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{formatDateEpoch(printer.update_date)}</Typography>
                    </TableCell>

                    {printer.update_date === null &&
                      <TableCell className={classes.tableButton} >
                        <Tooltip title={strings.toolTipDelete}>
                          <IconButton size='small' onClick={() => handleDelete(printer)} >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    }
                    {printer.update_date !== null &&
                      <TableCell />
                    }
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={14} count={printerList.count} rowsPerPage={currentRowsPerPage} page={currentPage}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => { handleChangePage(history, newPage); setCurrentPage(newPage); }}
                    onRowsPerPageChange={(event) => { handleChangeRowsPerPage(history, event, page, currentRowsPerPage); setCurrentRowsPerPage(parseInt(event.target.value)) }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          {printerList === undefined &&
            <CircularProgress className={classes.spinner} />
          }
        </div>
      </Paper>
      <Dialog open={entityToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText.replace('{entity}', entityToDelete !== undefined ? entityToDelete.serial_number : '')}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <PrinterWizardUpload open={wizardUploadOpen} onClose={() => setWizardUploadOpen(false)} />
      <PrinterWizardAdd open={wizardAddOpen} onClose={() => setAddWizardOpen(false)} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    allowAssignPrinter: state.allowAssignPrinter,
    printerTableForceRefresh: state.printerTableForceRefresh,
    printerList: state.printerList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(PrinterTable));