import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorOEMEditions, setDistributorOEMProducts } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, FormControl, Tooltip, Dialog, FormLabel } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings = new LocalizedStrings({
  en: {
    title: 'Editions',
    titleDialog: 'Editions',

    tableHeaderEditionName: 'Code',
    tableHeaderTitle: 'My code',
    tableHeaderDescription: 'Description',
    tableHeaderDetailedDescription: 'Detailed Description',
    tableHeaderPicture: 'Picture',
    tableHeaderLink: 'Find out more/Link',

    labelEditionName: 'Code',
    labelTitle: 'My code',
    labelDescription: 'Description',
    labelDetailedDescription: 'Detailed Description',
    labelPicture: 'Picture',
    labelLink: 'Find out more/Link',
    labelPicture: 'Picture',

    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit the Edition',
    toolTipEditPicture: 'Click the picture to edit',

    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMEditions(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});

  const { settingsOEMEditionsTableForceRefresh } = props;
  const { settingsOEMEditionsList } = props;

  const [state, setState] = useState({ hover: false });

  const { oemId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    () => {
      getDistributorOEMEditions(page * rowsPerPage, rowsPerPage, oemId);
    },
    [page, rowsPerPage, settingsOEMEditionsTableForceRefresh, oemId]
  );

  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch (name) {
      case 'code': {
        setValues({ ...values, code: event.target.value });
        break;
      }
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'description': {
        setValues({ ...values, description: event.target.value });
        break;
      }
      case 'detailedDescription': {
        setValues({ ...values, descriptionDetail: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleEdit = edition => {
    setEditMode(true);
    setValues({
      oem_id: oemId,
      oldCode: edition.product_code,
      code: edition.product_code,
      file: edition.product_file,
      title: edition.product_public,
      description: edition.product_description,
      descriptionDetail: edition.product_description_detail,
      picture: (edition.product_picture === null || edition.product_picture.includes('data:image') === false) ? '' : edition.product_picture,
      link: edition.product_link
    });
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setEditMode(false);
    setDistributorOEMProducts(values);
  };

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
        </Box>
        {settingsOEMEditionsList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderEditionName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderTitle}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDetailedDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPicture}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMEditionsList.items.map((edition) => (
                  <TableRow key={edition.product_code}>
                    <TableCell style={{ width: '20%' }}>{edition.product_code}</TableCell>
                    <TableCell style={{ width: '15%' }}>{edition.product_public}</TableCell>
                    <TableCell>{edition.product_description}</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-line' }}>{edition.product_description_detail}</TableCell>
                    <TableCell style={{ width: '50px' }}>
                      <Tooltip title={state.hover ? (<img src={(edition.product_picture !== null && edition.product_picture.includes('data:image') ? edition.product_picture : backgroundImage)} style={{ maxWidth: '200px', width: '200px' }} alt="" />) : ''} alt="">
                        <Icon style={{ width: '100%', height: 'auto' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(edition.product_picture !== null && edition.product_picture.includes('data:image') ? edition.product_picture : backgroundImage)} width="100%" height="auto" alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: '25%' }}>{edition.product_link}</TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(edition)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMEditionsList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMEditionsList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ height: '100%' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              {values.picture === '' &&
                <Grid item xs={4}>
                  <FormControl variant='outlined' required>
                    <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                    <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                  </FormControl>
                </Grid>
              }

              {values.picture !== '' &&
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={11}>
                      <FormControl variant='outlined' required onClick={handleEditPicClick}>
                        <FormLabel><Typography variant='caption'>{strings.labelPicture}</Typography></FormLabel>
                        <img src={values.picture} width={'100%'} alt="" />
                        <input type='file' accept='.png,.jpeg,.jpg' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton style={{ marginLeft: '-100px', marginTop: '-5px' }} size='small' onClick={() => handleEditPicClick()} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              }

              <Grid item xs={8}>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth disabled variant='outlined' margin='dense' id='code'
                      label={strings.labelEditionName} value={values.code} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='title'
                      label={strings.labelTitle} value={values.title} onChange={handleChange('title')} />
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description' inputProps={{ maxLength: 255 }}
                  label={strings.labelDescription} value={values.description} onChange={handleChange('description')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' multiline maxRows={4} margin='dense' id='detailedDescription' inputProps={{ maxLength: 500 }}
                  label={strings.labelDetailedDescription} value={values.descriptionDetail} onChange={handleChange('detailedDescription')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditionsTableForceRefresh: state.settingsOEMEditionsTableForceRefresh,
    settingsOEMEditionsList: state.settingsOEMEditionsList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMEditions));