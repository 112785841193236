import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import rootReducer, { initialState } from './utils/Reducer';
import { loadFromLocalStorage } from './utils/Utils';
import { StyledEngineProvider } from '@mui/material/styles';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './utils/Styles';
import App from './App';

const persistedState = loadFromLocalStorage();
export const store = createStore(rootReducer, { ...initialState, ...persistedState });
 
//store.subscribe( () => saveToLocalStorage( store.getState( ) ));
const rootNode = document.getElementById('root');
ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <CssBaseline />
          <Router>
            <Route path='/:filter?'>
              <App />
            </Route>
          </Router>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  rootNode
);