import React, { useEffect, useState }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setCustomerAssignItem, setLicenseSelected } from '../utils/Reducer';
import { formatDateEpoch, getSoftware, getSoftwareVersion, getSoftwareOS } from '../utils/Utils';
import { getLicense } from './LicenseRest';
import { addCustomerLicenses } from '../customer/CustomerRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Typography, CircularProgress, Button, Box } from '@mui/material';
import { Dialog } from '@mui/material';
import { Edit, PlayCircleOutline, PauseCircleOutline, AlarmOn, Autorenew, TrendingUp } from '@mui/icons-material';

import LicenseStateDialog from './LicenseStateDialog';
import CustomerTable from '../customer/CustomerTable';

import edition_xxs from './../resources/licenses/CP_XXS.png';
import edition_xs from './../resources/licenses/CP_XS.png';
import edition_xm from './../resources/licenses/CP_XM.png';
import edition_xl from './../resources/licenses/CP_XL.png';
import edition_xxl from './../resources/licenses/CP_XXL.png';

let strings=new LocalizedStrings({
	en:{
    labelLicense:'License Id: ',
    labelType:'Type: ',
    labelDongleSerial:'Dongle Serial: ',
    labelDongleVid:'Dongle VID: ',
    labelDonglePid:'Dongle PID: ',
    labelActivationCode:'Activation Code: ',
    labelStart:'Starts On: ',
    labelEnd:'Expires On: ',
    labelStatus:'Status: ',
    labelActive:'Active',
    labelNotStarted:'Not started',
    labelSuspended:'Suspended',
    labelCustomer:'Customer: ',
    labelCreatedDate:'Created On: ',
    labelOrderDate:'Order On: ',
    labelOriginalEdition:'Original Edition: ',
    labelCurrentEdition:'Current Edition: ',
    labelUpgradeCount:'Upgrade count: ',
    labelSoftware:'Software: ',
    labelSoftwareVersion:'Software Version: ',
    labelSoftwareOS:'Software OS: ',

    typeDongle: 'Dongle',
    typeCode: 'Activation Code',
    typePrinter: 'Printer Activation',
    typeTrial: 'Trial',

    buttonUpdate: 'Update Customer',
    buttonAdd: 'Add Customer',
    buttonActivate: 'Activate License',
    buttonSuspend: 'Suspend License',
    buttonStart: 'Start License',
    buttonRenew: 'Renew License',
    buttonUpgrade: 'Upgrade License',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',
	}
})

function LicenseDetail( props ) {

  const { classes } = props;

  const { userType } = props;
  const { licenseTableForceRefresh } = props;
  const { customerAssignItem, setCustomerAssignItem } = props;
  const { licenseSelected, setLicenseSelected } = props;

  const [ dialogType, setDialogType ] = useState( '' );
  const [ currentLicense, setCurrentLicense ] = useState( undefined );
  const [ editMode, setEditMode ] = useState( false );

  const { licenseId } = useParams();
  let history = useHistory();
  
  useEffect(
    () => {
      getLicense( licenseId );
    },
    [ licenseId, licenseTableForceRefresh ]
  );

  const getImage = image => {
    image = image.toLowerCase();
    if ( image.includes('xxs') ) return edition_xxs;
    if ( image.includes('xxl') ) return edition_xxl;
    if ( image.includes('xs') ) return edition_xs;
    if ( image.includes('xm') ) return edition_xm;
    if ( image.includes('xl') ) return edition_xl;
    return edition_xxs;
  }

  const handleAdd = () => {
    setCustomerAssignItem(  { name: licenseSelected.customer, customer_id: licenseSelected.customer_id } );
    setEditMode( true );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleConfirm = ( ) => {
    addCustomerLicenses( customerAssignItem.customer_id, [ licenseSelected.license ] );
    setLicenseSelected( { ...licenseSelected, customer: customerAssignItem.name, customer_id: customerAssignItem.customer_id } );
    setEditMode( false );
  };

  const handleStateClick = ( type, license ) => {
    setDialogType( type );
    setCurrentLicense( license );
  } 
  const handleStateDialogClose = () => {
    setCurrentLicense( undefined );
  }

  return (
    <div className={ classes.workarea }>
      { licenseSelected !== undefined &&
        <div>
          <div>
            <Typography variant='body1'>{ strings.labelLicense }<b>{ licenseSelected.license }</b></Typography>
            { licenseSelected.license_type === 1 &&
              <div>
                <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeDongle }</b></Typography>
                <Typography variant='body1'>{ strings.labelDongleSerial }<b>{ licenseSelected.serial }</b></Typography>
                <Typography variant='body1'>{ strings.labelDongleVid }<b>{ licenseSelected.vid }</b></Typography>
                <Typography variant='body1'>{ strings.labelDonglePid }<b>{ licenseSelected.pid }</b></Typography>
              </div>
            }
            { licenseSelected.license_type === 2 &&
              <div>
                <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeCode }</b></Typography>
                <Typography variant='body1'>{ strings.labelActivationCode }<b>{ licenseSelected.activation_code }</b></Typography>
              </div>
            }
            { licenseSelected.license_type === 3 &&
              <div>
                <Typography variant='body1'>{ strings.labelType }<b>{ strings.typePrinter }</b></Typography>
                <Typography variant='body1'>{ strings.labelActivationCode }<b>{ licenseSelected.activation_code }</b></Typography>
              </div>
            }
            { licenseSelected.license_type === 4 &&
              <div>
                <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeTrial }</b></Typography>
              </div>
            }
            <Typography variant='body1'>{ strings.labelCreatedDate }<b>{ formatDateEpoch( licenseSelected.created_date ) }</b></Typography>
            <Typography variant='body1'>{ strings.labelOrderDate }<b>{ formatDateEpoch( licenseSelected.order_date ) }</b></Typography>
            <Typography variant='body1'>{ strings.labelOriginalEdition }<b>{ licenseSelected.original }</b></Typography>
            <Typography variant='body1'>{ strings.labelCurrentEdition }<b>{ licenseSelected.edition }</b></Typography>
            <Typography variant='body1'>{ strings.labelUpgradeCount }<b>{ licenseSelected.upgradedCount !== null ? licenseSelected.upgradedCount : 0 }</b></Typography>
            <Typography variant='body1'>{ strings.labelSoftware }<b>{ getSoftware( licenseSelected.softwareVersion ) }</b></Typography>
            <Typography variant='body1'>{ strings.labelSoftwareVersion }<b>{ getSoftwareVersion( licenseSelected.softwareVersion ) }</b></Typography>
            <Typography variant='body1'>{ strings.labelSoftwareOS }<b>{ getSoftwareOS( licenseSelected.softwareVersion ) }</b></Typography>
            { licenseSelected.renewable === 1 &&
              <Box>
                <Typography variant='body1'>
                  { strings.labelStatus }<b>{ licenseSelected.end_utc === null ? strings.labelNotStarted : licenseSelected.active === 1 ? strings.labelActive : strings.labelSuspended }</b>
                  </Typography>
                <Typography variant='body1'>{ strings.labelStart }<b>{ formatDateEpoch( licenseSelected.start_utc ) }</b></Typography>
                <Typography variant='body1'>{ strings.labelEnd }<b>{ formatDateEpoch( licenseSelected.end_utc ) }</b></Typography>
              </Box>
            }
            <Typography variant='body1'>{ strings.labelCustomer }
              <b className={ classes.link } onClick={ () => history.push('/customers/' + licenseSelected.customer_id + '/details') }>
                { licenseSelected.customer }
              </b>
            </Typography>
            <img src={ getImage( licenseSelected.pcode ) } className={ classes.floatingImage } alt=''/>
            <div className={ classes.lineButtonBox }>
              <Button
                variant='contained'
                color='secondary'
                className={ classes.flexButton }
                onClick={ ( ) => handleStateClick( 'upgrade', licenseSelected ) }
                disabled={ !licenseSelected.canUpgrade } >
                <TrendingUp className={ classes.leftIcon }/>
                { strings.buttonUpgrade }
              </Button>
              { licenseSelected.renewable === 1 && 
                <Button
                  variant='contained'
                  color='secondary'
                  className={ classes.flexButton }
                  onClick={ ( ) => handleStateClick( licenseSelected.end_utc === null ? 'start' : 'renew', licenseSelected ) } >
                  { licenseSelected.end_utc === null ?
                    <AlarmOn className={ classes.leftIcon }/> :
                    <Autorenew className={ classes.leftIcon }/>
                  }
                  { licenseSelected.end_utc === null ? strings.buttonStart : strings.buttonRenew }
                </Button>
              }
              { licenseSelected.renewable === 1 &&
                <Button
                  variant='contained'
                  color='secondary'
                  className={ classes.flexButton }
                  onClick={ ( ) => handleStateClick( licenseSelected.active === 1 ? 'suspend' : 'activate', licenseSelected ) }
                  disabled={ licenseSelected.end_utc === null } >
                  { licenseSelected.active === 1 ?
                    <PauseCircleOutline className={ classes.leftIcon }/> :
                    <PlayCircleOutline className={ classes.leftIcon }/>
                  }
                  { licenseSelected.active === 1 ? strings.buttonSuspend : strings.buttonActivate }
                </Button>
              }
              { (userType === 2 || ( userType === 1 && licenseSelected.subdistributor_id === null)) &&
                <Button
                  variant='contained'
                  color='secondary'
                  className={ classes.flexButton }
                  onClick={ ( ) => handleAdd() } >
                  <Edit className={ classes.leftIcon }/>
                  { licenseSelected.customer !== null ? strings.buttonUpdate : strings.buttonAdd }
                </Button>
              }         
            </div>
          </div>
          
          <Dialog maxWidth='lg' open={ editMode }>
            <div className={ classes.workarea }>
              <CustomerTable assignMode={ true }/>
              <div className={ classes.lineButtonBox }>
                <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleConfirm() }>{ strings.buttonConfirm }</Button>
                <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
              </div>
            </div>
          </Dialog>
          <LicenseStateDialog open={ currentLicense !== undefined } dialogType={ dialogType } currentLicense={ currentLicense } onClose={ () => handleStateDialogClose() }/>
        </div>
      }
      { licenseSelected === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    licenseTableForceRefresh: state.licenseTableForceRefresh,
    customerAssignItem: state.customerAssignItem,
    licenseSelected: state.licenseSelected
  }
};

export default connect( mapStateToProps, { setCustomerAssignItem, setLicenseSelected } )( withStyles( gStyles )( LicenseDetail ) );