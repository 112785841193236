import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';

//import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, AppBar, Toolbar, Typography, Button, CircularProgress, Paper, Card, CardContent, CardMedia, ButtonBase } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';
import backgroundImage2 from './../resources/icons/backgroundImage_iconButton2.png';

import applogo from './../resources/icons/softwareLogo.png';
import appIcon from './../resources/preview/appIcon.png';

import cr80Image from './../resources/preview/card_cr80.png';
import cr90ImageSelected from './../resources/preview/card_cr90_selected.png';
import { store } from '..';

const theme = createTheme({
  //v5.0.0
  typography: {
    body2: {
      fontSize: 9
    },
    body1: {
      fontSize: 12
    },
    button: {
      fontSize: 10,
      textTransform: "none",
      '&:hover': {
        hoverColor: "#fff",
      }
    }
  },
});

function calculateWidthFromHeight(imageBase64: string, height: number): Promise<number> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const ratio = img.width / img.height;
      const width = Math.round(height * ratio);
      resolve(width);
    };
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    img.src = imageBase64;
  });
}

function cropBase64Image(base64String: string, cropWidth: number, cropHeight: number): string {
  // Create an image object
  var image = new Image();
  image.src = base64String;

  // Create a canvas element
  var canvas = document.createElement('canvas');
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  // Draw the image onto the canvas
  var ctx: any = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, cropWidth, cropHeight);

  // Get the cropped image as a base64 string
  var croppedBase64String = canvas.toDataURL('image/jpeg');

  return croppedBase64String;
}

async function cropBase64ImageV2(base64String: string, targetWidth: number, targetHeight: number): Promise<string> {

  return new Promise((resolve) => {

    // Get the canvas element and its 2D rendering context
    // var canvas: any = document.createElement('canvas');
    // var ctx: any = canvas.getContext("2d");

    // Create an image element
    var img = new Image();

    // Set the source of the image
    img.src = base64String;

    // When the image has loaded
    img.onload = function () {
      const sourceWidth = img.width;
      const sourceHeight = img.height;

      const widthRatio = targetWidth / sourceWidth;
      const heightRatio = targetHeight / sourceHeight;

      let cropWidth, cropHeight, cropX, cropY;

      if (widthRatio > heightRatio) {
        cropWidth = targetWidth;
        cropHeight = sourceHeight * widthRatio;
        cropX = 0;
        cropY = (cropHeight - targetHeight) / 2;
      } else {
        cropWidth = sourceWidth * heightRatio;
        cropHeight = targetHeight;
        cropX = (cropWidth - targetWidth) / 2;
        cropY = 0;
      }

      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');

      canvas.width = targetWidth;
      canvas.height = targetHeight;
      context.drawImage(img, -cropX, -cropY, cropWidth, cropHeight);

      const croppedImage: string = canvas.toDataURL('image/jpeg');
      resolve(croppedImage);

    };

    img.onerror = function () {
      resolve("");
    };

  });

}

function SettingsOEMWelcomeView({ settingsOEMPreviewForceRefresh, settingsOEMThemeValues, themeGenerated }: Props) {

  const [values, setValues] = useState({ valid: false, oem_id: 0, theme_id: 0, logo: '', icon: '', background: '' });
  const dispatch = useDispatch();

  useEffect(
    () => {
      setValues({ ...values, valid: false, background: '' });
    },
    [settingsOEMPreviewForceRefresh, settingsOEMThemeValues, themeGenerated]
  );

  if (settingsOEMThemeValues !== undefined && !values.valid) {
    if (settingsOEMThemeValues?.background !== undefined && settingsOEMThemeValues?.background !== '' && settingsOEMThemeValues?.background !== null) {
      (async () => {
        try {
          // const resizedWidth: number = await calculateWidthFromHeight(settingsOEMThemeValues.background, 425);
          // Resize to format 2048x1152 
          let croppedBase64String = await cropBase64ImageV2(settingsOEMThemeValues.background, 2048, 1152);
          setValues({ ...values, background: croppedBase64String, valid: true });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }

  const handleClickIcon = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: true });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickLogo = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: true });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickBackground = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: true });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickText = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickWidgetNormal = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }


  const handleClickWidgetHover = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickWidgetSelected = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickWidgetDisabled = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: true });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickMenuBar = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: true });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickNotification = () => {

    const advancedMode = store.getState().switchButtonEditor;
    if (advancedMode) {
      dispatch({ type: 'SET_SWITCH_BUTTON_EDITOR', value: false });
    }

    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: true });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  let previewScaleCtr = {}
  if (window.innerWidth <= 1400 && window.innerWidth >= 1200) {
    previewScaleCtr = { transform: 'scale(0.8)', transformOrigin: '0 0' }
  } else if (window.innerWidth <= 1199 && window.innerWidth >= 900) {
    previewScaleCtr = { transform: 'scale(0.6)', transformOrigin: '0 0' }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ minWidth: '100%' }}>
        <div style={previewScaleCtr}>
          <Paper style={
            { marginBottom: '10px', marginTop: '10px', minHeight: '470px', maxHeight: '560px', width: '950px', maxWidth: '950px' }
          }>
            {settingsOEMThemeValues !== undefined &&
              <Grid minHeight={'470px'} container spacing={0}>

                <Grid item xs={12}>
                  <Box border={1} borderColor={'#CCCCCC'}>

                    {(settingsOEMThemeValues.icon === '' || settingsOEMThemeValues.icon === null) &&
                      <AppBar onClick={handleClickIcon} position="static" style={{ cursor: 'pointer', boxShadow: 'none', minHeight: '25px', backgroundColor: '#fff' }}>
                        <Toolbar variant={'dense'} style={{ paddingLeft: '5px', marginRight: 10, minHeight: '25px' }}>
                          <img src={appIcon} alt="" style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                          <Typography variant='body1' style={{ cursor: 'default', marginLeft: 5, color: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#000') }}> {settingsOEMThemeValues.name} </Typography>
                        </Toolbar>
                      </AppBar>
                    }

                    {settingsOEMThemeValues.icon !== null && settingsOEMThemeValues.icon !== '' &&
                      <AppBar onClick={handleClickIcon} position="static" style={{ cursor: 'pointer', boxShadow: 'none', minHeight: '25px', backgroundColor: '#fff' }}>
                        <Toolbar variant={'dense'} style={{ paddingLeft: '5px', minHeight: '25px' }}>
                          <img src={settingsOEMThemeValues.icon} style={{ width: '20px', height: '20px', objectFit: 'cover' }} alt="" />
                          <Typography variant='body1' style={{ cursor: 'default', marginLeft: 5, color: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#000') }}> {settingsOEMThemeValues.name} </Typography>
                        </Toolbar>
                      </AppBar>
                    }
                  </Box>
                </Grid>

                <Grid item xs={12}>

                  <AppBar onClick={handleClickMenuBar} color='transparent' elevation={0} position="static" style={{ cursor: 'pointer', minHeight: '25px', backgroundColor: ((settingsOEMThemeValues?.data?.menu_bar_fill === undefined) ? '#fff' : `${settingsOEMThemeValues?.data?.menu_bar_fill}`) }}>
                    <Toolbar variant={'dense'} style={{ paddingLeft: '5px', minHeight: '25px' }}>
                      <Typography variant='body1' style={{ marginRight: 14, cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'CP'} </Typography>
                      <Typography variant='body1' style={{ marginRight: 14, cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'File'} </Typography>
                      <Typography variant='body1' style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'Windows'} </Typography>
                    </Toolbar>
                  </AppBar>

                </Grid>

                <Grid item xs={3}>
                  <Box height={'100%'} border={0}>
                    <Paper square style={{ padding: 19, minHeight: '425px', backgroundColor: ((settingsOEMThemeValues?.data?.background_color_primary === undefined) ? '' : `${settingsOEMThemeValues?.data?.background_color_primary}`) }}>
                      <Grid container spacing={4}>


                        <Grid item xs={12}>
                          {(settingsOEMThemeValues.logo === '' || settingsOEMThemeValues.logo === null) &&
                            <Grid onClick={handleClickLogo} style={{ cursor: 'pointer' }} >
                              <img src={applogo} style={{ width: '200px', height: '47px', objectFit: 'cover' }} alt="" />
                            </Grid>
                          }

                          {settingsOEMThemeValues.logo !== '' && settingsOEMThemeValues.logo !== null &&
                            <Grid onClick={handleClickLogo} >
                              <img src={settingsOEMThemeValues.logo} style={{ width: '200px', height: '47px', objectFit: 'cover', cursor: 'pointer' }} alt="" />
                            </Grid>
                          }
                        </Grid>


                        <Grid item xs={12}>
                          <Typography onClick={handleClickText} style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} align={'left'} variant='subtitle1'> {'Get Stared'} </Typography>
                          <Typography onClick={handleClickText} style={{ marginLeft: '12px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }} align={'left'} variant='subtitle2'> {'New Card'} </Typography>
                          <Typography onClick={handleClickText} style={{ marginLeft: '12px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} align={'left'} variant='subtitle2'> {'Recent Cards'} </Typography>
                          <Typography onClick={handleClickText} style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} align={'left'} variant='subtitle1'> {'What\'s New'} </Typography>
                          <Typography onClick={handleClickText} style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} align={'left'} variant='subtitle1'> {'Learning'} </Typography>
                          <Typography onClick={handleClickText} style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_disabled}`) }} align={'left'} variant='subtitle1'> {'Disabled Text'} </Typography>
                        </Grid>

                      </Grid>
                    </Paper>
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box height={'100%'}>

                    <Paper square style={{
                      padding: 20,
                      height: '100%',
                      backgroundColor: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '' : `${settingsOEMThemeValues?.data?.background_color_default}`),
                      backgroundImage: ((values.background === '') ? '' : `url(${values.background})`),
                      backgroundSize: 'cover'
                    }}>

                      <Grid container alignItems="center" justifyContent="center" spacing={2}>

                        <Grid item xs={12}>

                          <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                            <Toolbar variant={'dense'} style={{ paddingLeft: '0px', minHeight: '25px' }}>
                              <Button onClick={handleClickBackground} style={{ height: '30px', minWidth: '200px', boxShadow: 'none', border: '1px solid', borderColor: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#555555'), borderRadius: '0px', backgroundColor: '#FFFFFF', color: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#555555') }} variant="contained" startIcon={<img src={((settingsOEMThemeValues?.data?.background_color_default === undefined) ? backgroundImage : backgroundImage2)} style={{ position: "absolute", bottom: 4, left: 5 }} height={20} alt="" />}>
                                {values.background === '' &&
                                  <Typography variant={"button"} style={{ position: "absolute", bottom: 4, left: 35 }}>Add Background Image</Typography>
                                }
                                {values.background !== '' &&
                                  <Typography variant={"button"} style={{ position: "absolute", bottom: 4, left: 35 }}>Update Background Image</Typography>
                                }
                              </Button>
                              <Box justifyContent='flex-end' minWidth='74%' style={{ display: 'flex' }}>
                                <Typography onClick={handleClickText} variant='subtitle2' style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }}> {'Total cards : 2'} </Typography>
                              </Box>
                            </Toolbar>
                          </AppBar>
                        </Grid>

                        <Grid item xs={3}>
                          <Box border={1} style={{ marginBottom: '46px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                            <Box onClick={handleClickWidgetNormal} borderBottom={1} style={{ marginTop: '0px', minHeight: '15px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '3px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}> {'All cards'} </Typography>
                            </Box>
                            <Box onClick={handleClickWidgetSelected} borderBottom={1} style={{ marginTop: '0px', minHeight: '15px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '3px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }}> {'Contactless'} </Typography>
                            </Box>
                            <Box onClick={handleClickWidgetSelected} style={{ marginTop: '0px', minHeight: '15px', backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '6px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }}> {'Option 1'} </Typography>
                            </Box>
                            <Box onClick={handleClickWidgetSelected} style={{ marginTop: '0px', minHeight: '15px', backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '6px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }}> {'Option 2'} </Typography>
                            </Box>
                            <Box onClick={handleClickWidgetNormal} borderTop={1} style={{ marginTop: '41px', minHeight: '15px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '3px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}> {'Contactless'} </Typography>
                            </Box>
                            <Box onClick={handleClickWidgetNormal} borderTop={1} style={{ minHeight: '15px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                              <Typography variant='body2' style={{ marginLeft: '3px', cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}> {'Contact'} </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={9}>
                          <Box >
                            <Paper elevation={0} square style={{ marginTop: '17px', padding: 1, minHeight: '200px', minWidth: '200px', backgroundColor: 'transparent' }}>

                              <Grid container alignItems="center" justifyContent="center" spacing={1}>

                                <Grid item xs={4}>
                                  <Box border={1} style={{ cursor: 'pointer', marginLeft: '0px', width: '150px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                                    <Card onClick={handleClickWidgetNormal} square style={{ boxShadow: 'none', padding: 3, minHeight: '75px', minWidth: '90px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                                      <Grid marginTop={'10px'} container alignItems="center" justifyContent="center">
                                        <ButtonBase >
                                          {cr80Image &&
                                            <CardMedia style={{ height: '50px', width: '80px' }} image={cr80Image} title="cr80" component="img" />
                                          }
                                        </ButtonBase>
                                        <CardContent>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}>
                                            Name: CR80
                                          </Typography>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}>
                                            Size: CR80
                                          </Typography>
                                        </CardContent>
                                      </Grid>
                                    </Card>
                                  </Box>
                                </Grid>


                                <Grid item xs={4}>
                                  <Box border={1} style={{ cursor: 'pointer', width: '150px', borderColor: ((settingsOEMThemeValues?.data?.button_selected_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                                    <Card onClick={handleClickWidgetSelected} square style={{ boxShadow: 'none', padding: 3, minHeight: '75px', minWidth: '90px', backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                                      <Grid marginTop={'10px'} container alignItems="center" justifyContent="center">
                                        <ButtonBase  >
                                          {cr90ImageSelected &&
                                            <CardMedia style={{ height: '50px', width: '80px' }} image={cr90ImageSelected} title="cr90" component="img" />
                                          }
                                        </ButtonBase>
                                        <CardContent>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }}>
                                            Name: CR90
                                          </Typography>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }}>
                                            Size: CR90
                                          </Typography>
                                        </CardContent>
                                      </Grid>
                                    </Card>
                                  </Box>
                                </Grid>

                                <Grid item xs={4}>
                                  <Box border={1} style={{ cursor: 'pointer', width: '150px', borderColor: ((settingsOEMThemeValues?.data?.button_normal_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                                    <Card onClick={handleClickWidgetNormal} square style={{ boxShadow: 'none', padding: 3, minHeight: '75px', minWidth: '90px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`) }}>
                                      <Grid marginTop={'10px'} container alignItems="center" justifyContent="center">
                                        <ButtonBase >
                                          {cr80Image &&
                                            <CardMedia style={{ height: '50px', width: '80px' }} image={cr80Image} title="cr100" component="img" />
                                          }
                                        </ButtonBase>
                                        <CardContent>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}>
                                            Name: CR100
                                          </Typography>
                                          <Typography variant="body2" style={{ color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }}>
                                            Size: CR100
                                          </Typography>
                                        </CardContent>
                                      </Grid>
                                    </Card>
                                  </Box>
                                </Grid>

                              </Grid>

                            </Paper>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container alignItems="center" justifyContent="center" spacing={0}>

                            <Grid item xs={9}>
                              <Box style={{ paddingTop: 0 }}>
                                <Typography onClick={handleClickText} style={{ cursor: 'pointer', color: ((settingsOEMThemeValues?.data?.text_color_disabled === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_disabled}`) }} align={'left'} variant='subtitle1'> {'Disabled Text'} </Typography>
                                <Box style={{}}>
                                  <Button onClick={handleClickWidgetNormal} style={{ boxShadow: 'none', border: '1px solid #CCCCCC', maxHeight: '25px', maxWidth: '130px', borderRadius: '0px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data?.button_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_text}`) }} variant="contained" startIcon={<HomeIcon style={{ color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />}>
                                    {'Normal Button'}
                                  </Button>
                                  <Button onClick={handleClickWidgetHover} style={{ boxShadow: 'none', border: '1px solid #CCCCCC', marginLeft: '7px', maxHeight: '25px', maxWidth: '140px', borderRadius: '0px', backgroundColor: ((settingsOEMThemeValues?.data?.button_hover_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.button_hover_fill}`), color: ((settingsOEMThemeValues?.data?.button_hover_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_hover_text}`) }} variant="contained" startIcon={<HomeIcon style={{ color: ((settingsOEMThemeValues?.data?.button_hover_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_hover_icon}`) }} />}>
                                    {'Hover Button'}
                                  </Button>
                                </Box>
                                <Box style={{ marginTop: 10 }}>
                                  <Button onClick={handleClickWidgetSelected} style={{ boxShadow: 'none', border: '1px solid #CCCCCC', marginLeft: '0px', maxHeight: '25px', maxWidth: '140px', borderRadius: '0px', backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.button_selected_fill}`), color: ((settingsOEMThemeValues?.data?.button_selected_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_text}`) }} variant="contained" startIcon={<HomeIcon style={{ color: ((settingsOEMThemeValues?.data?.button_selected_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_icon}`) }} />}>
                                    {'Selected Button'}
                                  </Button>
                                  <Button onClick={handleClickWidgetDisabled} style={{ boxShadow: 'none', border: '1px solid #CCCCCC', marginLeft: '7px', maxHeight: '25px', maxWidth: '140px', borderRadius: '0px', backgroundColor: ((settingsOEMThemeValues?.data?.button_disabled_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.button_disabled_fill}`), color: ((settingsOEMThemeValues?.data?.button_disabled_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_disabled_text}`) }} variant="contained" startIcon={<HomeIcon style={{ color: ((settingsOEMThemeValues?.data?.button_disabled_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_disabled_icon}`) }} />}>
                                    {'Disabled Button'}
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={3}>
                              <Box style={{ cursor: 'pointer', marginTop: '20px', width: '100%', paddingTop: 0 }}>
                                <Box onClick={handleClickNotification} border={1} style={{ minHeight: '70px', width: '100%', borderColor: ((settingsOEMThemeValues?.data?.notification_popup_outline === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.notification_popup_outline}`), backgroundColor: ((settingsOEMThemeValues?.data?.notification_popup_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.notification_popup_fill}`) }}>
                                  <Typography variant="body1" style={{ marginLeft: '7px', color: ((settingsOEMThemeValues?.data?.notification_popup_title === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.notification_popup_title}`) }}>
                                    Notification Title
                                  </Typography>
                                  <Typography variant="body2" style={{ marginTop: '7px', marginLeft: '7px', color: ((settingsOEMThemeValues?.data?.notification_popup_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.notification_popup_text}`) }}>
                                    Notification body
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Paper>

                  </Box>
                </Grid>
              </Grid>
            }
            {settingsOEMThemeValues === undefined &&
              <CircularProgress />
            }
          </Paper>
        </div>
      </Box>
    </ThemeProvider>

  );
}

interface Props extends PropsFromRedux {

}

interface RootState {
  settingsOEMPreviewForceRefresh: boolean,
  settingsOEMThemeValues: { name: string, logo: string; icon: string; background: string, data: Data },
  themeGenerated: boolean,

}


const mapStateToProps = (state: RootState) => ({
  settingsOEMPreviewForceRefresh: state.settingsOEMPreviewForceRefresh,
  settingsOEMThemeValues: state.settingsOEMThemeValues,
  themeGenerated: state.themeGenerated,
})

const connector = connect(mapStateToProps)


type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SettingsOEMWelcomeView)