import React from 'react';
import { connect } from 'react-redux';
import { clearErrorMessageList } from './utils/Reducer';
import { gStyles } from './utils/Styles';

import { withStyles } from '@mui/styles';
// import { Container, Typography, Snackbar } from '@mui/material';
import { Typography, Container, Slide, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

import SignIn from './login/SignIn';
import Main from './Main';

function App(props) {

  const { classes } = props;
  const { authToken } = props;
  const { errorMessageList, clearErrorMessageList } = props;

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    clearErrorMessageList();
  };

  return (
    <Container className={classes.app} maxWidth='false'>
      {!authToken && <SignIn />}
      {authToken && <Main />}
      {errorMessageList.length > 0 && 
        <Dialog
          open={errorMessageList.length > 0}
          TransitionComponent={Transition}
          keepMounted
          onClose={clearErrorMessageList}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{'Message'}</DialogTitle>
          <DialogContent>
              {errorMessageList.map((item, index) => {
                return (
                  <Typography key={index} variant='body1' color='inherit'>{item}</Typography>
                )
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{'OK'}</Button>
          </DialogActions>
        </Dialog>
      }
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    authToken: state.authToken,
    errorMessageList: state.errorMessageList,
  }
};

export default connect(mapStateToProps, { clearErrorMessageList })(withStyles(gStyles)(App));
