import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributors, syncDistributors, createDistributor, deleteDistributor } from './DistributorRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { Paper, Typography, CircularProgress, Button, InputBase, IconButton } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Box, Grid, TextField, Tooltip } from '@mui/material';
import { Search, AddCircle, Delete } from '@mui/icons-material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings = new LocalizedStrings({
  en: {
    distributorsTitle: 'Distributors',

    buttonSyncDistributors: 'Sync distributors',
    buttonAddDistributor: 'Add distributor',

    tableHeaderDistributorId: 'ID',
    tableHeaderDistributorName: 'Distributor',
    tableHeaderDistributorEmail: 'Email',
    tableHeaderDistributorPhone: 'Phone Number',
    tableHeaderDistributorCustomers: '# Customers',
    tableHeaderDistributorLicenses: '# Licenses',
    tableHeaderDistributorPrinters: '# Printers',

    searchPlaceHolder: 'search',

    toolTipDeleteDistributor: 'Delete the distributor',


    // Dialogs
    // Sync Distributor Dialog 
    syncDistributorsDialogTitle: 'Sync Distributors Confirmation',
    syncDistributorsDialogText: 'Are you sure that you want to sync all distributors from the shcp database?',

    // Add Distributor Dialog 
    addDistributorDialogTitle: 'Distributor',
    defaultDistributorName: 'new distributor',
    labelName: 'Name',
    labelEmail: 'Email',
    labelPhone: 'Phone Number',
    labelURL: 'URL',

    // Delete Distributor Dialog 
    deleteDistributorDialogTitle: 'Delete Distributor Confirmation',
    deleteDistributorDialogText: 'Are you sure that you want to permanently delete distributor \'{entity}\'?',

    // Buttons dialog
    yes: 'Yes',
    no: 'No',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    // End Dialogs
  }
});

function DistributorTable(props) {

  const { classes } = props;

  const { distributorTableForceRefresh } = props;
  const { distributorList } = props;

  const [syncDistributorsMode, setSyncDistributorsMode] = useState(false);
  const [addDistributorMode, setAddDistributorMode] = useState(false);
  const [distributorToDelete, setDistributorToDelete] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);
  const [currentUrlSearch, setCurrentUrlSearch] = useState('');

  const { userType } = props;
  const { assignMode } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  useEffect(
    () => {
      setCurrentPage(parseInt(page))
      setCurrentRowsPerPage(parseInt(rowsPerPage))
    },
    []
  );

  const searchFromURL = searchParams.get('search') === null ? '' : searchParams.get('search');
  const [searchValue, setSearchValue] = useState('');
  const [values, setValues] = useState({});

  useEffect(
    () => {
      setSearchValue(searchFromURL);
      getDistributors(currentPage * currentRowsPerPage, currentRowsPerPage, currentUrlSearch);
    },
    [page, rowsPerPage, searchFromURL, distributorTableForceRefresh, currentPage, currentRowsPerPage, currentUrlSearch]
  );

  const searchKeyPress = event => {
    if (event.charCode === 13) {//ENTER key pressed
      event.preventDefault();
      handleSearch();
    }
  }

  const handleSearch = () => {
    var searchParams = new URLSearchParams(history.location.search);
    if (searchValue !== '') {
      searchParams.set('search', searchValue);
    } else { 
      searchParams.delete('search');
      searchParams.delete('page');
    }

    history.push({ search: searchParams.toString() });
    setCurrentPage(0);
    setCurrentUrlSearch(searchValue)
  }

  const handleSyncDistributorsDialogClose = confirm => {
    if (confirm) {
      syncDistributors();
      setSyncDistributorsMode(false);
    }
  }

  const handleAddDistributor = () => {
    setValues({ name: strings.defaultDistributorName, email: '', phone: '', url: '' });
    setAddDistributorMode(true);
  }

  const handleConfirmAddDistributor = (event) => {
    event.preventDefault();
    createDistributor(values);
    setAddDistributorMode(false);
  }

  const handleCancelAddDistributor = () => {
    setAddDistributorMode(false);
  }

  const handleDeleteDistributorDialogClose = confirm => {
    if (confirm) {
      deleteDistributor(distributorToDelete.distributor_id);
      setDistributorToDelete(undefined);
    }
  }

  const handleDeleteDistributor = distributor => {
    setDistributorToDelete(distributor);
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }

  return (
    <Paper className={classes.workarea}>
      <div className={classes.flexHeader}>
        <Typography variant='h4' className={classes.title}>{strings.distributorsTitle}</Typography>
        {/*
          ( userType === 0 ) && 
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleSyncDistributors( ) }>
            <Sync className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonSyncDistributors }</Typography>
          </Button>
        */}
        {
          (userType === 1) &&
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAddDistributor()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAddDistributor}</Typography>
          </Button>
        }

        <div className={classes.flexExpand} />
        <Paper className={classes.searchPaper}>
          <InputBase
            className={classes.searchInput}
            placeholder={strings.searchPlaceHolder}
            inputProps={{ 'aria-label': 'Search Text' }}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyPress={searchKeyPress} />
          <IconButton aria-label='Search' size='small' onClick={handleSearch}>
            <Search />
          </IconButton>
        </Paper>
      </div>

      <div className={classes.tableArea} >
        {distributorList !== undefined &&
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorEmail}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorPhone}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorCustomers}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorLicenses}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{strings.tableHeaderDistributorPrinters}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {distributorList.items.map(distributor => (
                <TableRow key={distributor.distributor_id}>
                  <TableCell>{distributor.distributor_id}</TableCell>
                  <TableCell>
                    <Link className={classes.link} to={'/distributors/' + distributor.distributor_id + '/details'}>
                      <Typography variant='body2'>{distributor.name}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{distributor.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{distributor.phone}</Typography>
                  </TableCell>

                  <TableCell>
                    <Link className={classes.link} to={'/customers?search=' + distributor.name}>
                      <Typography variant='body2'>{distributor.customers}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link className={classes.link} to={'/licenses?search=' + distributor.name}>
                      <Typography variant='body2'>{distributor.licenses}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link className={classes.link} to={'/printers?search=' + distributor.name}>
                      <Typography variant='body2'>{distributor.printers}</Typography>
                    </Link>
                  </TableCell>
                  {!assignMode && userType === 1 && distributor.customers === null && distributor.licenses === null && distributor.printers === null &&
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDeleteDistributor}>
                        <IconButton size='small' onClick={() => handleDeleteDistributor(distributor)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={7} count={distributorList.count} rowsPerPage={currentRowsPerPage} page={currentPage}
                  SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                  onPageChange={(event, newPage) => { handleChangePage(history, newPage); setCurrentPage(newPage); }}
                  onRowsPerPageChange={(event) => { handleChangeRowsPerPage(history, event, currentPage, currentRowsPerPage); setCurrentRowsPerPage(parseInt(event.target.value)) }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        {distributorList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </div>
      <Dialog open={syncDistributorsMode} onClose={() => handleSyncDistributorsDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.syncDistributorsDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.syncDistributorsDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleSyncDistributorsDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleSyncDistributorsDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addDistributorMode}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{strings.addDistributorTitleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirmAddDistributor(event)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' required id='name' disabled={!addDistributorMode}
                  label={strings.labelName} value={values.name} onChange={handleChange('name')}
                  inputProps={{ maxLength: fieldLimits.distributorUser.name.size }} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' id='email' disabled={!addDistributorMode}
                  label={strings.labelEmail} value={values.email} onChange={handleChange('email')}
                  inputProps={{ maxLength: fieldLimits.distributorUser.email.size }} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' id='phone' disabled={!addDistributorMode}
                  label={strings.labelPhone} value={values.phone} onChange={handleChange('phone')}
                  inputProps={{ maxLength: fieldLimits.distributorUser.phone.size }} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' id='url' disabled={!addDistributorMode}
                  label={strings.labelURL} value={values.url} onChange={handleChange('url')}
                  inputProps={{ maxLength: fieldLimits.distributorUser.url.size }} />
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.lineButtonBox}>
                  <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
                  <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleCancelAddDistributor()}>{strings.buttonCancel}</Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Dialog open={distributorToDelete !== undefined} onClose={() => handleDeleteDistributorDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDistributorDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDistributorDialogText.replace('{entity}', distributorToDelete !== undefined ? distributorToDelete.name : '')}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDistributorDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDistributorDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    distributorTableForceRefresh: state.distributorTableForceRefresh,
    distributorList: state.distributorList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(DistributorTable));