import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';

//import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, AppBar, Toolbar, Typography, Button, CircularProgress, Paper, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ContactlessIcon from '@mui/icons-material/Contactless';
import StorageIcon from '@mui/icons-material/Storage';

import appIcon from './../resources/preview/appIcon.png';

import cardTemplate from './../resources/cardTemplate.png';

/*const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

function HomeIcon( {...props} : Props) {
  return (
    <SvgIcon {...props}>
      <img src={testSVG} style={{ marginLeft: '13px'}} /> 
   </SvgIcon>
  );
}*/


const theme = createTheme({
  //v5.0.0
  typography: {
    body2: {
      fontSize: 9
    },
    body1: {
      fontSize: 12
    },
    button: {
      fontSize: 10,
      textTransform: "none",
      '&:hover': {
        hoverColor: "#fff",
      }
    }
  },
});



function SettingsOEMDesignView({ settingsOEMPreviewForceRefresh, settingsOEMThemeValues, themeGenerated }: Props) {

  const dispatch = useDispatch();


  useEffect(
    () => {
    },
    [settingsOEMPreviewForceRefresh, settingsOEMThemeValues, themeGenerated]
  );

  const handleClickIcon = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: true });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickText = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickWidgetNormal = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickWidgetSelected = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: true });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickMenuBar = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: true });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  const handleClickMenu = () => {
    if (themeGenerated) {
      dispatch({ type: 'SET_EDIT_SOFTWARE_ICON', value: false });
      dispatch({ type: 'SET_EDIT_SOFTWARE_LOGO', value: false });
      dispatch({ type: 'SET_EDIT_BACKGROUND_IMAGE', value: false });
      dispatch({ type: 'SET_EDIT_TEXT_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_NORMAL', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_HOVER', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_SELECTED', value: false });
      dispatch({ type: 'SET_EDIT_WIDGETS_COLORS_DISABLED', value: false });
      dispatch({ type: 'SET_EDIT_MENU_COLORS', value: true });
      dispatch({ type: 'SET_EDIT_MENU_BAR_COLORS', value: false });
      dispatch({ type: 'SET_EDIT_NOTIFICATION_COLORS', value: false });
    }
    else {
      dispatch({ type: 'SET_CLICK_NO_THEME_GENERATED', value: true });
    }
  }

  let previewScaleCtr = {}
  if (window.innerWidth <= 1400 && window.innerWidth >= 1200) {
    previewScaleCtr = { transform: 'scale(0.8)', transformOrigin: '0 0' }
  } else if (window.innerWidth <= 1199 && window.innerWidth >= 900) {
    previewScaleCtr = { transform: 'scale(0.6)', transformOrigin: '0 0' }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <div style={previewScaleCtr}>
          <Paper style={
            { marginBottom: '10px', marginTop: '10px', minHeight: '470px', maxHeight: '560px', width: '950px', maxWidth: '950px' }
          }>
            {settingsOEMThemeValues !== undefined &&
              <Grid container spacing={0}>

                <Grid item xs={12}>
                  <Box border={1} borderColor={'#CCCCCC'}>
                    {settingsOEMThemeValues.icon === '' &&
                      <AppBar onClick={handleClickIcon} position="static" style={{ boxShadow: 'none', minHeight: '25px', backgroundColor: '#fff' }}>
                        <Toolbar variant={'dense'} style={{ paddingLeft: '5px', marginRight: 10, minHeight: '25px' }}>
                          <img src={appIcon} height={20} width={20} alt="" />
                          <Typography variant='body1' style={{ cursor: 'default', marginLeft: 5, color: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#000') }}> {settingsOEMThemeValues.name} </Typography>
                        </Toolbar>
                      </AppBar>
                    }


                    {settingsOEMThemeValues.icon !== '' &&
                      <AppBar onClick={handleClickIcon} position="static" style={{ boxShadow: 'none', minHeight: '25px', backgroundColor: '#fff' }}>
                        <Toolbar variant={'dense'} style={{ paddingLeft: '5px', minHeight: '25px' }}>
                          <img src={settingsOEMThemeValues.icon} height={20} width={20} alt="" />
                          <Typography variant='body1' style={{ cursor: 'default', marginLeft: 5, color: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '#CCCCCC' : '#000') }}> {settingsOEMThemeValues.name} </Typography>
                        </Toolbar>
                      </AppBar>
                    }
                  </Box>
                </Grid>

                <Grid item xs={12}>

                  <AppBar onClick={handleClickMenuBar} color='transparent' elevation={0} position="static" style={{ minHeight: '25px', backgroundColor: ((settingsOEMThemeValues?.data?.menu_bar_fill === undefined) ? '#fff' : `${settingsOEMThemeValues?.data?.menu_bar_fill}`) }}>
                    <Toolbar variant={'dense'} style={{ paddingLeft: '5px', minHeight: '25px' }}>
                      <Typography variant='body1' style={{ cursor: 'pointer', marginRight: 14, color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'File'} </Typography>
                      <Typography variant='body1' style={{ cursor: 'pointer', marginRight: 14, color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'Edit'} </Typography>
                      <Typography variant='body1' style={{ cursor: 'pointer', marginRight: 14, color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'View'} </Typography>
                      <Typography variant='body1' style={{ cursor: 'pointer', marginRight: 14, color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'Tools'} </Typography>
                      <Box borderBottom={1} style={{ cursor: 'pointer', padding: 1, marginBottom: 0, marginTop: 3, marginRight: 14, borderColor: ((settingsOEMThemeValues?.data?.menu_normal_fill === undefined) ? '#fff' : `${settingsOEMThemeValues?.data?.menu_normal_fill}`), backgroundColor: ((settingsOEMThemeValues?.data?.menu_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.menu_selected_fill}`) }}>
                        <Typography variant='body1' style={{ marginLeft: '7px', marginRight: '7px', color: ((settingsOEMThemeValues?.data?.menu_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_normal_text}`) }}> {'Help'} </Typography>
                      </Box>
                    </Toolbar>
                  </AppBar>

                </Grid>

                <Grid item xs={12}>

                  <AppBar color='transparent' elevation={0} position="static" style={{ minHeight: '25px', backgroundColor: ((settingsOEMThemeValues?.data?.menu_bar_fill === undefined) ? '#fff' : `${settingsOEMThemeValues?.data?.menu_bar_fill}`) }}>
                    <Toolbar variant={'regular'} style={{ paddingLeft: '5px', minHeight: '25px' }}>
                      <Box onClick={handleClickWidgetNormal} style={{ cursor: 'pointer', borderColor: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.button_normal_outline}`) }}>
                        <Button size='small' style={{ borderRadius: '0px', maxWidth: '26px', minWidth: '26px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="outlined" disabled startIcon={<TextFieldsIcon style={{ marginLeft: '11px', color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />} />
                      </Box>
                      <Box onClick={handleClickWidgetNormal} style={{ cursor: 'pointer', marginLeft: '3px', borderColor: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.button_normal_outline}`) }}>
                        <Button size='small' style={{ borderRadius: '0px', maxWidth: '26px', minWidth: '26px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="outlined" disabled startIcon={<InsertInvitationIcon style={{ marginLeft: '11px', color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />} />
                      </Box>
                      <Box onClick={handleClickWidgetNormal} style={{ cursor: 'pointer', marginLeft: '3px', borderColor: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.button_normal_outline}`) }}>
                        <Button size='small' style={{ borderRadius: '0px', maxWidth: '26px', minWidth: '26px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="outlined" disabled startIcon={<FingerprintIcon style={{ marginLeft: '11px', color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />} />
                      </Box>
                      <Box onClick={handleClickWidgetNormal} style={{ cursor: 'pointer', marginLeft: '3px', borderColor: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.button_normal_outline}`) }}>
                        <Button size='small' style={{ borderRadius: '0px', maxWidth: '26px', minWidth: '26px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="outlined" disabled startIcon={<HomeIcon style={{ marginLeft: '11px', color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />} />
                      </Box>
                      <Box onClick={handleClickWidgetSelected} style={{ cursor: 'pointer', marginLeft: '3px', borderColor: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.button_selected_outline}`) }}>
                        <Button size='small' style={{ borderRadius: '0px', maxWidth: '26px', minWidth: '26px', backgroundColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_selected_fill}`), color: ((settingsOEMThemeValues?.data === null) ? '' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="outlined" disabled startIcon={<ContactlessIcon style={{ marginLeft: '11px', color: ((settingsOEMThemeValues?.data?.button_selected_icon === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_icon}`) }} />} />
                      </Box>
                      <Box onClick={handleClickMenu} border={1} style={{ cursor: 'pointer', minWidth: '130px', marginBottom: '10px', marginTop: '0px', marginLeft: '10px', borderColor: ((settingsOEMThemeValues?.data?.menu_normal_fill === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_normal_fill}`), backgroundColor: ((settingsOEMThemeValues?.data?.menu_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.menu_normal_fill}`) }}>
                        <Box style={{ backgroundColor: ((settingsOEMThemeValues?.data?.menu_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.menu_normal_fill}`) }}>
                          <Typography variant='body1' style={{ marginLeft: '5px', color: ((settingsOEMThemeValues?.data?.menu_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_normal_text}`) }}> {'Help Guide'} </Typography>
                        </Box>
                        <Box style={{ cursor: 'pointer', backgroundColor: ((settingsOEMThemeValues?.data?.menu_normal_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.menu_normal_fill}`) }}>
                          <Typography variant='body1' style={{ marginLeft: '5px', marginRight: '5px', color: ((settingsOEMThemeValues?.data?.menu_normal_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_normal_text}`) }}> {'Technotes'} </Typography>
                        </Box>
                        <Box style={{ cursor: 'pointer', backgroundColor: ((settingsOEMThemeValues?.data?.menu_disabled_fill === undefined) ? '' : `${settingsOEMThemeValues?.data?.menu_disabled_fill}`) }}>
                          <Typography variant='body1' style={{ marginLeft: '5px', marginRight: '5px',  color: ((settingsOEMThemeValues?.data?.menu_disabled_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_disabled_text}`) }}> {'About ' + settingsOEMThemeValues.name} </Typography>
                        </Box>
                      </Box>
                    </Toolbar>
                  </AppBar>

                </Grid>

                <Grid container>
                  
                  <Grid item xs={9}>
                    <Grid container>

                      <Grid item xs={12}>
                        <Box style={{ minHeight: '100%' }}>
                          <Paper square style={{ paddingTop: '15px', paddingBottom: '15px', minHeight: '100px', minWidth: '100%', backgroundColor: '#A9A9A9' }}>
                            <Grid container alignItems="center" justifyContent="center">
                              <img src={cardTemplate} height={'180px'} alt='' />
                            </Grid>
                          </Paper>

                          <Paper square style={{ paddingTop: '25px', minHeight: '162px', minWidth: '100%', backgroundColor: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '' : `${settingsOEMThemeValues?.data?.background_color_default}`) }}>
                            <Stack alignItems="center" justifyContent="center">
                              <Button onClick={handleClickWidgetNormal} style={{ boxShadow: 'none', border: '1px solid #CCCCCC', maxWidth: '26px', minWidth: '26px', borderRadius: '0px', backgroundColor: ((settingsOEMThemeValues?.data?.button_normal_fill === undefined) ? '#FFFFFF' : `${settingsOEMThemeValues?.data?.button_normal_fill}`), color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }} variant="contained" startIcon={<StorageIcon style={{ marginLeft: '13px', color: ((settingsOEMThemeValues?.data?.button_normal_icon === undefined) ? '' : `${settingsOEMThemeValues?.data?.button_normal_icon}`) }} />} />
                              <Typography onClick={handleClickWidgetNormal} variant='body1' style={{ cursor: 'default', marginRight: 14, marginTop: 5, color: ((settingsOEMThemeValues?.data?.menu_bar_text === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.menu_bar_text}`) }}> {'Connect to database'} </Typography>
                            </Stack>
                          </Paper>
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Paper square style={{ minHeight: '100%', minWidth: '100%', backgroundColor: ((settingsOEMThemeValues?.data?.background_color_default === undefined) ? '' : `${settingsOEMThemeValues?.data?.background_color_default}`) }}>

                      <Box border={1} style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px', paddingBottom: '20px', paddingTop: '10px', borderColor: ((settingsOEMThemeValues?.data?.button_selected_fill === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.button_selected_fill}`) }}>
                        <Typography onClick={handleClickText} variant='subtitle2' style={{ marginBottom: 5, marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }}> {'Doc Information:'} </Typography>
                        <Typography onClick={handleClickText} variant='body1' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }}> {'File Size:'} </Typography>
                        <Typography onClick={handleClickText} variant='body2' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }}> {'New'} </Typography>
                        <Typography onClick={handleClickText} variant='body1' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }}> {'File Name:'} </Typography>
                        <Typography onClick={handleClickText} variant='body2' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }}> {'New'} </Typography>
                        <Typography variant='body1' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_primary === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_primary}`) }}> {'Created:'} </Typography>
                        <Typography onClick={handleClickText} variant='body2' style={{ marginLeft: 5, color: ((settingsOEMThemeValues?.data?.text_color_default === undefined) ? '#CCCCCC' : `${settingsOEMThemeValues?.data?.text_color_default}`) }}> {'New'} </Typography>
                      </Box>

                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            }
            {settingsOEMThemeValues === undefined &&
              <CircularProgress />
            }
          </Paper>
        </div>
      </Box>
    </ThemeProvider>

  );
}

interface Props extends PropsFromRedux {

}

interface RootState {
  settingsOEMPreviewForceRefresh: boolean,
  settingsOEMThemeValues: { name: string, logo: string; icon: string; background: string, data: Data },
  themeGenerated: boolean,
}


const mapStateToProps = (state: RootState) => ({
  settingsOEMPreviewForceRefresh: state.settingsOEMPreviewForceRefresh,
  settingsOEMThemeValues: state.settingsOEMThemeValues,
  themeGenerated: state.themeGenerated
})

const connector = connect(mapStateToProps)


type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SettingsOEMDesignView)
