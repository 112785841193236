import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMUpgrades, setSettingsOEMProducts, getSettingsOEMEditions, deleteSettingsOEMEdition } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@mui/material';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Tooltip, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit, AddCircle, Delete } from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';
import backgroundImage from './../resources/icons/backgroundImage_iconButton.png';

let strings = new LocalizedStrings({
  en: {
    title: 'OEM Plugin',
    titleDialog: 'Edit Plugin',
    titleItemDialog: 'New Plugin',

    tableHeaderCode: 'Product Code',
    tableHeaderBase: 'Product Base',
    tableHeaderPublic: 'Product Public',
    tableHeaderDescription: 'Product Description',
    tableHeaderPicture: 'Picture',
    tableHeaderLink: 'Find out more/Link',
    toolTipEditPicture: 'Click the picture to edit',

    labelCode: 'Product Code',
    labelBase: 'Product Base',
    labelPublic: 'Product Public',
    labelDescription: 'Description',
    labelLink: 'Find out more/Link',

    buttonAdd: 'Add Plugin',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',

    toolTipEdit: 'Edit Plugin',
    toolTipDelete: 'Delete Plugin',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this Plugin?',
    yes: 'Yes',
    no: 'No',
  }
})

function SettingsOEMDetailPlugins(props) {

  const { classes } = props;

  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});
  const [pluginToDelete, setPluginToDelete] = useState(undefined);
  const [state, setState] = useState({ hover: false });
  const { settingsOEMUpgradesTableForceRefresh } = props;
  const { settingsOEMPluginsList } = props;
  const { settingsOEMEditionsList } = props;
  const { oemId } = useParams();
  const [newItem, setNewItem] = useState(false);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    () => {
      getSettingsOEMUpgrades(page * rowsPerPage, rowsPerPage, oemId);
      if (settingsOEMEditionsList === undefined) {
        getSettingsOEMEditions(0, 9999, oemId);
      }
    },
    [page, rowsPerPage, settingsOEMUpgradesTableForceRefresh, oemId]
  );

  const mouseOver = () => {
    setState({ hover: true });
  }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const handleChange = name => event => {

    switch (name) {      
      case 'code': {
        setValues({ ...values, code: event.target.value });
        break;
      }
      case 'base': {
        setValues({ ...values, base: event.target.value });
        break;
      }
      case 'title': {
        setValues({ ...values, title: event.target.value });
        break;
      }
      case 'description': {
        setValues({ ...values, description: event.target.value });
        break;
      }
      case 'link': {
        setValues({ ...values, link: event.target.value });
        break;
      }
      

      default:
        break;
    }
  };

  const handleAdd = () => {
    setEditMode(true);
    setNewItem(true);
    setValues({
      oem_id: oemId,
      oldCode: '',
      code: '',
      base: '',
      title: '',
      description: '',
      picture: '',
      link: ''
    });
  }

  const handleEdit = plugin => {
    setEditMode(true);
    setNewItem(false);
    setValues({
      oem_id: oemId,
      oldCode: plugin.product_code,
      code: plugin.product_code,
      base: plugin.product_base,
      title: plugin.product_public,
      description: plugin.product_description,
      picture: (plugin.product_picture === null) ? '' : plugin.product_picture,
      link: plugin.product_link === null ? '' : plugin.product_link
    });
  };

  const handleDeleteDialogClose = confirm => {
    if (confirm) {
      deleteSettingsOEMEdition(pluginToDelete);
      setPluginToDelete(undefined);
    } else {
      setPluginToDelete(undefined);
    }
  }

  const handleDelete = edition => {
    setPluginToDelete(edition);
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setEditMode(false);
    setSettingsOEMProducts(values);
  };

  const startNewFile = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(file);
  }
  const onPictureEdit = (event) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, picture: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  return (
    <Box>
      <Paper className={classes.workareaTopBoxes}>
        <Box className={classes.flexHeader}>
          <Typography variant='h4' className={classes.title}>{strings.title}</Typography>
          <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleAdd()}>
            <AddCircle className={classes.leftIcon} />
            <Typography variant='button'>{strings.buttonAdd}</Typography>
          </Button>
        </Box>
        {settingsOEMPluginsList !== undefined &&
          <Box className={classes.tableArea}>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderCode}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderBase}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPublic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderPicture}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderLink}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsOEMPluginsList.items.map((plugin, index) => (
                  <TableRow key={index}>
                    <TableCell >{plugin.product_code}</TableCell>
                    <TableCell >{plugin.product_base}</TableCell>
                    <TableCell >{plugin.product_public}</TableCell>
                    <TableCell >{plugin.product_description}</TableCell> 
                    <TableCell style={{ maxWidth: '48px', width: '48px' }}>
                      <Tooltip 
                        title={state.hover ? (<img src={(plugin.product_picture !== undefined && plugin.product_picture !== null ? plugin.product_picture : backgroundImage)} alt="" style={{ maxWidth: '200px', width: '200px' }} />) : ''} alt=""
                      >
                        <Icon style={{ width: '100%', height: 'auto' }} onMouseOver={mouseOver} onMouseOut={mouseOut} alt="">
                          <img src={(plugin.product_picture !== undefined && plugin.product_picture !== null ? plugin.product_picture : backgroundImage)} width="100%" height="auto"  alt="" />
                        </Icon>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: '25%' }}>{plugin.product_link}</TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipEdit}>
                        <IconButton size='small' onClick={() => handleEdit(plugin)} >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableButton} >
                      <Tooltip title={strings.toolTipDelete}>
                        <IconButton size='small' onClick={() => handleDelete(plugin)} >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={9} count={settingsOEMPluginsList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => handleChangePage(history, newPage)}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(history, event, page, rowsPerPage)}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        {settingsOEMPluginsList === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper>

      <Dialog open={editMode} style={{ zIndex: 0, height: '900px' }}>
        <Box className={classes.workarea}>
          <Typography variant='h4' className={classes.title}>{newItem ? strings.titleItemDialog : strings.titleDialog}</Typography>
          <form className={classes.form} autoComplete="off" onSubmit={(event) => handleConfirm(event)}>

            <Grid container spacing={2}>

              <Grid item xs={4}>

                {values.picture === '' &&
                  <Grid item xs={12}>
                    <FormControl variant='outlined'>
                      <DropzonePic onFileAdded={(file) => { startNewFile(file) }} />
                    </FormControl>
                  </Grid>
                }
                {values.picture !== '' &&
                  <Grid item xs={12}>
                    <FormControl variant='outlined' style={{ width: '100%', height: 'auto' }} onClick={handleEditPicClick}>
                      <img src={values.picture} style={{ width: '100%', height: 'auto' }} alt="" />
                      <input type='file' accept='.png' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onPictureEdit(file) }} />
                      <FormControl><Typography variant='body1' style={{ fontStyle: "italic", fontSize: "13px" }}>{strings.toolTipEditPicture}</Typography></FormControl>
                    </FormControl>

                  </Grid>
                }

              </Grid>

              <Grid item xs={8}>

                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <TextField fullWidth variant='outlined' margin='dense' id='code'
                      label={strings.labelCode} value={values.code} onChange={handleChange('code')} required />
                  </Grid>

                  <Grid item xs={12}>
                    {settingsOEMEditionsList !== undefined &&
                      <FormControl fullWidth>
                        <InputLabel id="base-select-label">{strings.labelBase}</InputLabel>
                        <Select
                          labelId="base-select-label"
                          id="base"
                          value={values.base}
                          label={strings.labelBase}
                          onChange={handleChange('base')}
                          required
                        >
                          {settingsOEMEditionsList.items.map((edition, index) => (
                            <MenuItem key={index} value={edition.product_code}>{edition.product_public} ({edition.product_code})</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </Grid>

                </Grid>

              </Grid>



              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='title'
                  label={strings.labelPublic} value={values.title} onChange={handleChange('title')} required />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' multiline maxRows={4} id='description' inputProps={{ maxLength: 255 }}
                  label={strings.labelDescription} value={values.description} onChange={handleChange('description')} />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
              </Grid>

            </Grid>

            <Box className={classes.lineButtonBox}>
              <Button className={classes.lineButton} variant='contained' color='secondary' type='submit'>{strings.buttonConfirm}</Button>
              <Button className={classes.lineButton} variant='contained' color='secondary' onClick={() => handleClose()}>{strings.buttonCancel}</Button>
            </Box>
          </form>
        </Box>
      </Dialog>
      <Dialog open={pluginToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMUpgradesTableForceRefresh: state.settingsOEMUpgradesTableForceRefresh,
    settingsOEMPluginsList: state.settingsOEMPluginsList,
    settingsOEMEditionsList: state.settingsOEMEditionsList
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(SettingsOEMDetailPlugins));