import React from 'react';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';

import SettingsOEM from './SettingsOEM';

function SettingsOEMAdminDashboard( props ) {
  
  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SettingsOEM/>
        </Grid> 
      </Grid>
    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( SettingsOEMAdminDashboard ) );
