import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { addErrorMessage, setChangePass } from '../utils/Reducer';
import { changePassword } from './LoginRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@mui/styles';import { Avatar, Button, TextField, Typography, Container, InputAdornment, IconButton } from '@mui/material';
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

let strings=new LocalizedStrings({
	en:{
    title: 'Change Password',
    
    textFieldNewPassword: 'New Password',
    textFieldConfirmPassword: 'Confirm Password',
    
    buttonChange: 'Change Password',
    buttonCancel: 'Cancel',

    emptyPasswordError: 'Password can not be empty!',
    passwordMismatchError: 'Passwords must match!',
	}
})

function ChangePassword( props ) {

  const { classes } = props;

  const [ values, setValues ] = useState( { newPassword: '', confirmPassword: '', showNewPassword: false, showConfirmPassword: false } );

  const { addErrorMessage } = props;
  const { changePass, setChangePass } = props;
  
  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const onSubmitClick = ( ) => {
    if ( values.newPassword === '' ) {
      addErrorMessage( strings.emptyPasswordError );
      return;
    }
    if ( values.newPassword !== values.confirmPassword ) {
      addErrorMessage( strings.passwordMismatchError );
      return;
    }
    changePassword( values.newPassword );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmitClick();
    }
  };

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  return (
    <Container maxWidth='xs'>
      <div className={ classes.signInArea }>
        <Avatar className={ classes.signInIcon }>
          <LockOutlined/>
        </Avatar>
        <Typography component='h1' variant='h5'>{ strings.title }</Typography>
        <form className={ classes.form } noValidate>

          <TextField variant='outlined' margin='normal' required fullWidth id='newPassword' type={ !values.showNewPassword? 'password' : 'text' }
            label={ strings.textFieldNewPassword } value={ values.newPassword } onChange={ handleChange('newPassword') }    
            onKeyDown={handleKeyPress}
            InputProps={ {
              inputProps: { maxLength: fieldLimits.signIn.password.size },
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={ ( ) => handleClickShowNewPassword( ) }>
                    { values.showNewPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
            } }
          />

          <TextField variant='outlined' margin='normal' required fullWidth id='confirmPassword' type={ !values.showConfirmPassword? 'password' : 'text' }
            label={ strings.textFieldConfirmPassword } value={ values.confirmPassword } onChange={ handleChange('confirmPassword') }    
            onKeyDown={handleKeyPress}
            InputProps={ {
              inputProps: { maxLength: fieldLimits.signIn.password.size },
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={ ( ) => handleClickShowConfirmPassword( ) }>
                    { values.showConfirmPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
            } }
          />
          <Button className={ classes.formSubmit } fullWidth variant='contained' color='secondary'
            onClick={ ( ) => onSubmitClick( ) }>{ strings.buttonChange }
          </Button>
        </form>
        { changePass &&
          <Button className={ classes.formSubmit } variant='contained' color='secondary'
            onClick={ ( ) => setChangePass( false ) }>{ strings.buttonCancel }
          </Button>
        }
      </div>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    changePass: state.changePass,
  }
};

export default connect( mapStateToProps, { addErrorMessage, setChangePass } )( withStyles( gStyles )( ChangePassword ) );