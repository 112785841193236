import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorSubDistributors } from './DistributorRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles'; import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { Paper, Typography, InputBase, IconButton, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings = new LocalizedStrings({
  en: {
    tableHeaderSubDistributorId: 'ID',
    tableHeaderSubDistributorName: 'Sub Distributor',
    tableHeaderSubDistributorEmail: 'Email',
    tableHeaderSubDistributorPhone: 'Phone Number',
    tableHeaderSubDistributorCustomers: '# Customers',
    tableHeaderSubDistributorLicenses: '# Licenses',
    tableHeaderSubDistributorPrinters: '# Printers',

    searchPlaceHolder: 'search'
  }
});

function DistributorSubDistributorTable(props) {

  const { classes } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);
  const [currentUrlSearch, setCurrentUrlSearch] = useState('');

  const { distributorSubDistributorTableForceRefresh } = props;
  const { distributorSubDistributorList } = props;
  const { distributorId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40) ? 20 : urlRowsPerPage;

  const searchFromURL = searchParams.get('search') === null ? '' : searchParams.get('search');
  const [searchValue, setSearchValue] = useState('');

  useEffect(
    () => {
      setCurrentPage(parseInt(page));
      setCurrentRowsPerPage(parseInt(rowsPerPage));
      setCurrentUrlSearch(searchFromURL);
    },
    [searchFromURL, page, rowsPerPage]
  );

  useEffect(
    () => {
      setSearchValue(currentUrlSearch); 
      getDistributorSubDistributors(distributorId, currentPage * currentRowsPerPage, currentRowsPerPage, currentUrlSearch);
    },
    [distributorId, currentPage, currentRowsPerPage, currentUrlSearch, distributorSubDistributorTableForceRefresh]
  );

  const searchKeyPress = event => {
    if (event.charCode === 13) {//ENTER key pressed
      event.preventDefault();
      handleSearch();
    }
  }
 
  const handleSearch = () => {
    var searchParams = new URLSearchParams(history.location.search);
    if (searchValue !== '') {
      searchParams.set('search', searchValue);
    } else {
      searchParams.delete('search');
      searchParams.delete('page');
    }

    history.push({ search: searchParams.toString() });
    setCurrentPage(0);
    setCurrentUrlSearch(searchValue)
  }

  return (
    <div>
      <div className={classes.workarea}>
        <div className={classes.flexHeader}>
          <div className={classes.flexExpand} />
          <Paper className={classes.searchPaper}>
            <InputBase
              className={classes.searchInput}
              placeholder={strings.searchPlaceHolder}
              inputProps={{ 'aria-label': 'Search Text' }}
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              onKeyPress={searchKeyPress} />
            <IconButton aria-label='Search' size='small' onClick={handleSearch}>
              <Search />
            </IconButton>
          </Paper>
        </div>
        <div className={classes.tableArea} >
          {distributorSubDistributorList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorEmail}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorPhone}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorCustomers}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorLicenses}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{strings.tableHeaderSubDistributorPrinters}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distributorSubDistributorList.items.map((subDistributor, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant='body2'>{subDistributor.distributor_id}</Typography>
                    </TableCell>
                    <TableCell>
                      {<Link className={classes.link} to={'/distributors/' + subDistributor.distributor_id + '/details'}>
                        <Typography variant='body2'>{subDistributor.name}</Typography>
                      </Link>}
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{subDistributor.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{subDistributor.phone}</Typography>
                    </TableCell>
                    <TableCell>
                      <Link className={classes.link} to={'/customers?search=' + subDistributor.name}>
                        <Typography variant='body2'>{subDistributor.customers}</Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link className={classes.link} to={'/licenses?search=' + subDistributor.name}>
                        <Typography variant='body2'>{subDistributor.licenses}</Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link className={classes.link} to={'/printers?search=' + subDistributor.name}>
                        <Typography variant='body2'>{subDistributor.printers}</Typography>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={7} count={distributorSubDistributorList.count} rowsPerPage={currentRowsPerPage} page={currentPage}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onPageChange={(event, newPage) => { handleChangePage(history, newPage); setCurrentPage(newPage); }}
                    onRowsPerPageChange={(event) => { handleChangeRowsPerPage(history, event, currentPage, currentRowsPerPage); setCurrentRowsPerPage(parseInt(event.target.value)) }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          {distributorSubDistributorList === undefined &&
            <CircularProgress className={classes.spinner} />
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    distributorSubDistributorTableForceRefresh: state.distributorSubDistributorTableForceRefresh,
    distributorSubDistributorList: state.distributorSubDistributorList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(DistributorSubDistributorTable));