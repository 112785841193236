import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

import Dropzone from './Dropzone';
import { CheckCircleOutline } from '@mui/icons-material';

let strings=new LocalizedStrings({
	en:{

    step1Label: 'Select file',
    step1Optional:'Drop file here or click',
    step2Label: 'Upload file',
    step3Label: 'Confirmation',

    next: 'Next',
    back: 'Back',
    close: 'Close',
    cancel: 'Cancel',

    wrongFileMsg: 'Please select a csv file',
    duplicateHeaderMsg: 'Duplicate header, \'{header}\'',
    unknownHeaderMsg: 'Unknown header, \'{header}\'',
    missingHeaderMsg: 'Missing \'{header}\' header',
    
    errorsTitleFuture: 'The following lines will not be applied:',
    errorsTitlePast: 'The following lines were not applied:',
    fieldsCounterMsg: '{line} - Wrong number of fields',
    fieldMaxLengthMsg: '{line} - {header} length is longer than {max}',
    fieldMandatoryMsg: '{line} - {header} can not be empty',
    serverInternalErrorMsg: '{line} - Server internal error',

    okMsg: '{total} lines applied!!',
	}
})

function UploadCsvDialog( props ) { 

  const { classes } = props;

  const [ activeStep, setActiveStep ] = useState( 0 );
  const [ step1Error, setStep1Error ] = useState( false );
  const [ step1ErrorLabel, setStep1ErrorLabel ] = useState( '' );
  const [ step1Value, setStep1Value ] = useState( undefined );
  const [ step2Optional, setStep2Optional ] = useState( '' );

  const [ errors, setErrors] = useState( [] );
  const [ finalList, setFinalList ] = useState( [] );
  const [ uploadProgress, setUploadProgress ] = useState( 0 );
  
  const perIteration = 100;
  var okSoFar = 0;
  var iteration = 0;
  var callBackErrors = [];
  
  const startNewFile = ( file ) => {
    
    if ( file !== undefined && file.type !== undefined && file.type !== 'application/vnd.ms-excel' && file.type !== "text/csv" ) {
      setStep1Value( undefined );
      setStep1Error( true );
      setStep1ErrorLabel( strings.step1Label + ' - ' + strings.wrongFileMsg );
    }
    else {
      setStep1Value( file );
      setStep1Error( false );
      setStep1ErrorLabel( '' );
    }
    setErrors( [] );
  }

  const handleNext = () => {
    switch ( activeStep ) {
      case 0:
        if ( step1Value === undefined ) {
          setStep1Error( true );
          setStep1ErrorLabel( strings.step1Label + ' - ' + strings.wrongFileMsg );
        }
        else
          onValidateFile();
        break;
      case 1:
        setUploadProgress( 1 );
        callBackErrors = [];
        props.uploadRest( finalList.slice( 0, perIteration), uploadCallback );
        break;
      case 2:
        handleCloseDialog();
        break;
      default: break;
    }
  }

  const handleBack = () => {
    switch ( activeStep ) {
      case 0:
        handleCloseDialog();
        break;
      case 1:
        setUploadProgress( 0 );
        setErrors( [] );
        setFinalList( [] );
        setActiveStep( activeStep - 1 );
        break;
      default:
        break;
    }
  }

  const onValidateFile = () => {
    var reader = new FileReader();
    reader.onload = function( event ) {
      var exception = {};
      try {
        var headerSize = 0;
        var iErrors = [];

        event.target.result.split( '\r\n' ).forEach( ( line, lineIndex ) => {

          //process HEADER line
          if ( lineIndex === 0 ) {
            for ( var headerIndex = 0; headerIndex < props.headers.length; headerIndex++ )
              props.headers[ headerIndex ].index = undefined;

            line.split( ';' ).forEach( ( element, elementIndex ) => {

              headerSize++;
              var found = false;
              for ( headerIndex = 0; headerIndex < props.headers.length; headerIndex++ ) {
                var header = props.headers[ headerIndex ];
                if ( element === header.name ) {
                  if ( header.index !== undefined ) {
                    exception = { msg: strings.duplicateHeaderMsg.replace( '{header}', element ) };
                    throw exception;
                  }
                  header.index = elementIndex;
                  found = true;
                  break;
                }
              }
              //unknown header
              if ( !found ) {
                exception = { msg: strings.unknownHeaderMsg.replace( '{header}', element ) };
                throw exception;
              }
            } );

            //check for mandatory headers
            for ( headerIndex = 0; headerIndex < props.headers.length; headerIndex++ ) {
              var header = props.headers[ headerIndex ];
              if ( header.mandatory === true && header.index === undefined ) {
                exception = { msg: strings.missingHeaderMsg.replace( '{header}', header.name ) };
                throw exception;
              }
            }
          }

          //process other lines
          else {
            var elements = line.split( ';' );
            var element = {};
            var elementOK = true;

            if ( elements.length !== headerSize ) {
              iErrors = [ ...iErrors, { msg: strings.fieldsCounterMsg.replace( '{line}', ( lineIndex + 1 ) ) } ];
              setErrors( iErrors );
              elementOK = false;
            }
            else {
              for ( headerIndex = 0; headerIndex < props.headers.length; headerIndex++ ) {
                header = props.headers[ headerIndex ];
                if ( header.index !== undefined ) {
                  if ( header.maxLength !== undefined && elements[ header.index ].length > header.maxLength ) {
                    iErrors = [ ...iErrors, { msg: strings.fieldMaxLengthMsg.replace( '{header}', header.name ).replace( '{max}', header.maxLength ).replace( '{line}', ( lineIndex + 1 ) ) } ];
                    setErrors( iErrors );
                    elementOK = false;
                  }
                  else if ( header.mandatory === true && elements[ header.index ].length <= 0 ) {
                    iErrors = [ ...iErrors, { msg: strings.fieldMandatoryMsg.replace( '{header}', header.name ).replace( '{line}', ( lineIndex + 1 ) ) } ];
                    setErrors( iErrors );
                    elementOK = false;
                  }
                  else if ( elements[ header.index ].length > 0 ) {
                    element[ header.name ] = elements[ header.index ];
                  }
                }
              }
            }
            if ( elementOK )
              finalList.push( element );
          }
        } )
        setActiveStep( activeStep + 1 );
      }
      catch( exception ) {
        setStep1Error( true );
        setStep1ErrorLabel( strings.step1Label + ' - ' + exception.msg );
      }
    }
    reader.readAsText( step1Value );
  }

  const uploadCallback = response => {

    for ( var msgIndex = 0; msgIndex < response.length; msgIndex++ ) {
      var msg = response[ msgIndex ];
      var specError = props.checkSpecError( msg.msg );
      if ( specError !== undefined ) {
        callBackErrors = [ ...callBackErrors, { msg: specError.replace( '{line}', ( ( iteration * perIteration ) + msg.line + 2 ) ) } ];
      }
      else if ( msg.msg === 'SERVER_INTERNAL_ERROR' ) {
        callBackErrors = [ ...callBackErrors, { msg: strings.serverInternalErrorMsg.replace( '{line}', ( ( iteration * perIteration ) + msg.line + 2 ) ) } ];
      }
      else if ( msg.msg === 'OK' ) {
        okSoFar += msg.total;
      }
    }
    
    iteration++;
    if ( ( iteration * perIteration ) >= finalList.length ) {
      setStep2Optional( strings.okMsg.replace( '{total}', okSoFar ) );
      setErrors( [ ...errors, ...callBackErrors ] );
      setUploadProgress( 100 );
      props.refresh( );
      setActiveStep( activeStep + 1 );
    }
    else {
      setErrors( [ ...errors, ...callBackErrors ] );
      setUploadProgress( okSoFar * 100 / finalList.length );
      props.uploadRest( finalList.slice( ( iteration * perIteration ), ( iteration * perIteration ) + perIteration ), uploadCallback );
    }
  }



  const handleCloseDialog = () => {
    props.onClose( );
    setActiveStep( 0 );
    startNewFile( undefined ); //setStep1Value, setStep1Error, setStep1ErrorLabel
    setStep2Optional( '' );
    setErrors( [] );
    setFinalList( [] );
    setUploadProgress( 0 );
  }


  return (
    <Dialog maxWidth='md' open={ props.open }>
      <DialogTitle className={ classes.title }>{ props.title }</DialogTitle>
      <DialogContent style={ { width: '500px', height: '490px' } }>
        <Stepper activeStep={ activeStep } orientation='vertical'>
            <Step>
              <StepLabel error={ step1Error }
                optional={ 
                  activeStep === 0 ?
                  <Typography variant='body1'> { strings.step1Optional + (step1Value !== undefined ? ' - ' + step1Value.name : '') } </Typography> :
                  <Typography variant='body1'> { step1Value.name } </Typography>
                }>
                <Typography variant='body1'>{ step1Error ? step1ErrorLabel : strings.step1Label }</Typography>
              </StepLabel>
              <StepContent>
                <Dropzone onFileAdded={ ( file ) => { startNewFile( file ) } } />
              </StepContent>
            </Step>
            <Step>
              <StepLabel optional={ activeStep > 1 ? <Typography variant='body1'>{ step2Optional }</Typography> : ' ' }>
                <Typography variant='body1'>{ strings.step2Label }</Typography>
              </StepLabel>
              <StepContent>
                <div className={ classes.uploadFileInfo }>
                  <div className={ classes.uploadProgressWrapper }>
                    <div className={ classes.uploadProgressBar }>
                      <div className={ classes.uploadProgress } style={ { width: uploadProgress + "%" } } />
                    </div>
                    <CheckCircleOutline alt='' style={ { opacity: uploadProgress === 100 ? 0.5 : 0.05 } }/>
                  </div>
                </div>
                <div>
                  { errors.length > 0 &&
                    <Typography variant='body1'>{ strings.errorsTitleFuture }</Typography>
                  }
                  { errors.map( ( error, index ) => (
                    <Typography variant='body1' key={ index }>{ error.msg }</Typography>
                  ) ) }
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Typography variant='body1'>{ strings.step3Label }</Typography>
              </StepLabel>
              <StepContent>
                <div>
                  { errors.length > 0 &&
                    <Typography variant='body1'>{ strings.errorsTitlePast }</Typography>
                  }
                  { errors.map( ( error, index ) => (
                    <Typography variant='body1' key={ index }>{ error.msg }</Typography>
                  ) ) }
                </div>
              </StepContent>
            </Step>
          </Stepper>       

      </DialogContent>
      <DialogActions className={ classes.lineButtonBox }>
        { activeStep < 2 &&
          <Button className={ classes.lineButton } onClick={ handleBack } variant='contained' color='secondary'>{ activeStep === 0 ? strings.cancel : strings.back }</Button>
        }
        <Button className={ classes.lineButton } onClick={ handleNext } variant='contained' color='secondary'>{ activeStep === 2 ? strings.close : strings.next }</Button>
      </DialogActions>
    </Dialog>
  );
 }


 export default connect( null, { } )( withStyles( gStyles )( UploadCsvDialog ) );