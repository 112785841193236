import { store } from '../index';
import { setLoginInfo, passChanged } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import axios from 'axios';

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// signIn
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function signIn(user, pass) {

  const onError = error => {
    gError('signIn', error);
  }

  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/login', {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 'email': user, 'password': pass })
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setLoginInfo(json));
        })
        return;
      }
      // response.json().then(json => onError(json.message));
      response.json().then(json => onError('INVALID_LOGIN'));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// changePassword
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function changePassword(newPass) {

  const onError = error => {
    gError('changePassword', error);
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/login/change', {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'cp-api-authorization': authToken
    },
    body: JSON.stringify({ 'newPassword': newPass })
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(passChanged());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// passwordRecovery
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function passwordRecovery(user) {
  return new Promise((resolve) => {

    try {
      var url = process.env.REACT_APP_WEB_API_URL + '/v1/login/recovery';
      axios({
        url: url,
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ user: user })
      }).then((response) => {
        resolve(true);
      }).catch(function (error) {
        resolve(false);
      });

    } catch (error) {
      console.log(error);
      resolve(false);
    }

  });

}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// passwordRecoveryProcess
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function passwordRecoveryProcess(user, recoveryKey, password) {
  return new Promise((resolve) => {

    try {
      var url = process.env.REACT_APP_WEB_API_URL + '/v1/login/recovery-submit';
      axios({
        url: url,
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ user: user, recoveryKey: recoveryKey, password: password })
      }).then((response) => {
        const ret = {
          status: 'OK'
        }
        resolve(ret);
      }).catch(function (error) {
        console.log();
        const ret = {
          status: 'NOK',
          message: error.response?.data?.message,
        }
        resolve(ret);
      });

    } catch (error) {
      console.log(error);
      const ret = {
        status: 'NOK',
        message: error,
      }
      resolve(ret);
    }

  });

}